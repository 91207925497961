import styled from "styled-components";

export const Wrapper = styled.div`
position: absolute;
/* position: ${ props => props.showMsg ? 'relative' : 'absolute'}; */
white-space: nowrap;
right: 0;
margin-bottom:  ${props => props.showMsg ? props.onHover ?  props.onHover :  props.onHover : '-200px'};
transition: all 0.5s;
column-gap: 10px;
font-size: 12px;
white-space: nowrap;
border-radius: 5px;
z-index: ${props => props.notifIndex ? props.notifIndex : '11'};
display: flex;
justify-content: flex-end;
align-items: center;
padding: 10px;
background: var(--yellow);
border: 2px solid #000000;
color: #000000;
min-width: 250px;

`;

export const CloseWrapper = styled.div`
display: flex;
align-items: center;
width: 25px;
padding: 3px;
`;