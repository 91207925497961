import { useState, useEffect, useRef } from 'react'
import API from '../API';
import { useAuth } from '../contexts/AuthContext'
import { PAYLOAD_TYPES } from '../config'

export const useAdminPanelResponseHook = (args) => {
    const { currentUser } = useAuth()
    const { setError, setLoading, payload, content, setItemsSubmitting, cleanupPlayingSong, pullSongs, itemsSubmitting } = args
    const itemsSubmittingRef = useRef(itemsSubmitting)
    itemsSubmittingRef.current = itemsSubmitting

    const cleanUpAndReload = async (newPayload, message, shouldPullSongs = false) => {
        if (shouldPullSongs)
            await pullSongs()

        setItemsSubmitting(prev =>
            [...prev.map(it => (
                it.contentType === newPayload.contentType &&
                it.contentValue === newPayload.contentValue &&
                it.payload.userID === newPayload.payload.userID &&
                it.payload.songID === newPayload.payload.songID) ? { ...it, message: message, showModal: true, waitingForServerResponse: false } : it)
            ]
        )
    }

    const getMessageForSubmissionType = (payloadType, payload, content) => {
        switch (payloadType) {
            case PAYLOAD_TYPES.NEW_SONG:
                return `${content.contentValue}: ${payload.status === '1' ? 'Accepting' : 'Rejecting'} '${payload.songTitle}' by ${payload.email}`;
            case PAYLOAD_TYPES.APPROVED_SONG:
                return `${content.contentValue}: ${payload.status === '1' ? 'Accepting' : 'Rejecting'} '${payload.songTitle}' by ${payload.email}`
            case PAYLOAD_TYPES.NEW_SONGWRITER:
                return `${content.contentValue}: ${payload.status === '1' ? 'Accepting' : 'Rejecting'} ${payload.email}`
            default:
                return 'Submitting...';
        }
    }


    const getErrorMessageForSubmissionType = (payloadType, payload) => {
        switch (payloadType) {
            case PAYLOAD_TYPES.NEW_SONG:
                return `Error: Couldn't complete ${payload.status === '1' ? 'acceptance' : 'rejection'} for '${payload.songTitle}' by ${payload.email}. Please try again later`;
            case PAYLOAD_TYPES.APPROVED_SONG:
                return `Error: Couldn't complete ${payload.status === '1' ? 'acceptance' : 'rejection'} for '${payload.songTitle}' by ${payload.email}. Please try again later`
            case PAYLOAD_TYPES.NEW_SONGWRITER:
                return `Error: Couldn't complete ${payload.status === '1' ? 'acceptance' : 'rejection'} for ${payload.email}. Please try again later`
            default:
                return 'An Error occured.';
        }
    }

    const getSuccessMessageForSubmissionType = (payloadType, payload) => {
        switch (payloadType) {
            case PAYLOAD_TYPES.NEW_SONG:
                return `Success: ${payload.status === '1' ? 'Accepted' : 'Rejected'} '${payload.songTitle}' by ${payload.email}`;
            case PAYLOAD_TYPES.APPROVED_SONG:
                return `Success: ${payload.status === '1' ? 'Accepted' : 'Rejected'} '${payload.songTitle}' by ${payload.email}`
            case PAYLOAD_TYPES.NEW_SONGWRITER:
                return `Success: ${payload.status === '1' ? 'Accepted' : 'Rejected'} application from ${payload.email}`
            case PAYLOAD_TYPES.NEW_SONGWRITER:
                return `Success: Updated ${payload['tempo']} ${payload['tempo'] && payload['genre'] ? 'and' : ''} ${payload['genre']} for song '${payload.songTitle}'`
            default:
                return 'Success!';
        }
    }


    const handlePayload = async (payloadType) => { //NEW_SONG, APPROVED_SONG, NEW_SONGWRITER
        setError(false)
        const message = getMessageForSubmissionType(payloadType, payload, { contentType: content.type, contentValue: content.value })
        const newPayload = { complete: false, initialIndex: itemsSubmitting.length, contentType: content.type, contentValue: content.value, payload, waitingForServerResponse: true, message: message, showModal: true }
        try {
            let res;
            if (PAYLOAD_TYPES.APPROVED_SONG || (payload.status !== undefined && payload.status === '0')) {
                cleanupPlayingSong()
            }
            setItemsSubmitting([...itemsSubmittingRef.current.filter(it => !it.complete), newPayload])
            if (payloadType === PAYLOAD_TYPES.NEW_SONG)
                await API.processSongRegUser(currentUser, payload.songID, payload.status)
            else if (payloadType === PAYLOAD_TYPES.APPROVED_SONG)
                await API.removeApprovedSong(currentUser, payload.songID)
            else if (payloadType === PAYLOAD_TYPES.NEW_SONGWRITER)
                await API.processPendingArtist(currentUser, payload)
            else if (payloadType === PAYLOAD_TYPES.NEW_BUYER)
                await API.processPendingArtist(currentUser, payload)
            else if (payloadType === PAYLOAD_TYPES.EDIT_GENRE_TEMPO)
                res = await API.updateSongGenreTempo(currentUser, payload)
            // else if (payloadType === PAYLOAD_TYPES.UPDATE_TAGS)
            //     res = await API.updateTags(currentUser, payload)

            cleanUpAndReload(newPayload, getSuccessMessageForSubmissionType(payloadType, newPayload.payload),  true)
        } catch (error) {
            setError(true)
            setLoading(false)
            cleanUpAndReload(newPayload, getErrorMessageForSubmissionType(payloadType, newPayload.payload))
        }
    }

    const removeFromNotifModals = (item) => {
        setItemsSubmitting(itemsSubmittingRef.current.filter(it => !(
            it.contentType === item.contentType &&
            it.contentValue === item.contentValue &&
            it.payload.userID === item.payload.userID &&
            it.payload.songID === item.payload.songID)))
    }

    const hideFromNotifModals = (item) => {
        const _itemsSubmitting = itemsSubmittingRef.current.map(it => (
            it.contentType === item.contentType &&
            it.contentValue === item.contentValue &&
            it.payload.userID === item.payload.userID &&
            it.payload.songID === item.payload.songID) ? { ...it, showModal: false } : it)
        setItemsSubmitting(_itemsSubmitting)
    }

    const markSequenceComplete = (item) => {
        const _itemsSubmitting = itemsSubmittingRef.current.map(it => (
            it.contentType === item.contentType &&
            it.contentValue === item.contentValue &&
            it.payload.userID === item.payload.userID &&
            it.payload.songID === item.payload.songID) ? { ...it, complete: true } : it)
        setItemsSubmitting(_itemsSubmitting)
    }

    return {
        handlePayload,
        itemsSubmittingRef,
        removeFromNotifModals,
        hideFromNotifModals,
        markSequenceComplete
    }
}