import React, { useRef, useState, useEffect } from 'react'
import Close from '../../images/closeBlack.svg'
import LoadingWheel from '../LoadingWheel'
import { Wrapper, CloseWrapper } from './NotificationModal.styles'
import dayjs from 'dayjs'

const NotificationModal = ({ markSequenceComplete, onHover, removeItem, hideModal, showModal,
    trueIndex, visibleIndex, numberSubmitting, waitingForServerResponse, message }) => {
    const initialOrder = trueIndex
    useEffect(() => {
        if (showModal && !waitingForServerResponse) {
            let id = setTimeout(() => {
                hideModal()
            }, (5 * (1)) * 1000)
            return () => {
                clearTimeout(id)
                markSequenceComplete()
            };
        }
    }, [showModal, waitingForServerResponse])

    return (
        <Wrapper
            // onHover={onHover ? `0` : `-${(thisModalInstance - 1) * 60}px`}
            onHover={onHover ? `${(visibleIndex) * 60}px` : `0`}
            showMsg={showModal} notifIndex={11 + numberSubmitting - trueIndex}>
            {waitingForServerResponse && <LoadingWheel
                width='20px'
                radius="20px"
                height='100%'
                position='relative'
                background="transparent"
            />}
            {/* {`#${initialOrder} - `} */}
            {message}
            <CloseWrapper onClick={hideModal}>
                <img src={Close} alt="x" />
            </CloseWrapper></Wrapper>
    )
}

export default NotificationModal
