import { useState, useEffect, useRef } from 'react';
import API from '../API';
import dayjs from 'dayjs'
import { useAuth } from '../contexts/AuthContext'
import { updateRelTime } from '../utils'
import { useNotifications } from './useNotifications'

export const useAudioHook = (args) => {
    const { payload, songs } = args
    const [file, setFile] = useState(null)
    // audio
    const playRef = useRef(null)
    const audioRef = useRef(null)
    const [playing, setPlaying] = useState(false)
    const [nowPlaying, setNowPlaying] = useState(null)
    const [playBarSong, setPlayBarSong] = useState(null)

    const importFile = async (path) => {
        setFile(`${process.env.REACT_APP_API_DOMAIN}` + path)
    }

    const handleSongChange = (songPath, songID) => {
        importFile(songPath);
        if (songID !== nowPlaying && nowPlaying !== null) {
            setPlaying(true)
            setNowPlaying(songID)
            playRef.current = songID

        }
        else if (nowPlaying === null) {

            setPlaying(true)
            setNowPlaying(songID)
            playRef.current = songID
        }
        else {

            setPlaying(!playing)
            setNowPlaying(songID)
            playRef.current = songID
        }
    }

    const cleanupPlayingSong = () => {
        const songIsPlaying = payload.songID === nowPlaying
        if (songIsPlaying) {
            setFile(null)
            setNowPlaying(null)
            setPlaying(false)
        }
    }


    const onSkip = (dir) => {
        // const currSongIdx = filteredSongs.findIndex(song => song.song_id === nowPlaying)
        // const adjustedIndex = dir + currSongIdx
        // const s_idx = adjustedIndex >= filteredSongs.length ? 0 : adjustedIndex < 0 ? filteredSongs.length - 1 : adjustedIndex;
        // const newSong = filteredSongs[s_idx];
        // console.log("on skip triggered", currSongIdx, nowPlaying, filteredSongs, s_idx)
        // handleSongChange(newSong.file_path, newSong.song_id)
        const currSongIdx = songs.findIndex(song => song.song_id === nowPlaying)
        const adjustedIndex = dir + currSongIdx
        const s_idx = adjustedIndex >= songs.length ? 0 : adjustedIndex < 0 ? songs.length - 1 : adjustedIndex;
        const newSong = songs[s_idx];
        console.log("on skip triggered", currSongIdx, nowPlaying, songs, s_idx)
        handleSongChange(newSong.file_path, newSong.song_id)
    }

    
    const onSkip2 = (dir) => {
        // const currSongIdx = filteredSongs.findIndex(song => song.song_id === playRef.current)
        // const adjustedIndex = dir + currSongIdx
        // const s_idx = adjustedIndex >= filteredSongs.length ? 0 : adjustedIndex < 0 ? filteredSongs.length - 1 : adjustedIndex;
        // const newSong = filteredSongs[s_idx];
        // console.log("on skip triggered", currSongIdx, playRef.current, filteredSongs, s_idx)
        // handleSongChange(newSong.file_path, newSong.song_id)
        const currSongIdx = songs.findIndex(song => song.song_id === playRef.current)
        const adjustedIndex = dir + currSongIdx
        const s_idx = adjustedIndex >= songs.length ? 0 : adjustedIndex < 0 ? songs.length - 1 : adjustedIndex;
        const newSong = songs[s_idx];
        handleSongChange(newSong.file_path, newSong.song_id)
    }

    useEffect(() => {
        if (audioRef.current) {
            playing ? audioRef.current.play().catch((e) => { }) : audioRef.current.pause();
        }

    }, [playing, nowPlaying])

    
    // useEffect(() => {

    //     // Add event listener
    //     const c = (e) => {
    //         if (e.code === 'MediaTrackNext') { onSkip2(1) }
    //     }
    //     window.addEventListener('keydown', c);

    //     // Remove event listener on cleanup
    //     return () => {
    //         window.removeEventListener('keydown', c);
    //     };
    // }, [])
    // console.log("nowPlaying", nowPlaying, filteredSongs)

    return {
        file,
        audioRef,
        playing,
        nowPlaying,
        handleSongChange,
        cleanupPlayingSong,
        onSkip
    }
}