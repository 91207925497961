import styled from "styled-components";

export const SearchIcon = styled.img`
height: 20px;
width: 20px;
position: absolute;
right: 0;
`;
export const NavArrow = styled.div`
height: 20px;
width: 20px;
padding: 5px;
display: flex;
align-items: center;
border-radius: 50%;
border: 1px solid #ffffff;
position: relative;
img{
    width: 10px;
    height: 8px;
    object-fit: contain;
    transform: ${props => props.left ? 'rotate(180deg)' : ''};
margin-left: ${props => props.right ? '1px' : ''};
}`;