import styled from 'styled-components'

export const ToolTipItem = styled.div`
position: relative;
width: 100%;
height: ${props => props.height ? props.height : ''};
display: flex;
font-size: ${props => props.fontSize ? props.fontSize : '12px'};
justify-content: ${props => props.justifyContent ? props.justifyContent : 'center'};
align-items: center;
.tooltip{
    border-radius: 5px;
    width:  ${props => props.width ? props.width : '100%'};;
    min-width: min-content;
    background: ${props => props.background ? props.background : 'var(--greyBackground)'};
    border: ${props => props.border ? props.border : '1px solid #858585'};
    box-shadow: ${props => props.boxShadow ? props.boxShadow : ''};
    padding: 10px 20px;
    position: ${props => props.position ? props.position : 'absolute'};
    visibility: hidden;
    font-size: ${props => props.fontSize ? props.fontSize : '12px'};
    z-index: ${props => props.zIndex ? props.zIndex : '1'};
    text-transform: none;
    right: ${props => props.right ? props.right : ''};
    left: ${props => props.left ? props.left : ''};
    white-space: break-spaces;
    bottom: ${props => props.bottom ? props.bottom : ''};
}
&:hover .tooltip{
    visibility: visible;
}
`;

export const ToolTipTextWrapper = styled.div`
font-size: ${props => props.fontSize ? props.fontSize : '12px'};
`;