import styled from "styled-components";

export const TableContainer = styled.div`
margin: ${props => props.margin ? props.margin : '20px 0'};
padding: ${props => props.padding ? props.padding : 'undefined'};
border: ${props => props.border ? props.border : '1px solid #ffffff'};
box-shadow: ${props => props.boxShadow ? props.boxShadow : 'undefined'};
border-bottom: ${props => props.borderBottom ? props.borderBottom : '1px solid #ffffff'};
width: 100%;
`;

export const Email = styled.div`
display: flex;
flex-direction: column-reverse;

.emailShortened{
    max-width: calc(400px - 45px - 3.5rem - 63px - 0.75rem*4 - 40px - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.tooltip{
    background: var(--background);
    border: 2px solid var(--yellow);
    padding: 10px 20px;
    position: fixed;
    visibility: hidden;
    z-index: 1;
    margin-left: -20px;
    margin-bottom: 30px;
}
&:hover .tooltip{
    visibility: visible;
}
`;

export const EmailIconWrapper = styled.div`
height: 20px;
width: 20px;
display: flex;
align-items: center;
justify-content: center;
position: relative;
img{
    object-fit: contain;
    position: relative;
    height: 10px;
}
`;