import styled from 'styled-components';

export const Wrapper = styled.div`
position: absolute;
width: 100%;
height: 5px;
top: 0;
background: var(--greyBackground);
`;

export const Progression = styled.div`
background: rgb(255,255,255);
position: absolute;
transition: width 0.5s;
height: 100%;

width: ${ props => props.step };
`;