import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import API from '../../API'
import { numToFormattedPriceString } from '../../helpers'

import paypalLogoText from '../../images/paypal-wordmark-color.svg'

import { useAuth } from '../../contexts/AuthContext'

import { Container, InnerContainer } from './Payout.styles'

import Button from '../Button'
import Flexbox from '../Flexbox'
import ContributorTable from '../ContributorTable'
import GridItem from '../GridItem'
import NavArrow from '../NavArrow'
import dayjs from 'dayjs'


const Payout = ({ }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { currentUser } = useAuth()
    const [song, setSong] = useState({
        songID: null,
        details: {
            contributors: []
        }
    })
    const columns = [
        { Header: "Contact Email", accessor: "email" },
        { Header: "Paypal Email", accessor: "songwriterPaypal" },
        { Header: "Split [%]", accessor: "split_percent" },
        { Header: "Split [$]", accessor: "split" },
    ]

    const getTableDataForExport = (data = [], columns = []) =>
        data.map((record) => columns.reduce((recordToDownload, column) => ({ ...recordToDownload, [column.Header]: record[column.accessor] }), {}))

    const makeCSV = async (rows = [], filename) => {
        const separator = ','
        const keys = Object.keys(rows[0])
        const csvContent = `${keys.join(separator)}\n${rows.map((row) => keys.map((k) => {
            let cell = row[k] === null || row[k] === undefined ? '' : row[k];

            cell = cell instanceof Date
                ? cell.toLocaleString()
                : cell.toString().replace(/"/g, '""');

            if (cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell}"`;
            }
            return cell;
        }).join(separator)).join('\n')}`;
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // In case of IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }


    useEffect(() => {
        const getDetails = async () => {
            const _songID = location.pathname.split('payout/')[1]
            const res = await API.fetchSong(currentUser, _songID, true)
            console.log("res", res)
            setSong(prev => ({ songID: res.song_id, details: { ...res, songTitle: res.song_title } }))
        }
        if (location.pathname.split('payout/').length > 1) {
            getDetails()
        }

    }, [currentUser])
    const { details: { contributors, songTitle, price, dateSold, updated_at: updatedAt }, songID } = song
    console.log("price", price, numToFormattedPriceString(price))
    if (!songID)
        return <></>
    return (
        <Container background="#121212" >
            <Flexbox gap="5px">
                <NavArrow back onClick={() => { navigate('/transactions/pending-payout') }} />
                <h2>Pending Payouts</h2>
                {/* <InnerContainer> */}
                {/* </InnerContainer> */}
            </Flexbox>
            <Flexbox className="songdetails" padding="20px" gap="20px" justifyContent="space-between" background="#1A1A1A" boxShadow="0 0 5px #000">
                <Flexbox whiteSpace="nowrap" gap="5px" column width="min-content" alignItems="start" >
                    <div className="subhead">Song ID</div>
                    <h1 style={{ margin: '0' }}>{songID}</h1>
                </Flexbox>
                <Flexbox flexGrow="2" gap="5px" column width="min-content" alignItems="start" >
                    <div className="subhead">Title</div>
                    <h1 style={{ margin: '0' }}>{songTitle}</h1>
                </Flexbox>
                <Flexbox whiteSpace="nowrap" alignText="left" gap="5px" column width="min-content" alignItems="end" >
                    <div className="subhead">Time Pending</div>
                    <h1 style={{ margin: '0' }}>{dayjs.unix(updatedAt).fromNow()}</h1>
                </Flexbox>
                <Flexbox alignText="left" gap="5px" column width="min-content" alignItems="end" >
                    <div className="subhead">Date Sold</div>
                    <h1 style={{ margin: '0' }}>{dayjs.unix(dateSold).format('M/D/YY')}</h1>
                </Flexbox>

                <Flexbox alignText="right" gap="5px" column width="min-content" alignItems="end" >
                    <div className="subhead">Total Payout</div>
                    <h1 style={{ margin: '0' }}>{numToFormattedPriceString(price)}</h1>
                </Flexbox>
            </Flexbox>
            <GridItem  display="grid" width="100%" gridTempCols="1fr 1fr" gridAutoRows="min-content">
                <h1 style={{ margin: '0' }}>Splits</h1>
                <GridItem flexDirection="row" gap="5px" gridCol="2" justifySelf="end" whiteSpace="nowrap" width="min-content">
                    <Button
                        // height="49px"
                        margin="5px 0"
                        padding="20px"
                        border='none'
                        boxShadow="0 0 5px #000"
                        background='#1C1C1C'
                        text="Download As CSV" onClick={() => {
                            makeCSV(getTableDataForExport(contributors, columns), `${songTitle}.csv`)
                        }} />
                    <Button
                        onClick={() => { }}
                        // height="49px"
                        border="none"
                        // borderRadius="0.5rem"
                        background="var(--paypalYellow)"
                        hoverColor="var(--paypalYellowHover)"
                        padding="20px 40px"
                        margin="5px 0"
                        width="100%">
                        <img style={{ height: '1.0rem' }} src={paypalLogoText} alt="Paypal" />
                    </Button>
                </GridItem>
                <GridItem gridCol="1 / span 2" column background="#1A1A1A" boxShadow="0 0 5px #000">
                    <ContributorTable data={song.details} hideHeader fontSize='1.0rem'/>
                </GridItem>
            </GridItem>
        </Container >
    )
}

export default Payout