import styled from "styled-components";

export const TableRowContainer = styled.tr`
/* display: table-row; */
font-size: ${props => props.fontSize ? props.fontSize : '12px'};
user-select: ${props => props.userSelect ? props.userSelect : 'none'};
position: relative;
transition: background 0.2s ease-out;
background: ${props => props.isDragging ? 'var(--yellow) !important' : `${props.background} !important` ? `${props.background} !important` : ''};
color: ${props => props.color ? props.color : props.isDragging ? '#000000 !important' : ''};
font-weight: ${({ fontWeight }) => fontWeight};
width: 100%;

:not(.tableHeader):not(.hoverOff):hover{
    background: ${({ hoverBackground }) => `${hoverBackground} !important` || 'rgba(255,255,255,0.15)'};
    
}

>div:nth-of-type(1), >div:nth-of-type(2){
padding-left: 0;
}
>div:nth-last-of-type(1){
    padding: 0;  
}
td{
    padding: ${({ cellPadding }) => cellPadding};
}

`;

export const TableRowHeaderContainer = styled.th`
/* display: table-row; */
font-size: ${props => props.fontSize ? props.fontSize : '12px'};
user-select: ${props => props.userSelect ? props.userSelect : 'none'};
position: relative;
transition: background 0.2s ease-out;
background: ${props => props.background ? props.background : ''};
color: ${props => props.color ? props.color : props.isDragging ? '#000000 !important' : ''};
width: 100%;
font-weight: ${({ fontWeight }) => fontWeight};
>div:nth-of-type(1), >div:nth-of-type(2){
padding-left: 0;
}
>div:nth-last-of-type(1){
    padding: 0;  
}
td{
    padding: ${({ cellPadding }) => cellPadding};
}
`;

export const TableGroup = styled.div`
display: table-row-group;

`;
