import React from 'react'

import { IconWrapper } from './SocialIcon.styles'
import SpotifyIcon from '../../images/spotify.svg'
import InstagramIcon from '../../images/instagramIcon.svg'

const SocialIcon = ({ type, url }) => {

    const getUrl = () => {
        if (url === null || url === undefined || url === '')
            return undefined
        if (type === 'spotify')
            return url.includes('https://') ? url : 'https://' + url
        if (type === 'instagram')
            return `https://www.instagram.com/${url.split('@').length > 1 ? url.split('@')[1] : url.split('@')[0]}`
    }

    return (
        <IconWrapper target="_blank" href={getUrl()} isLinked={getUrl()}><img src={type === 'spotify' ? SpotifyIcon : InstagramIcon} alt={type} /></IconWrapper>
    )
}

export default SocialIcon