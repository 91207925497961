import styled from 'styled-components'

export const Wrapper = styled.div`
    border: 1px solid var(--greyBackground);
    width: 100%;
    min-width: ${props => props.minWidth ? props.minWidth : ''};
    
    height: 100%;
    
    flex-grow: 1;
    position: relative;
   
`;

export const OuterWrapper = styled.div`
overflow: auto;
display: flex;
position: relative;
margin-top: 2px;
height: calc(100% - 60px);
margin-bottom: 50px;
`;

export const Row = styled.div``;

