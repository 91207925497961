import axios from 'axios'
//http://nfttest.xyz/api/index.php/home/
import {
  ACCEPTED_SONGS,
  SONG_ID_SPECIFIED,
  USER_ID_SPECIFIED,
  LOCAL_API_PATH
} from './config';

const defaultConfig = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  }
};

const apiSettings = {
  processPendingArtist: async (currentUser, payload) => {
    var axios = require('axios');
    var qs = require('qs');
    var data = qs.stringify({
      'access_token': currentUser.accessToken,
      'email': currentUser.email,
      'user_id': payload.userID,
      'status': payload.status,
      'song_id': payload.songID
    });
    let res = await axios({
      method: 'post', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/user_status_update`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Cookie': 'ci_session=b52152b3c80d9dde2c53f0649d552f74f4eefe68'
      },
      data: data
    });

    return res
  },
  fetchUsers: async (currentUser) => {
    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/get_users?email=${currentUser.email}&access_token=${currentUser.accessToken}`
    });

    return res
  },
  fetchUserBySongId: async (currentUser, songId) => {
    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/getArtistDetails?email=${currentUser.email}&access_token=${currentUser.accessToken}&songId=${songId}`
    });

    return res.data
  },
  fetchBuyerBySongId: async (currentUser, songId) => {
    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/getBuyerDetails?email=${currentUser.email}&access_token=${currentUser.accessToken}&songId=${songId}`
    });

    return res.data
  },
  fetchSong: async (currentUser, songId, returnSplitsAsFloats = false) => {
    const params = {
      email: currentUser.email,
      access_token: currentUser.accessToken,
      songId: songId
    }
    if (returnSplitsAsFloats)
      params.returnSplitsAsFloats = true

    let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/getSongDetails`,
      { params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined)) } })


    return res.data
  },
  fetchSongs: async (currentUser, page = undefined, resultsPerPage = undefined, filter = undefined, contentFilter = {}) => {
    const params = {
      email: currentUser.email,
      access_token: currentUser.accessToken,
      c: resultsPerPage,
      p: page,
      filter: filter, //accepted, etc (numeric)
      ...contentFilter

    }

    let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/getAdminPanelData`,
      { params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined && it[1] !== "")) } })


    return res

  },
  searchAcceptedSongs: async (currentUser, filter = {}, searchTerm = null, category = undefined, page = undefined, resultsPerPage = undefined) => { //admin
    const params = {
      email: currentUser.email,
      access_token: currentUser.accessToken,
      search: searchTerm,
      c: resultsPerPage,
      p: page,
      category: category, //  category, NOT status (new songs, accepted songs, songs for sale, etc)
      ...filter
    }

    let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/searchAcceptedSongs`,
      { params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined)) } })


    return res.data

  },
  removeTags: async (currentUser, songID, tags) => {
    const params = {
      email: currentUser.email,
      access_token: currentUser.accessToken,
      songID,
      tags,
    }

    let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/removeTags`,
      { params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined)) } })


    return res

  },
  getTags: async (search) => {
    const params = {
      search
    }
    let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/getTags`,
      { params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined)) } })

    return res.data
  },
  updateSongGenreTempo: async (currentUser, payload) => {
    const qs = require('qs');
    const data = {
      email: currentUser.email,
      access_token: currentUser.accessToken,
      songID: payload['songID'],
      genre: payload['genres'].reduce((a, b, index) => index > 0 ? a = a + ',' + b : b, ''),
      tempo: payload['tempo'].split(' ')[0],
      // tags: payload['tags']
    }
    if (payload['tags']) {
      data['tags'] = payload['tags']
    }

    // const params = {
    //   email: currentUser.email,
    //   access_token: currentUser.accessToken,
    //   songID: payload['songID'],
    //   genre: payload['genres'].reduce((a, b, index) => index > 0 ? a = a + ',' + b : b, ''),
    //   tempo: payload['tempo'].split(' ')[0]
    // }
    // let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/updateSongGenreTempo`,
    //   { params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined)) } })
    let res = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_DOMAIN}/updateSongGenreTempo`, //api/config/database.php
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: qs.stringify({
        ...Object.fromEntries(Object.entries(data).filter(it =>
          it[0] === 'tags' ? true :
            it[1] !== undefined))
      })
    });

    return res

  },
  updateTags: async (currentUser, songs) => {
    const data = JSON.stringify({
      email: currentUser.email,
      accessToken: currentUser.accessToken,
      songIDs: songs
    })
    let res = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_DOMAIN}/tagSong`, //api/config/database.php
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    });

    return res
  },
  updateTop100: async (currentUser, top100, options) => {
    const formData = new FormData();
    if (currentUser !== null) {
      formData.append('access_token', currentUser.accessToken)
      formData.append("email", currentUser.email);
    }
    formData.append('top100', JSON.stringify(top100))
    formData.append('options', JSON.stringify(options))
    let res = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_DOMAIN}/updateTop100`, //api/config/database.php
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    });

    return res
  },
  setAccessToken: async (email, token) => {
    const qs = require('qs');
    const data = qs.stringify({
      'email': email,
      'token': token
    });
    let res = await axios({
      method: 'post', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/set_admin_access_token`, //api/config/database.php
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    });

    return res
  },
  fetchSongStatusKey: async () => {

    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/get_song_status_keys`
    });
    return res.data.valid_statuses
  },
  fetchMessages: async (userID, songID, userType = 1, page = 1, resultsPerPage = 5) => {

    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/get_messages?song_writer_id=${userID}&song_id=${songID}&p=${page}&c=${resultsPerPage}&user_type=${userType}`
    });

    return [res.data.messages, res.data.user, res.data.song]

  },
  fetchMessagesAdmin: async (currentUser, page = 1, resultsPerPage = 5, userType = 1, userID = null, songID = null) => { //get_message_stream_headers
    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/get_message_stream_headers2?access_token=${currentUser.accessToken}&email=${currentUser.email}&user_type=${userType}&song_writer_id=${userID}&song_id=${songID}&p=${page}&c=${resultsPerPage}`
    });
    return [res.data.message_streams, res.data.total]
  },
  processSongRegUser: async (currentUser, songID, status) => { //registered, accepted user
    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/song_status_update?admin_email=${currentUser.email}&access_token=${currentUser.accessToken}&song_id=${songID}&status=${status}`
    });

    return res

  },
  sendMessage: async (receiver_id, sender_id, songwriter_id, song_id, message) => {
    var qs = require('qs');
    var data = qs.stringify({
      'receiver_id': receiver_id,
      'sender_id': '1',
      'songwriter_user_id': songwriter_id,
      'song_id': song_id,
      'message': message
    });
    let res = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_DOMAIN}/save_messages`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data

    });

    return res
  },
  sendSongAcceptanceEmail: async (userID, name, email, songTitle) => {
    var qs = require('qs');
    var data = qs.stringify({
      'fname': name,
      'email': email,
      'song_title': songTitle,
      'user_id': userID
    });
    let res = await axios({
      method: 'post', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/send_user_email2`, //api/config/database.php
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    });

    return res

  },
  sendSongAcceptanceEmailX: async (userID, name, email, songTitle) => {
    var qs = require('qs');
    var data = qs.stringify({
      'fname': name,
      'email': email,
      'song_title': songTitle,
      'user_id': userID
    });
    let res = await axios({
      method: 'post', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/send_user_email2`, //api/config/database.php
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    });

    return res

  },
  authenticateAcceptance: async (userID) => {

    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/get_users?user_id=${userID}`
    });

    return res.data.user

  },
  registerAccount: async (userID, email, password) => {
    const qs = require('qs');
    const data = qs.stringify({
      'email': email,
      'password': password,
      'user_id': userID
    });
    let res = await axios({
      method: 'post', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/register`, //api/config/database.php
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    });

    return res

  },
  updateMessageReadStatus: async (song_id, receiver_id, user_id) => { //we only want new tags when current user === receiver
    let res = await axios({ //user_id is songwriter_user_id field
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/update_message_read_status?song_id=${song_id}&receiver_id=${receiver_id}&user_id=${user_id}`
    });
    return res
  },
  signIn: async (email, password) => {
    const qs = require('qs');
    const data = qs.stringify({
      'email': email,
      'password': password
    });
    let res = await axios({
      method: 'post', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/login`, //api/config/database.php
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    });

    return res
  },
  removeApprovedSong: async (currentUser, song_id) => {
    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/remove_approved_song?email=${currentUser.email}&access_token=${currentUser.accessToken}&song_id=${song_id}`
    });

    return res
  },
};



export default apiSettings;