import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { OuterWrapper, InnerWrapper, Wrapper, SiteHeader, SideBarLinkHeader, Spacer, SideBarLink, Logout } from './SideBar.styles'
import { useAuth } from '../../../contexts/AuthContext'
import { throttle } from '../../../utils'

import GridItem from '../../GridItem'

import Logo from '../../../images/headerLogo.svg'
import Close from '../../../images/close.svg'

const SideBar = ({ props }) => {
    const [showSideBar, setShowSideBar] = useState(true)
    const navigate = useNavigate()
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const { content, sidebarlinks, setContent, setPage, setFilter, pullSongs, pullBuyers } = props
    const { logout } = useAuth()

    useEffect(() => {
        let isMounted = true;
        const handleResize = throttle(() => {
            if (isMounted) {
                setWindowSize(window.innerWidth)
            }
        }, 1000)
        window.addEventListener("resize", handleResize);
        return () => {
            isMounted = false
            window.removeEventListener("resize", handleResize)
        };
    }, [])

    return (
        <>
            {/* {(windowSize < 1200 && showSideBar) && <OuterWrapper onClick={() => setShowSideBar(false)}/>} */}
            <GridItem onClick={() => setShowSideBar(!showSideBar)} position="absolute" zIndex="5" margin="15px 20px" height="15px" width="15px"><img src={showSideBar ? Close : Logo} id="hamburger-menu" /></GridItem>
            <Wrapper showSideBar={showSideBar}>
                <InnerWrapper>
                    <SiteHeader>Azaa Admin</SiteHeader>
                    {
                        Object.keys(sidebarlinks).map(category => (
                            <Spacer>
                                <SideBarLinkHeader>{category}</SideBarLinkHeader>
                                {sidebarlinks[category].map(
                                    (value, index) => (
                                        <SideBarLink
                                            value={value.title}
                                            key={index}
                                            isActive={content.value === value.title && content.type === category ? true : false}
                                            onClick={(e) => {
                                                setContent({
                                                    type: category,
                                                    value: value.title
                                                })
                                                setPage(1)
                                                setFilter(value.filterValue)
                                                pullSongs(1, value.filterValue, content.value !== value.title || content.type !== category)
                                                navigate(`/${category.toLowerCase().replaceAll(' ','-')}/${value.title.toLowerCase().replaceAll(' ','-')}`)
                                            }
                                            }
                                        >
                                            {value.title}
                                        </SideBarLink>))}
                            </Spacer>
                        ))
                    }
                    <Spacer>
                        <SideBarLinkHeader>Messages</SideBarLinkHeader>
                        <SideBarLink
                            value={"messages"}
                            isActive={content.type === "messages" && content.value === "buyers" ? true : false}
                            onClick={(e) => {

                                setContent({
                                    type: "messages",
                                    value: "buyers"
                                })
                                navigate(`/messages/buyers`)
                            }
                            }
                        >Buyers</SideBarLink>
                        <SideBarLink
                            value={"messages"}
                            isActive={content.type === "messages" && content.value === "songwriters" ? true : false}
                            onClick={(e) => {
                                setContent({
                                    type: "messages",
                                    value: "songwriters"
                                })
                                navigate(`/messages/songwriters`)
                            }}
                        >Songwriters</SideBarLink>

                    </Spacer>
                    <Logout ><SideBarLink onClick={() => logout()}>Log Out</SideBarLink></Logout>
                </InnerWrapper>
            </Wrapper>
        </>
    )
}

export default SideBar