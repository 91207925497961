import React from 'react'

import { Wrapper, LoadingWheelWrapper } from './LoadingWheel.styles'

const Rings = ({ width, height, segments }) => {
    return (<div style={{ border: '1px solid rgba(0,0,0,0.25)', borderRadius: '50%', width: `${width}`, height: `${width}`, position: 'absolute' }}></div>)
}

const LoadingWheel = ({ hideWrapper, background, width, height, position, top, zIndex, radius, border, borderOuter }) => {
    const segments = 10;
    const segmentArray = [];
    for (let i = 0; i <= segments; i++) {
        segmentArray.push(width ? width.replace(/px/, '') * parseFloat((i + 1) / segments) : '80px'.replace(/px/, '') * parseFloat((i + 1) / segments) + '%')
    }
    if (hideWrapper !== undefined) {
        return (<LoadingWheelWrapper border={border} radius={radius}>
            {segmentArray.map((segment, idx) => (<Rings key={idx} width={segment} height={segment} segments={segments} />))}
            <div style={{ border: '1px solid rgba(255,255,255,0.05)', background: 'var(--greyBackground)', borderRadius: '50%', width: '25%', height: '25%', position: 'absolute' }}></div>
            <div style={{ background: '#000000', borderRadius: '50%', width: '3%', height: '3%', position: 'absolute' }}></div>
            <div style={{ border: '1px solid rgba(255,255,255,0.10)', borderRadius: '50%', width: '100%', height: '100%', position: 'absolute' }}></div>
        </LoadingWheelWrapper>)
    }
    else return (
        <Wrapper
            border={borderOuter}
            background={background}
            width={width}
            height={height}
            position={position}
            top={top}
            zIndex={zIndex} >
            <LoadingWheelWrapper border={border} radius={radius}>
                {segmentArray.map((segment, idx) => (<Rings key={idx} width={segment} height={segment} segments={segments} />))}
                <div style={{ border: '1px solid rgba(255,255,255,0.05)', background: 'var(--greyBackground)', borderRadius: '50%', width: '25%', height: '25%', position: 'absolute' }}></div>
                <div style={{ background: '#000000', borderRadius: '50%', width: '3%', height: '3%', position: 'absolute' }}></div>
                <div style={{ border: '1px solid rgba(255,255,255,0.10)', borderRadius: '50%', width: '100%', height: '100%', position: 'absolute' }}></div>
            </LoadingWheelWrapper>
        </Wrapper>
    )
}

export default LoadingWheel
