import React, { useEffect, useState, useRef } from 'react'
import { objectsEqual } from '../../utils'

import {
    Wrapper,
    RightContainer,
    Row,
    Innercontainer,
    DateWrapper,
    NavArrow,
    LoadWrapper,
    NotificationWrapper
} from './AdminPanel.styles'

import FilterBar from './FilterBar'
import SideBar from './SideBar'
import PageNav from './PageNav'
import SongwritersTab from './SongwritersTab'
import Buyers from '../Buyers'
import Songs from './Songs'
import FooterPlayBar from './FooterPlayBar'
import Songwriter from './Songwriter'
import Messages from '../Messages'
import Transactions from '../Transactions'
import NotificationModal from '../NotificationModal'
import Top100New from '../Top100New'

import { useAuth } from '../../contexts/AuthContext'
import { useAdminPanelHook } from '../../hooks/useAdminPanelHook'
import { useAudioHook } from '../../hooks/useAudioHook'
import { useAdminPanelResponseHook } from '../../hooks/useAdminPanelResponseHook'

const AdminPanel = () => {

    const adminPanelHook = useAdminPanelHook()

    const {
        payload,
        setPayload,
        sidebarlinks,
        content,
        setContent,
        page,
        setPage,
        pageRef,
        totalResults,
        adminID,
        resultsPerPage,
        songs,
        options,
        setOptions,
        filter,
        setFilter,
        loading,
        setLoading,
        error,
        setError,
        songStatusKey,
        pullSongs,
        pullBuyers,
        itemsSubmitting,
        setItemsSubmitting,
        onSearchChange,
        onSearchReset,
        triggerReset,
        fetchMoreOnScroll,
        nbHits
    } = adminPanelHook

    const {
        file,
        audioRef,
        playing,
        nowPlaying,
        handleSongChange,
        cleanupPlayingSong,
        onSkip
    } = useAudioHook({ songs, payload })

    const {
        handlePayload,
        itemsSubmittingRef,
        removeFromNotifModals,
        hideFromNotifModals,
        markSequenceComplete
    } = useAdminPanelResponseHook({ setError, setLoading, payload, content, setItemsSubmitting, cleanupPlayingSong, pullSongs, itemsSubmitting })
    const { currentUser } = useAuth()
    const [onHover, setOnHover] = useState(false)
    const songwriterProps = { markSequenceComplete, setError, cleanupPlayingSong, payload, currentUser, setLoading, pullSongs }
    const contentType = content.type;
    console.log("content", content, loading)
    return (
        <Wrapper>
            <SideBar props={{ content, sidebarlinks, setContent, setPage, setFilter, pullSongs, pullBuyers }} />
            <RightContainer
                height={(content.type === 'songs' && content.value === 'Accepted') ? '100vh' : undefined}
                isMessages={false}
                isTop100={content.value === 'Top 100' || content.type === 'transactions' || content.type === 'buyers' || (content.type === 'songs' && content.value === 'Accepted') ? true : undefined}>
                {(content.value !== 'Top 100' && content.type !== 'transactions' && !(content.type === 'songs' && content.value === 'Accepted')) &&
                    <PageNav
                        content={content}
                        filter={filter}
                        totalResults={totalResults}
                        onSearchChange={onSearchChange}
                        resultsPerPage={resultsPerPage}
                        page={page}
                        setPage={setPage}
                        padding={content.type === 'buyers' ? '50px 50px 0' : undefined}
                        pullSongs={pullSongs}
                        loading={loading}
                        onSearchReset={onSearchReset}>
                        {
                            (contentType !== 'messages' && content.value !== 'Top 100' && content.type !== 'transactions' && (content.type !== 'songs' || content.value === 'New Songs') && !(content.type === 'buyers' && content.value === 'New Applicants')) &&
                            <SongwritersTab
                                songwriterProps={songwriterProps}
                                handlePayload={handlePayload}
                                type={content.type}
                                content={content}
                                isApproved={content.value === "New Applicants" || content.value === "New Songs" ? false : true}
                                songs={songs}
                                loading={loading}
                                setItemsSubmitting={setItemsSubmitting}
                                itemsSubmitting={itemsSubmitting}
                                setPayload={setPayload}
                                error={error}
                                nowPlaying={nowPlaying}
                                songStatusKey={songStatusKey}
                                playing={playing}
                                handleSongChange={handleSongChange}
                            >
                            </SongwritersTab>
                        }

                        {(contentType === 'messages') && <Messages userID={adminID} userType={content.value}></Messages>}

                    </PageNav>}
                {
                    (content.type === 'songs' && content.value !== 'New Songs' && content.value !== 'Top 100') &&
                    <Songs
                        pullSongs={pullSongs}
                        songwriterProps={songwriterProps}
                        handlePayload={handlePayload}
                        payload={payload}
                        type={content.type}
                        content={content}
                        isApproved={content.value === "New Applicants" || content.value === "New Songs" ? false : true}
                        songs={songs}
                        loading={loading}
                        setItemsSubmitting={setItemsSubmitting}
                        itemsSubmitting={itemsSubmitting}
                        setPayload={setPayload}
                        error={error}
                        nowPlaying={nowPlaying}
                        songStatusKey={songStatusKey}
                        playing={playing}
                        handleSongChange={handleSongChange}
                        {...adminPanelHook}
                    >
                    </Songs>
                }
                {
                    // (
                    //     content.value === 'Top 100'
                    //     && !loading)

                    content.value === 'Top 100'
                    &&
                    <Top100New
                        songs={songs}
                        options={options}
                        {...adminPanelHook} />
                }
                {
                    (content.type === 'transactions' && !loading) && <Transactions songs={songs} type={content.value} />
                }
                {
                    (content.type === 'buyers' && !loading && content.value === 'New Applicants') &&
                    <Buyers
                        buyers={songs}
                        payload={payload}
                        handlePayload={handlePayload}
                        isApproved={content.value === "New Applicants" ? false : true}
                        type={content.value}
                        content={content}
                        loading={loading}
                        setItemsSubmitting={setItemsSubmitting}
                        itemsSubmitting={itemsSubmitting}
                        setPayload={setPayload}
                        itemsSubmittingRef={itemsSubmittingRef}
                    // adminPanelHookProps={{ ...adminPanelHook, itemsSubmittingRef }} 
                    />
                }
            </RightContainer>
            <NotificationWrapper
                height={itemsSubmitting.length * 50}
                onMouseEnter={(e) => setOnHover(true)}
                onMouseLeave={(e) => setOnHover(false)}>
                {itemsSubmitting.length > 0 && itemsSubmitting.map((item, idx) => {
                    return (
                        <NotificationModal
                            onHover={onHover}
                            key={idx}
                            message={item.message}
                            waitingForServerResponse={item.waitingForServerResponse}
                            showModal={item.showModal}
                            initialIndex={item.initialIndex}
                            numberSubmitting={itemsSubmitting.length}
                            trueIndex={idx}
                            visibleIndex={itemsSubmitting.length > 0 ?
                                itemsSubmitting.filter(it => it.showModal)
                                    .findIndex(visItem =>
                                        visItem.payload.userID === item.payload.userID
                                        && visItem.payload.songID === item.payload.songID
                                        && visItem.contentValue === item.contentValue
                                        && visItem.contentType === item.contentType) : 0}
                            markSequenceComplete={() => markSequenceComplete(item)}
                            hideModal={() => hideFromNotifModals(item)}
                            removeItem={() => removeFromNotifModals(item)}
                        />
                    )
                })}</NotificationWrapper>
            <audio preload="none" ref={audioRef} src={file} onEnded={() => onSkip(1)}>
            </audio>
        </Wrapper>
    )
}

export default AdminPanel
