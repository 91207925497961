import styled from 'styled-components';

export const Wrapper = styled.button`
width: min-content;
width: 105px;
height:25px;
display: flex;
align-items: center;
justify-content: ${props => props.direction === 'back' ? 'flex-end' : 'flex-start'};
position: relative;
color: rgb(255, 255, 255);
text-transform: uppercase;
font-size: var(--fontMicro);
font-weight: 600;
letter-spacing: 0.2rem;
background: transparent;
border: none;
cursor:  ${ props => props.validContinue ? 'pointer' : 'not-allowed'};
opacity: ${ props => props.validContinue ? '1' : '.5'};
 img{
     height: 100%;
     width: 23px;
     position: relative;
     order: ${props => props.direction === 'next' ? 0 : 1};
     transform: ${props => props.direction === 'next' ? 'rotate(0deg)' : 'rotate(180deg)'};
 }
`;

export const InnerWrapper = styled.div`
    
`;