import React, { useState, useRef, useEffect } from 'react'

import DropdownIcon from '../../images/dropdownArrow.svg'

import { Wrapper, BackgroundWrapper, OptionTile, RemoveButton, DropdownContainer, DropdownHeader, DropdownListContainer, DropdownList, ListItem, DropdownArrow, LabelStyled } from './DropdownMultiSelect.styles'

import Flexbox from '../Flexbox'

const CloseDropdown = ({ shouldRotate }) => (

    <DropdownArrow src={DropdownIcon} alt="dropdown-arrow" shouldRotate={shouldRotate} />
)

const Label = ({ name, label, marginBottom }) => (
    <LabelStyled htmlFor={name} marginBottom={marginBottom}>{label}</LabelStyled>

)

const Option = ({ isDash, value, defaultSelect, innerText, onClick, isSelected }) => (

    <ListItem defaultSelect={defaultSelect} value={value} onClick={onClick} isSelected={isSelected} justifyContent={isDash ? 'center' : undefined}>
        {innerText}
        {isSelected && (
            <RemoveButton onClick={onClick} value={innerText} isDash={isDash ? true : undefined}>&#10006;</RemoveButton>
        )}

    </ListItem>
);

const Tile = ({ innerText, onClick }) => (

    <OptionTile

        value={innerText}
    >
        <div value={innerText} >{innerText}</div>
        <RemoveButton onClick={onClick} value={innerText} >&#10006;</RemoveButton>
    </OptionTile>
);

const DropdownMultiSelect = ({ flexDirection, listMarginBottom, isDash = false, _isOpen = false, _handleChange = null, headerText = null, hideLabel = false, label, name, marginBottom, options, setState, handleStateChange, valueX, key, id, showTile = true, ...props }) => {
    console.log("flexDirection", flexDirection, listMarginBottom)
    let defView;
    const bgdRef = useRef(null)
    if (valueX !== undefined && valueX.length > 0) {
        defView = `Genre (${valueX.length})`;
    }
    else {
        defView = '--'
    }

    const [header, setHeader] = useState(headerText ? headerText : defView);
    const [isOpen, setIsOpen] = useState(_isOpen);
    const [genreArray, setgenreArray] = useState('');
    const [value, setValue] = useState(valueX)
    const initial = useRef(true);

    const handleChange = (event) => {
        if (typeof (_handleChange) === 'function') {
            setIsOpen(!isOpen)
            _handleChange()
        } else {
            setIsOpen(!isOpen)
        }

    }

    const selectNew = (event) => {

        setValue(event.target.innerHTML);
        // setState(event.target.innerHTML);
        //handleStateChange('songList')
        handleStateChange()
        setIsOpen(!isOpen)
    }

    const selectNew2 = (event) => {
        event.preventDefault();
        handleStateChange(event)
        setIsOpen(!isOpen)
    }


    useEffect(() => {
        if (initial.current) {
            initial.current = false;
            return;
        }

        setgenreArray(valueX)
        if (valueX.length > 0) {
            setHeader(headerText !== null ? headerText : `Genre (${valueX.length})`)
        }
        else {
            setHeader(headerText !== null ? headerText : `--`)
        }


    }, [
        valueX
    ])

    if (isDash) {
        return (
            <>
                {isOpen && <Flexbox
                    left="0"
                    top="0"
                    fixed
                    refID={bgdRef}
                    height='100vh'
                    width='100vw'
                    onClick={(e) => {
                        console.log(e.target)
                        if (e.target === bgdRef.current && typeof (_handleChange) === 'function') _handleChange()
                    }}></Flexbox>}

                {/* {isOpen && <BackgroundWrapper ref={bgdRef} onClick={(e) => { if (e.target === bgdRef.current && typeof (_handleChange) === 'function') _handleChange() }} />} */}
                {!hideLabel && < Label
                    name={name}
                    label={label}
                    marginBottom={marginBottom}
                ></Label>}
                <Wrapper showTile={showTile} flexDirection={flexDirection}>
                    {/* <DropdownContainer id={id}  {...props}  flexDirection={flexDirection}> */}
                    {/* <DropdownHeader
                            onClick={handleChange}
                        >{header}
                            <CloseDropdown shouldRotate={isOpen} />
                        </DropdownHeader> */}
                    {isOpen && (
                        // <DropdownListContainer >
                        <DropdownList {...props} listMarginBottom={listMarginBottom}>
                            {
                                options.map(option => (
                                    <Option
                                        isDash
                                        value={option.value}
                                        innerText={option.innerText}
                                        key={option.value}
                                        defaultSelect={option.isDefault}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleStateChange(e)
                                            // _handleChange()
                                        }} //was handleStateChange
                                        isSelected={valueX.includes(option.value)}
                                    />

                                ))
                            }
                        </DropdownList>
                        // {/* </DropdownListContainer> */}
                    )}


                    {/* </DropdownContainer> */}
                    {showTile &&
                        valueX.map(selected => (
                            <Tile
                                innerText={selected}
                                key={selected}
                                onClick={handleStateChange}
                            />

                        ))




                    }
                </Wrapper>
            </>
        )
    }
    else {
        return (
            <>
                {!hideLabel && < Label
                    name={name}
                    label={label}
                    marginBottom={marginBottom}
                ></Label>}
                <Wrapper showTile={showTile} flexDirection={flexDirection}>
                    {/* <DropdownContainer id={id}  {...props}   flexDirection={flexDirection}> */}
                    <DropdownHeader
                        onClick={handleChange}
                    >{header}
                        <CloseDropdown shouldRotate={isOpen} />
                    </DropdownHeader>
                    {isOpen && (
                        // <DropdownListContainer {...props} flexDirection={flexDirection}>
                        <DropdownList {...props} listMarginBottom={listMarginBottom}>
                            {
                                options.map(option => (
                                    <Option
                                        value={option.value}
                                        innerText={option.innerText}
                                        key={option.value}
                                        defaultSelect={option.isDefault}
                                        onClick={option.onClick ? option.onClick : selectNew2} //was handleStateChange
                                        isSelected={valueX.includes(option.value)}
                                    />

                                ))
                            }
                        </DropdownList>
                        // </DropdownListContainer>
                    )}


                    {/* </DropdownContainer> */}
                    {showTile &&
                        valueX.map(selected => (
                            <Tile
                                innerText={selected}
                                key={selected}
                                onClick={handleStateChange}
                            />

                        ))




                    }
                </Wrapper>
            </>
        )
    }
}

export default DropdownMultiSelect
