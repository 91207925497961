import styled from "styled-components";

export const Wrapper = styled.div`
    
    width: 100%;
    background: var(--greyBackground);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0 20px 0;
    `;


export const Content = styled.div`
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;



export const LinksContainer = styled.div`
width: ${props => props.width};
margin-top: ${props => props.marginTop ? props.marginTop : '0'};
font-size: 1em;
display: flex;
justify-content: space-between;

img{
    height: 28px;
    width: 28px;
}
`;

export const Copyright = styled.div`
font-size: 10px;
`;

export const PhoneNumber = styled.div`
margin: 1em 0;
`;
