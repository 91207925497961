import styled from "styled-components";

export const OuterWrapper = styled.div`
height: 100vh;
width: 100vw;
overflow: hidden;
position: absolute;
background: rgba(0,0,0,0.75);
left: 0;
z-index: 2;
`;

export const Wrapper = styled.div`
    user-select: none;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.03rem;
    width: 30%;
    max-width: 200px;
    flex-grow: 1;
    flex-basis: 200px;
    height: 100%;
    position: relative;
    box-shadow: ${props => props.showSideBar ? '0 0 8px #000000' : ''};
    display: flex;
    flex-direction: column;
    background: var(--greyBackground);

    z-index: 3;
    transition: all 0.5s;
    margin-left: ${props => props.showSideBar ? '0' : '-200px'};
    /* @media screen and (max-width: 1200px){
        position: absolute;
        z-index: 3;
        transition: all 0.5s;
        margin-left: ${props => props.showSideBar ? '0' : '-200px'};
    } */
`;

export const InnerWrapper = styled.div`
    user-select: none;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.03rem;
    width: 100%;
    max-width: 200px;
    flex-grow: 1;
    flex-basis: 200px;
   
    height: 100%;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    background: var(--greyBackground);
    margin-top: 40px;

`;

export const SiteHeader = styled.div`
    color: #fff;
    padding: 10px 20px;
    font-family: Cano;
    text-transform: none;
    font-size: 16px;
`;

export const SideBarLinkHeader = styled.div`
    padding: 10px 20px;
    font-weight: 500;
    font-size: 15px;
`;

export const Spacer = styled.div`
margin-top: 30px;
`;

export const SideBarLink = styled.div`
    padding: 10px 20px;
    background: ${props => props.isActive ? 'var(--backgroundActiveLi)' : 'transparent'};
    border-right: ${props => props.isActive ? 'var(--borderRightActiveLi)' : 'none'};
`;

export const Logout = styled.div`
    margin-top: 40px;
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    
`;
