import React, { useState, useEffect, useRef } from 'react'

import Accept from '../../../images/acceptIcon.svg'
import Agreed from '../../../images/agreedIcon.svg'
import Disagreed from '../../../images/close.svg'
import Reject from '../../../images/rejectIcon.svg'
import Close from '../../../images/close.svg'
import clock from '../../../images/clockIcon.svg'
import { AZAA_PLATFORM_FEE_PERCENT } from '../../../config'
import {
    Wrapper,
    AcceptButtonContainer,
    RejectButtonContainer,
    PopupWrapperOverlay,
    PopupWrapper,
    Subtext,
    ButtonContainer,
    ValueHighlighted,
    CloseButtonContainer,
    Strong,
    Blurb,
    Title,
    TableContainer,
    SubTitle,
    ConfirmResponse,
    Row,
    PricingTable

} from './SongDetailsModal.styles'

import { EmailLink } from '../../Top100New/Top100Modal/Top100Modal.styles'

import Button from '../../Button'
import Flexbox from '../../Flexbox'
import GridItem from '../../GridItem'
import Table from '../../Table'
import Table2 from '../../Table2'
import TableCell from '../../TableCell'
import TableRow from '../../TableRow'
import ToolTip from '../../ToolTip'
import SongDetailsTable from '../../SongDetailsTable'
import TransactionDetailsBuyerPaypal from '../../TransactionDetailsBuyerPaypal'
import { ContributorTable } from '../../Top100New/Top100Modal'

import { showTrailing, adjustPrice, calculateCut, calculatePriceWithFee } from '../../../helpers'
import { getCompletionStatus } from '../../../hooks/useTransactionHook'

import { useAuth } from '../../../contexts/AuthContext'

import dayjs from 'dayjs'

const ClosePopup = ({ closePopup }) => (
    <CloseButtonContainer >
        <img src={Close} alt="close-popup" onClick={closePopup} />
    </CloseButtonContainer>
)

export const RowItem = ({ c,
    selectedSong,
    getDotMenu,
    handleSelect,
    showSideBar,
    selected }) => {

    const dotMenuRef = useRef(null)
    const [hover, setHover] = useState(false)

    return (
        <TableRow content={c.data} selectedSong={selectedSong} key={c.song_id}
            // handleSelect={() => handleSelect(c)}
            handleSelect={() => { }}
            userSelect="text"
            onMouseEnter={() => { setHover(true) }}
            onMouseLeave={() => { setHover(false) }}
            onClick={(e) => {
            }}>
        </TableRow>)
}

const BuyerDetails = ({ selectedSong }) => {
    //(payoutComplete = undefined, stemTransfer = undefined, index = 0)
    const [data, setData] = useState(null)
    useEffect(() => {
        setData(formatData())
    }, [selectedSong])
    const _status = parseInt(selectedSong.status)

    const formatData = () => {
        if (selectedSong.buyer_email !== undefined)
            return (({ email, primarySongwriterPaypal, buyer_email, buyerPaypal, stemTransfer,
                payoutComplete, payoutDate, dateSold, price, updated_at }) => ({
                    // 'Primary Songwriter [Paypal Email]': { data: primarySongwriterPaypal, props: {} },
                    'Primary Songwriter [Contact Email]': { data: email, props: {} },
                    'Deliverables Confirmation [Songwriter]': { data: getCompletionStatus(undefined, stemTransfer, 0, 'start'), props: { color: !stemTransfer[0].updated_at ? 'var(--yellow)' : '' } },
                    'Buyer [Contact Email]': { data: buyer_email, props: {} },
                    // 'Buyer [Paypal Email]': { data: buyerPaypal, props: {} },
                    'Deliverables Confirmation [Buyer]': { data: getCompletionStatus(undefined, stemTransfer, 1, 'start'), props: { color: !stemTransfer[1].updated_at ? 'var(--yellow)' : '' } },
                    // 'Payout Complete': { data: getCompletionStatus(payoutComplete, stemTransfer, 0, 'start'), props: {} },
                    // 'Payout Date': { data: payoutDate ? dayjs.unix(payoutDate).format('M/D/YY') : '-', props: {} },
                    'Date Sold': { data: dayjs.unix(dateSold).format('M/DD/YY'), props: {} },
                    // 'release_date': { data: dayjs.unix(updated_at).format('M/DD/YY'), props: {} },
                }))(selectedSong)
        return (({ bpm, price, updated_at }) => ({ price: calculatePriceWithFee(price).total, tempo: bpm, release_date: dayjs.unix(updated_at).format('M/DD/YY') }))(selectedSong)
    }
    if (data === null) {
        return <></>
    }
    return (
        <>
            <h2 style={{ margin: '20px 0 0' }}>Deliverables Transfer</h2>
            < Table2 noUppercase background="#1c1c1c">
                {
                    Object.keys(data).map(it => {
                        return (
                            <TableRow >
                                <TableCell width="min-content" props={data[it].props}>{it.replaceAll('_', " ")}</TableCell>
                                <TableCell width="max-content" props={data[it].props}>{data[it].data === '' || data[it].data === 0 ? '-' : data[it].data}</TableCell>
                            </TableRow>
                        )
                    })
                }
            </Table2 >
        </>)

}

const BuyerDetails2 = ({ selectedSong }) => {
    //(payoutComplete = undefined, stemTransfer = undefined, index = 0)
    const [data, setData] = useState(null)
    useEffect(() => {
        setData(formatData())
    }, [selectedSong])
    const _status = parseInt(selectedSong.status)
    const formatData = () => {
        if (selectedSong.buyer_email !== undefined)
            return (({ email, primarySongwriterPaypal, buyer_email, buyerPaypal, stemTransfer,
                payoutComplete, payoutDate, dateSold, price, updated_at }) => ({
                    'Primary Songwriter [Paypal Email]': { data: primarySongwriterPaypal, props: {} },
                    'Primary Songwriter [Contact Email]': { data: email, props: {} },
                    'Deliverables Confirmation [Songwriter]': { data: getCompletionStatus(undefined, stemTransfer, 0, 'start'), props: { color: !stemTransfer[0].updated_at ? 'var(--yellow)' : '' } },
                    'Buyer [Contact Email]': { data: buyer_email, props: {} },
                    'Buyer [Paypal Email]': { data: buyerPaypal, props: {} },
                    'Deliverables Confirmation [Buyer]': { data: getCompletionStatus(undefined, stemTransfer, 1, 'start'), props: { color: !stemTransfer[1].updated_at ? 'var(--yellow)' : '' } },
                    'Payout Complete': { data: getCompletionStatus(payoutComplete, stemTransfer, 0, 'start'), props: {} },
                    'Payout Date': { data: payoutDate ? dayjs.unix(payoutDate).format('M/D/YY') : '-', props: {} },
                    'Date Sold': { data: dayjs.unix(dateSold).format('M/DD/YY'), props: {} },
                    'release_date': { data: dayjs.unix(updated_at).format('M/DD/YY'), props: {} },
                }))(selectedSong)
        return (({ bpm, price, updated_at }) => ({ price: calculatePriceWithFee(price).total, tempo: bpm, release_date: dayjs.unix(updated_at).format('M/DD/YY') }))(selectedSong)
    }
    if (data === null) {
        return <></>
    }
    return (
        <>
            <h2 style={{ margin: '20px 0 0' }}>Sale Details</h2>
            < Table2 noUppercase background="#1c1c1c">
                {
                    Object.keys(data).map(it => {
                        return (
                            <TableRow >
                                <TableCell width="min-content" props={data[it].props}>{it.replaceAll('_', " ")}</TableCell>
                                <TableCell width="max-content" props={data[it].props}>{data[it].data === '' || data[it].data === 0 ? '-' : data[it].data}</TableCell>
                            </TableRow>
                        )
                    })
                }
            </Table2 >
        </>)

}

const SongBadges = ({ selectedSong }) => {
    const songInfo = (({ favorites, top_100_history }) => ({ favorites: favorites ? favorites : '-', 'Top 100 Spot': !top_100_history || top_100_history.length === 0 ? '-' : top_100_history[top_100_history.length - 1].spot }))(selectedSong)

    const formatTop100 = () => {
        if (selectedSong.top_100_history && selectedSong.top_100_history.length > 0) {
            const { top_100_history } = selectedSong
            const _start = dayjs.unix(top_100_history[top_100_history.length - 1].created_at).format('MMM d')
            const _end = top_100_history[top_100_history.length - 1].removed_at ? top_100_history[top_100_history.length - 1].removed_at : parseInt(selectedSong.status) >= 5 ? selectedSong.dateSold : undefined
            return <>Top 100 Spot<br />{_start} - {_end !== undefined ? dayjs.unix(_end).format('MMM d') : '-'}</>
        }
        else return <>Top 100 Spot</>

    }
    return (
        <>
            <h2 style={{ margin: '20px 0 0' }}>Top 100 Data</h2>
            <GridItem gridRowGap="5px" width="100%" justifyItems="center" display="grid" gridTempRows="min-content min-content" gridTempCols="repeat(auto-fit, minmax(100px, 1fr))">
                {
                    Object.keys(songInfo).map(it => {
                        const title = it !== 'Top 100 Spot' ? it.replaceAll('_', " ").toUpperCase() : formatTop100()
                        return (
                            <>
                                <GridItem fontSize="10px" alignText="center" gridRow="2" color="555555" transformText="capitalize" width="70px">{title}</GridItem>
                                <GridItem justifyContent="center" fontSize="16px" alignText="center" gridRow="1" border="1px solid #ffffff" borderRadius="50%" height="50px" width="50px">
                                    <div style={{ marginBottom: '2px' }}>{songInfo[it] === '' || songInfo[it] === 0 ? '-' : songInfo[it]}</div></GridItem>
                            </>
                        )
                    })
                }
            </GridItem>
        </>
    )
}

const Content = ({ selectedSong, closePopup }) => {
    const _showTrailing = showTrailing(selectedSong.price, selectedSong.contributors)
    const _songStatus = parseInt(selectedSong.status)
    const getText = () => {
        if (_songStatus == 7)
            return ` sold at a total price of ${calculatePriceWithFee(selectedSong.price, _showTrailing).total}.`
        if (_songStatus == 6)
            return ` is waiting on Azaa admin to release funds.`
        if (_songStatus == 5)
            return ` is waiting on user confirmation of deliverables transfer.`
        if (_songStatus == 4)
            return ` is for sale at a total price of ${calculatePriceWithFee(selectedSong.price, _showTrailing).total} with the following splits:`
        if (_songStatus == 3)
            return ` is waiting for contributor agreement to sell at a total price of ${calculatePriceWithFee(selectedSong.price, _showTrailing).total} with the following splits:`
        else return <></>
    }
    return (
        <>
            <Flexbox height="100%" column overflow="auto" relative alignItems="start" gap="20px">
                {
                    _songStatus >= 3 && <Blurb>
                        <Strong style={{ textTransform: 'none' }}>'{selectedSong.song_title}'
                        </Strong>
                        {getText()}
                    </Blurb>
                }

                {_songStatus > 4 &&
                    <>
                        <BuyerDetails selectedSong={selectedSong} />
                        {/* <PaypalDetails selectedSong={selectedSong} /> */}
                        <TransactionDetailsBuyerPaypal selectedSong={selectedSong} />
                    </>
                }

                {/* {(_songStatus >= 3 && _songStatus < 5) && <Blurb>
                    <Strong style={{ textTransform: 'none' }}>'{selectedSong.song_title}'
                    </Strong>
                    {_songStatus < 5 ? ' will be listed' : ' sold'} at a total price of {` ${calculatePriceWithFee(selectedSong.price, _showTrailing).total}`} with the following splits.
                </Blurb>} */}
                {_songStatus === 2 &&
                    <Blurb>
                        A contributor rejected listing <Strong style={{ textTransform: 'none' }}>'{selectedSong.song_title}'</Strong> at a total price of {`${calculatePriceWithFee(selectedSong.price, _showTrailing).total}`} with the following splits.
                    </Blurb>
                }
                {
                    _songStatus >= 4 && <h2 style={{ margin: '20px 0 0' }}>Contributors</h2>
                }
                <Table2 background="#1c1c1c" >
                    <TableRow >
                        <TableCell props={{ padding: '0', width: '30px', height: '100%' }}></TableCell>
                        <TableCell props={{ padding: '20px 10px', width: '100%' }}>Contributor Paypal Email</TableCell>
                        <TableCell width="min-content" props={{ whiteSpace: 'nowrap', padding: '0', textAlign: 'center' }}>Split [%]</TableCell>
                        <TableCell width="min-content" props={{ whiteSpace: 'nowrap', padding: '20px 10px' }}>Split [$]</TableCell>
                        <TableCell width="min-content" props={{ width: 'min-content', textAlign: 'center' }}>Agreement</TableCell>
                    </TableRow>

                    {

                        selectedSong.contributors.map((contributor, index) => {

                            return (
                                <TableRow >
                                    <TableCell props={{ padding: '20px 10px', width: '30px', height: '100%' }} className={`${index} row`} width="30px" height="100%" textAlign="center">
                                        {contributor.email === selectedSong.email &&
                                            <ToolTip absolute justifyContent="center" bottom="30px" left="50px" toolTipText="Primary Contributor"><div style={{ position: 'relative', borderRadius: '3px', height: '6px', width: '6px', background: 'var(--yellow)' }}></div></ToolTip>

                                        }
                                    </TableCell>
                                    <TableCell props={{ padding: '20px 10px' }} className={`${index} row`} width="100%" height="54px" padding="0 10px">
                                        <ToolTip height="100%" bottom="40px" justifyContent='start' absolute toolTipText={<GridItem gap="10px" display="grid" gridTempCols="min-content 1fr">
                                            <div>Paypal:</div>
                                            <EmailLink href={`mailto:${contributor.songwriterPaypal}`}>{contributor.songwriterPaypal}
                                            </EmailLink>
                                            <div>Contact:</div><EmailLink href={`mailto:${contributor.email}`}>{contributor.email}
                                            </EmailLink></GridItem>}>
                                            <EmailLink href={`mailto:${contributor.songwriterPaypal}`}>{contributor.songwriterPaypal}
                                            </EmailLink>
                                        </ToolTip>
                                    </TableCell>
                                    <TableCell props={{ padding: '20px 10px', textAlign: 'center' }} className={`${index} row`} width="min-content">{contributor.split}%</TableCell>
                                    <TableCell props={{ padding: '20px 10px' }} className={`${index} row`} width="min-content">{calculateCut(contributor.split, selectedSong.price, _showTrailing)}</TableCell>
                                    <TableCell props={{ padding: '20px 10px', textAlign: 'center', width: 'min-content' }} className={`${index} row`} width="min-content">{contributor.agreement !== null ? <><img src={contributor.agreement === '1' ? Agreed : Disagreed} alt={contributor.agreement === '1' ? "agreed" : "disagreed"} style={{ width: "15px" }} /></> : '-'}</TableCell>
                                </TableRow>
                            )
                        })
                    }
                    <TableRow className="tableHeader" background='#291229'>
                        <TableCell props={{ fontWeight: '600', color: 'var(--purpleGrey)', padding: '0', width: '30px', height: '100%', colSpan: '3', textAlign: 'end' }}>Song Price</TableCell>
                        <TableCell props={{ fontWeight: '600', color: 'var(--purpleGrey)', padding: '10px 10px', width: '100%', colSpan: '2' }}>{`${adjustPrice(selectedSong.price, showTrailing)}`}</TableCell>
                    </TableRow >
                    <TableRow className="tableHeader" background='#291229'>
                        <TableCell props={{ fontWeight: '600', color: 'var(--purpleGrey)', padding: '0', width: '30px', height: '100%', colSpan: '3', textAlign: 'end' }}>+ 15% Azaa Platform Fee</TableCell>
                        <TableCell props={{ fontWeight: '600', color: 'var(--purpleGrey)', padding: '10px 10px', width: '100%', colSpan: '2' }}>{`+ ${adjustPrice(selectedSong.price, showTrailing)}`}</TableCell>
                    </TableRow>
                    <TableRow className="tableHeader" background='#291229'>
                        <TableCell props={{ fontWeight: '600', color: 'var(--purpleGrey)', padding: '0', width: '30px', height: '100%', colSpan: '3', textAlign: 'end' }}>Listing Price</TableCell>
                        <TableCell props={{ fontWeight: '600', color: 'var(--purpleGrey)', padding: '10px 10px', width: '100%', colSpan: '2' }}>{calculatePriceWithFee(selectedSong.price, showTrailing).total}</TableCell>
                    </TableRow>
                </Table2>
                {_songStatus >= 4 && <SongBadges selectedSong={selectedSong} />}
                {/* <TableContainer>
                    <Table
                        width="100%"
                        height="min-content"
                        content={tableContent}
                        noHover={true}
                        tableHeaderTextStyling={{ textTransform: 'uppercase' }}
                        tableHeader={["Contributor", "Split [%]", "Split [$]", "Agreement"]}
                        gridTemplateColumns='1fr 60px 5rem 5rem'
                        rowAlignmentArray={rowAlignmentArray}
                        gridColGap="20px"
                        padding="10px 20px"
                    />
                    <PricingTable>
                        <div>Song Price</div><div>{`${adjustPrice(selectedSong.price, showTrailing)}`}</div>
                        <div>+ 15% Azaa Platform Fee</div><div> + {calculatePriceWithFee(selectedSong.price, showTrailing).fee}</div>
                        <div>Listing Price</div><div>{calculatePriceWithFee(selectedSong.price, showTrailing).total}</div>
                    </PricingTable>
                </TableContainer> */}

            </Flexbox>
            <ButtonContainer items={1}>
                <Button
                    text="Close"
                    width="100%"
                    onClick={() => {
                        closePopup()
                        //setPopupVisibility(false)
                    }}
                    background="transparent"
                    border="1px solid #ffffff" />
            </ButtonContainer>
        </>
    )
}

const Popup = ({ visible, closePopup, selectedSong }) => {
    const { currentUser } = useAuth();

    return (
        <PopupWrapperOverlay visible={visible} onClick={closePopup}>
            <PopupWrapper visible={visible} onClick={e => e.stopPropagation()}>
                <Row>
                    <Title>Song Details - {selectedSong.song_title}</Title>
                    <ClosePopup closePopup={closePopup} />
                </Row>
                <Content
                    selectedSong={selectedSong}
                    closePopup={closePopup}
                />
            </PopupWrapper>
        </PopupWrapperOverlay>

    )
}
const SongDetailsModal = ({ isApproved, popupVisible, type, value, showModal, closePopup, selectedSong, setPopupVisibility }) => {
    return (
        <>
            <Wrapper isApproved={isApproved} selectedSong={selectedSong}>
            </Wrapper>
            <Popup
                selectedSong={selectedSong}
                setPopupVisibility={setPopupVisibility}
                closePopup={closePopup}
                visible={popupVisible !== undefined ? popupVisible : false}
                type={type}
                value={value} />
        </>
    )
}

export default SongDetailsModal
