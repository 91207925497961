import styled from 'styled-components'

export const TableRowWrapper = styled.div`
display: table-row;
transition: background 0.5s ease-out, color 0.3s ease-out;
background: ${props => props.isDragging ? 'var(--yellow) !important' : props.background ? props.background : ''};
color: ${props => props.isDragging ? '#000000 !important' : ''};
width: 100%;
outline: ${props => props.isDragging ? 'inset 1px #1A1A1A' : 'none'};
user-select: none;
position: relative;

`;

export const TableCell = styled.div`
display: table-cell;
width: ${props => props.width ? props.width : 'auto'};
min-width: ${({ minWidth }) => minWidth};
vertical-align: middle;
text-align: ${props => props.textAlign ? props.textAlign : 'left'};
padding: 20px 10px;

`;

export const DragCountWrapper = styled.div`
height: 20px;
display: flex;
color: var(--yellow);
justify-content: center;
align-items: center;
width: 20px;
background: #000000;
border: 2px solid var(--yellow);
box-shadow: 0 0 5px rgb(0,0,0);
border-radius: 50%;
position: absolute;
left: -20px;
font-weight: 700;
top: -30px;
line-height: 0.5rem;
font-size: 10px;
`;