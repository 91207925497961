import React, { useState, useEffect, useRef } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import InfiniteScroll from "react-infinite-scroll-component";

import { Item, TaskList, CheckboxStyle, TableCell, Table, ToolTipItem } from './Top100New.styles'

import Flexbox from '../Flexbox'
import Top100Modal from './Top100Modal'
import BarItem from './BarItem'
import Button from '../Button'
import PageNav from '../AdminPanel/PageNav'
import GridItem from '../GridItem'
import SearchBar from '../SearchBar'
import DotMenu from '../DotMenu'
import TableRow from './TableRow'
import { calculatePriceWithFee } from '../../helpers'
import { updateRelTime } from '../../utils'
import dayjs from 'dayjs'
import { useTop100NewHook } from '../../hooks/useTop100NewHook'

import clock from '../../images/clockIcon.svg'
import ExpandIcon from '../../images/expandIcon.svg'
import favoritesIcon from '../../images/favoriteIcon.svg'

const Checkbox = ({ checked, onChange }) => (
    <CheckboxStyle><input type="checkbox" onChange={onChange} checked={checked} /><span></span></CheckboxStyle>
)

const TableHeader = ({ selected, isTop100 }) => {
    const toolTipText = isTop100 ? 'Time spent on Top 100 in this spot.' : 'Time elapsed since song was listed for sale.'
    return (
        <>
            <div className="tableHeader" style={{ display: 'table-row' }}>
                {/* <TableCell width="30px"><GridItem position="relative"><DotMenu selected={selected} fill="#ffffff" hoverActive={true} status="1" /></GridItem></TableCell> */}
                <TableCell width="30px" textAlign="center"></TableCell>
                {isTop100 &&
                    <TableCell width="3rem" textAlign="center" >
                        Spot
                    </TableCell>}
                <TableCell>Title</TableCell>
                <TableCell>
                    Genre(s)
                </TableCell>
                <TableCell>Price</TableCell>
                <TableCell textAlign="center">
                    <ToolTipItem right="0">
                        <img src={favoritesIcon} alt="numberFavs" style={{ width: "12px", height: '12px' }} />
                        <div className="tooltip">No. of buyers who favorited this song</div>
                    </ToolTipItem>
                </TableCell>
                <TableCell width="30px">

                </TableCell>
            </div>
            <div className="tableHeader" style={{ display: 'table-row' }}>
                <TableCell width="30px" textAlign="center"></TableCell>
                {isTop100 &&
                    <TableCell width="3rem" textAlign="center" >
                        <ToolTipItem
                            title={toolTipText}
                            left="0"
                        >
                            <img src={clock} alt="clockIcon" style={{ width: "15px", opacity: '0.35' }} />
                            <div className="tooltip" >{toolTipText}</div>
                        </ToolTipItem>
                    </TableCell>}
                <TableCell></TableCell>
                <TableCell><Flexbox fontSize="10px" opacity="0.35">Tempo</Flexbox></TableCell>
                <TableCell></TableCell>
                <TableCell textAlign="center">
                </TableCell>
                <TableCell width="30px"></TableCell>
            </div>
        </>
    )

}

const TList = ({ provided, innerRef, children }) => {
    // const innerRef = useRef(null)
    return (<Table
        height="100%"
        id="taskList"
        ref={innerRef}
        width="500px"
        {...provided.draggableProps}
        {...provided.dragHandleProps}
    >{children}</Table>)
}

const InfScroll = ({ isInfScroll = false, children, ...props }) => {
    const { column, songs, fetchMoreOnScroll, nbHits } = props
    console.log("nbHits", nbHits.current, songs)
    const columnScrollRef = useRef(null)
    if (isInfScroll)
        return (
            <InfiniteScroll
                height={'calc(70px * 9)'} //row height * count
                width="100%"
                ref={columnScrollRef}
                dataLength={songs.length}
                loader={<Flexbox row justifyContent="center" height="60px" color="var(--yellow)" >Checking for more songs...</Flexbox>}
                next={() => {
                    if (typeof (fetchMoreOnScroll) === 'function' && nbHits.current > songs.length)
                        fetchMoreOnScroll(column.id)
                }}
                hasMore={nbHits.current > songs.length}>
                {children}
            </InfiniteScroll>
        )
    else
        return (<>{children}</>)
}

const Column = (props) => {
    const { onCheck, selected, column, songs, index, fetchMoreOnScroll, nbHits } = props
    const dotMenuRef = useRef(null)
    const dotMenuRefWrapper = useRef(null)
    //provided
    //droppable props = need to be applied to the component
    return (
        <GridItem height="100%" alignItems="start" padding="50px 30px" background={column.background} overflow="hidden" gridCol={index + 1}>
            <h1>{column.title}</h1>
            <p style={{ color: '#555555', fontWeight: '500' }}>{column.subtext}</p>
            <div style={{ borderTop: '2px solid rgba(255,255,255,0.15)', width: "100%" }}></div>

            <Droppable droppableId={column.id}>
                {
                    (provided, snapshot) =>
                        <GridItem
                            // overflowX="hidden"
                            height="100%"
                            overflow={column.title === 'Top 100' ? "auto" : undefined}
                            alignItems="start"
                            margin="20px 0 0"
                            position="relative"
                            boxShadow={snapshot.isDraggingOver ? "inset 0 0 20px rgba(0,0,0,.5)" : 'none'}
                            background={snapshot.isDraggingOver ? '#3c3c3c' : column.background}
                            transition="background 0.5s ease"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            refID={provided.innerRef}
                            width="100%">

                            <Table

                                isTop100={column.title === 'Top 100' ? true : undefined}
                                background={column.background}
                            >
                                <InfScroll isInfScroll={column.title === 'Options' ? true : false} column={column} {...props}>
                                    <TableHeader selected={selected} isTop100={column.title === 'Top 100' ? true : undefined}></TableHeader>
                                    {
                                        songs.length > 0 && songs.map((song, index) => {


                                            const genres = JSON.parse(song.content.genre)
                                            const spot = songs.findIndex(it => it.id === song.id) + 1

                                            return (
                                                <Draggable
                                                    key={song.id.toString()}
                                                    draggableId={song.id.toString()}
                                                    index={index}>
                                                    {(provided, snapshot) =>
                                                        <TableRow className="tablerow" provided={provided} snapshot={snapshot} index={index} song={song} {...props} spot={spot}
                                                            background={index % 2 === 0 ? column.title === 'Top 100' ? '#282828' : '#313131' : ''}
                                                        />
                                                    }
                                                </Draggable>
                                            )
                                        }
                                        )
                                    }
                                </InfScroll>
                                {provided.placeholder}
                            </Table>
                        </GridItem>
                }
            </Droppable>
        </GridItem>
    )
}

const Top100New = ({ songs, options, ...props }) => {
    const { fetchMoreOnScroll, nbHits } = props
    const {
        onCheck,
        revertChanges,
        getHomeColumnTitle,
        setButtonActions,
        button,
        setModalType,
        onDragStart,
        onDragEnd,
        loading,
        reorderMultiDrag,
        multiDragAwareReorder,
        closeModal,
        modalDetails,
        changed,
        data,
        setData,
        searchValue,
        onSearchChange,
        setFilters,
        filters,
        handleActionForTop100Spot,
        onMoveViaBar,
        setSelected,
        selected } = useTop100NewHook({ songs, options })
        console.log("DATA", data)

    if (loading) return <></>
    if (data.columnOrder !== undefined)
        return (
            <>
                <DragDropContext
                    onDragStart={onDragStart}
                    // onDragUpdate
                    onDragEnd={multiDragAwareReorder}
                >
                    <GridItem alignItems="start" position="relative" gridTempCols="3fr minmax(500px,3fr)" gridTempRows="1fr min-content" height="100%" width="100%" display="grid" style={{ flexGrow: '1' }}>

                        {

                            data.columnOrder.map((id, index) => {
                                const column = data.columns[id]
                                const songs = column.songIds.map(it => data.songs[it])
                                // const songs = Array(column.songIds.length).fill(0)
                                // for (let index = 0; index < column.songIds.length; index++) {
                                //     const song = column.songIds[index];
                                //     console.log("song data", song)
                                //     songs.push(data.songs[song])

                                // }
                                // console.log("songs:::::::", songs)
                                return (
                                    <Column
                                        index={index}
                                        setButtonActions={setButtonActions}
                                        setModalType={setModalType}
                                        onCheck={onCheck}
                                        selected={selected}
                                        key={column.id}
                                        column={column}
                                        songs={songs}
                                        getHomeColumnTitle={getHomeColumnTitle}
                                        reorderMultiDrag={reorderMultiDrag}
                                        nbHits={nbHits}
                                        fetchMoreOnScroll={fetchMoreOnScroll} />)
                            })
                        }
                        {

                            (modalDetails.showModal && modalDetails.fullModal) &&
                            <GridItem position="absolute" gridRow="1 / span 1" gridCol="2" height="100%" alignItems="start" background={data.columns[data.columnOrder[1]].background} overflow="hidden">
                                <Top100Modal
                                    left="0"
                                    zIndex="5"
                                    modalDetails={modalDetails}
                                    modalType={modalDetails.modalType}
                                    showModal={modalDetails.showModal}
                                    closeModal={closeModal} />
                            </GridItem>
                        }
                        <GridItem zIndex="5" boxShadow="0 0 10px #12032d" gridColGap="10px" padding="10px" gridTempCols="1fr min-content min-content min-content" gridTempRows="min-content" justifyContent="start" background="#291229" width="100%" display="grid" gridCol="1 / span 2" >
                            <GridItem gridCol="1" width="100%" position="relative" justifyContent="end" alignContent="end" alignItems="end">
                                {modalDetails.showModal && <BarItem
                                    modalType={modalDetails.modalType}
                                    data={modalDetails.data}
                                    handleActionForTop100Spot={handleActionForTop100Spot}
                                    showModal={modalDetails.showModal}
                                    closeModal={closeModal} />}
                                {!modalDetails.showModal &&
                                    <GridItem alignItems="start" flexDirection="row" overflow="visible">
                                        {/* <SearchBar
                                            value={searchValue}
                                            onChange={onSearchChange}
                                            setList={setFilters}
                                            list={filters}
                                        /> */}
                                        <PageNav
                                            isFooter={true}
                                            partitionedSearch={true}
                                            searchPartitions={[
                                                { innerText: 'Top 100', onClick: () => { }, value: 'top-100' },
                                                { innerText: 'Options', onClick: () => { }, value: 'options' },
                                            ]}
                                            hideHeader={true}
                                            altLayout={true}
                                            {...props}
                                            innerPadding="10px 30px"
                                            padding={props.content.type === 'buyers' ? '50px 50px 0' : undefined}>

                                        </PageNav>
                                    </GridItem>}
                            </GridItem>
                            <GridItem gridCol="2" width="30px" position="relative">
                                <DotMenu
                                    conditionMet={() => selected.length > 0 && !modalDetails.showModal}
                                    position="absolute"
                                    bottom="100px"
                                    border="1px solid #ffffff"
                                    borderRadius="50%"
                                    transform="rotate(90deg)"
                                    selected={selected} fill="#ffffff"
                                    hoverActive={true} status="1" /></GridItem>
                            <GridItem width="min-content" gridCol="3">
                                <Button
                                    color={'#291229'}
                                    onClick={button.options[button.selected]['cancel'].onClick}
                                    disabled={changed.current || (modalDetails.showModal && !modalDetails.fullModal)}
                                    width="160px"
                                    borderRadius="5px"
                                    height="49px"
                                    hoverColor={changed.current || (modalDetails.showModal && !modalDetails.fullModal) ? '#eb8900' : "rgba(255,255,255,0.15)"}
                                    border={changed.current || (modalDetails.showModal && !modalDetails.fullModal) ? "2px solid #eb8900" : '2px solid rgba(255,255,255,0.1)'}
                                    background={changed.current || (modalDetails.showModal && !modalDetails.fullModal) ? '#eb8900' : "rgba(255,255,255,0.15)"}>{button.options[button.selected]['cancel'].text}</Button>
                            </GridItem>
                            <GridItem width="min-content" borderRadius="5px" gridCol="4" alignItems="end">
                                <Button
                                    color={'#291229'}
                                    onClick={
                                        button.selected === 'option-2' ?
                                            onMoveViaBar :
                                            async () => {
                                                await button.options[button.selected]['submit'].onClick(data)
                                            }
                                    }
                                    disabled={!changed.current}
                                    // disabled={!changed.current || (modalDetails.showModal && !modalDetails.fullModal)}
                                    width="160px"
                                    borderRadius="5px"
                                    height="49px"
                                    hoverColor={changed.current || (modalDetails.showModal && !modalDetails.fullModal) ? '#00ffff91' : "rgba(255,255,255,0.15)"}
                                    border={changed.current || (modalDetails.showModal && !modalDetails.fullModal) ? "2px solid aqua" : '2px solid rgba(255,255,255,0.1)'}
                                    background={changed.current || (modalDetails.showModal && !modalDetails.fullModal) ? 'aqua' : "rgba(255,255,255,0.15)"}>{button.options[button.selected]['submit'].text}</Button>
                            </GridItem>
                        </GridItem>
                    </GridItem>

                </DragDropContext>

            </>
        )
    else return <></>
}

export default Top100New