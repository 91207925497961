import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom'
import API from '../API';
import dayjs from 'dayjs'
import { useAuth } from '../contexts/AuthContext'
import { updateRelTime } from '../utils'
import { toTitleCase } from '../helpers'
import { useNotifications } from './useNotifications'

import { Genre, Tempo } from '../objectPrototypes'

export const useAdminPanelHook = () => {
    const { currentUser } = useAuth()
    const location = useLocation()
    const [adminID, setAdminID] = useState('1')
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [itemsSubmitting, setItemsSubmitting] = useState([

    ])
    // audio
    const validTempos = (new Tempo()).validTempos()
    const validGenres = (new Genre()).validGenres()

    const resultsPerPage = 10//20
    const pageRef = useRef(1)
    const [page, setPage] = useState(1)
    const [totalResults, setTotalResults] = useState(0)
    const [showFilterDropdown, setShowFilterDropdown] = useState([false, false])
    const initialFilterState = {
        genre: [],
        playlist: 'Top 100',
        tempo: 'TEMPO',
        searchValue: '',
        searchTermList: []

    }
    const [contentFilter, setContentFilter] = useState(initialFilterState)
    const tempoDropdown = validTempos.map(it => ({
        value: it.split(' ')[0],
        innerText: it.split(' ')[0],
        onClick: async () => {
            if (contentFilter.tempo === it.split(' ')[0]) {
                setContentFilter(prev => ({ ...prev, tempo: 'TEMPO' }))
            } else {
                setContentFilter(prev => ({ ...prev, tempo: it.split(' ')[0] }))
                console.log("current filter", filter,)
            }
            setShowFilterDropdown(Array(2).fill(false))
        }
    }))
    // top100ColumnSearch.optionOrder.find( it => top100ColumnSearch.options[it].innerText === )
    const [top100ColumnSearch, setTop100ColumnSearch] = useState({
        options: {
            'option-1': {
                id: 'option-1',
                innerText: 'Top 100',
                value: 'top100',
                valueForSearch: 7,
                title: 'Top 100',
                onClick: function () {
                    console.log("THIS", this)
                    setTop100ColumnSearch(prev => ({ ...prev, selected: this.id }))

                }
            },
            'option-2': {
                id: 'option-2',
                innerText: 'Options',
                value: 'top100options',
                valueForSearch: 12,
                title: 'Options',
                onClick: function () {
                    console.log("THIS", this)
                    setTop100ColumnSearch(prev => ({ ...prev, selected: this.id }))

                }
            },
        },
        selected: 'option-1',
        optionOrder: ['option-1', 'option-2'],
    })
    const playlistDropdown = [
        {
            value: 'top100',
            innerText: 'Top 100',
            onClick: function () {
                console.log("this value", this.value)
                setContentFilter(prev => ({ ...prev, playlist: this.value }))
            }
        },
        {
            value: 'options',
            innerText: 'Options',
            onClick: function () {
                console.log("this value", this.value)
                setContentFilter(prev => ({ ...prev, playlist: this.value }))
            }
        }]

    const genreDropdown = validGenres.map(it => ({ value: it, innerText: it }))
    const filterDropdowns = { genreDropdown, tempoDropdown }

    const handleGenreChange = (event) => {
        if (contentFilter.genre.includes(event.target.attributes.value.value)) {
            if (contentFilter.genre.length === 1) {
                setShowFilterDropdown(Array(2).fill(false))
            }
            setContentFilter(prev => ({ ...prev, genre: prev.genre.filter((n) => { return n != event.target.attributes.value.value }) }))
        }
        else if (!contentFilter.genre.includes(event.target.attributes.value.value) && contentFilter.genre.length < 2) {
            if (contentFilter.genre.length === 1) {
                setShowFilterDropdown(Array(2).fill(false))
            }
            setContentFilter(prev => ({ ...prev, genre: [...prev.genre, event.target.attributes.value.value] }))
        }
    }

    const skipEmptyFilters = () => {

        const { genre, tempo } = contentFilter
        const _filter = {}
        if (tempo !== 'TEMPO')
            _filter.tempo = tempo
        if (genre.length > 0)
            _filter.genre = genre.reduce((a, b, index) => index > 0 ? a = a + ',' + b : b, '')
        if (location.pathname.includes('top-100') && contentFilter.searchValue !== '') {
            const playlist = top100ColumnSearch.options[top100ColumnSearch.selected].value
            _filter.playlist = playlist
        }

        return _filter
    }

    const fetchFilteredSongs = async (skipReset = false) => {
        triggerReset.current = true
        const _filter = skipEmptyFilters()
        if (!skipReset)
            setContentFilter(prev => ({ ...prev, searchValue: '', searchTermList: [] }))
        await pullSongs(1, filter, false, _filter)
        triggerReset.current = false
    }

    useEffect(() => {

        if (showFilterDropdown[0] !== true && showFilterDropdown[1] !== true && !triggerReset.current)
            fetchFilteredSongs()

    }, [contentFilter, showFilterDropdown])
    const { selected: selectedColumn } = top100ColumnSearch

    useEffect(() => {
        if (contentFilter.searchValue !== '') {
            console.log("triggered::::::::")
            fetchFilteredSongs(true)
        }
        console.log("triggered:::::::::", contentFilter.searchValue)

    }, [selectedColumn])

    const onSearchChange = (input) => {
        // setSearchValue(e.target.value)
        setContentFilter(prev => ({ ...prev, searchValue: input }))
    }

    const onSearchListUpdate = (newList) => {
        // const { searchValue, searchTermList } = contentFilter
        // if (searchTermList.includes(input))
        //     setContentFilter(prev => ({ ...prev, searchTermList: searchTermList.filter(it => it !== input) }))
        // else
        setContentFilter(prev => ({ ...prev, searchTermList: [...newList] }))
    }

    const sidebarlinks = {
        buyers: [
            { title: 'New Applicants', filterValue: 5 },
            { title: 'Accepted', filterValue: 6 },
        ],
        songwriters:
            [
                { title: "New Applicants", filterValue: 1 },
                { title: "Accepted", filterValue: 4 },
            ],
        songs: [
            { title: "New Songs", filterValue: 2 },
            { title: "Accepted", filterValue: 3 },
            { title: "Top 100", filterValue: 7 },
            // { title: "Sold", filterValue: 8 },
        ],
        transactions: [
            { title: "Pending Payout", filterValue: 9 },
            { title: "Completed", filterValue: 10 },
        ],
    };

    const [content, setContent] = useState({
        type: "songwriters",
        value: sidebarlinks.songwriters[0].title
    })

    const getAssociatedValue = (type, valueTitle) => {
        const category = sidebarlinks[type]
        if (!category)
            return {}
        return category.find(it => it.title === toTitleCase(valueTitle))
    }

    const nbHits = useRef(0)
    const [songs, setSongs] = useState([])
    const [options, setOptions] = useState([])
    const [filter, setFilter] = useState(1)

    const triggerReset = useRef(false)

    const [songStatusKey, setSongStatusKey] = useState(null)

    const todaysDate = "Today's Date: " + dayjs().format('ddd, MMMM D, YYYY')

    const [payload, setPayload] =
        useState({
            userID: null,
            songID: null,
            status: null,
            email: '',
            name: null,
            songTitle: null,
        })


    const onSearchReset = () => {
        triggerReset.current = false
    }

    const activeFilters = () => {

    }

    const fetchMoreOnScroll = async (columnId, _page = page, _filter = filter, _sort) => {
        console.log("columnId: ", columnId)
        pageRef.current = pageRef.current + 1;
        await pullSongs(pageRef.current, _filter, false, undefined, true, true)
    }

    const setData = (data, accessors = [], overwriteData = true) => {
        setTotalResults(data['total'])
        const primaryAccessor = accessors.find(id => id !== 'total' && id !== 'options')
        if (overwriteData) {
            setSongs(data[primaryAccessor])
        } else {

            setSongs(prev => ([...prev, ...data[primaryAccessor]]))
        }
        if (data['options']) {

            if (overwriteData) {
                console.log("overwriting")
                setOptions(data['options'])
            }
            else {
                console.log("keeping data")
                console.log("songs", options, data['options'])
                setOptions(prev => ([...prev, ...data['options']]))
            }
        }
    }

    const pullSongs = async (_page = page, _filter = filter, isCategorySwitch = false, _contentfilter = undefined, skipLoading = false, retainValues = false) => {
        if (!skipLoading)
            setLoading(true)
        setError(false)
        if (!retainValues)
            setOptions([])
        try {
            if (isCategorySwitch) {
                setSongs([])
                setTotalResults(0)
            }
            const response = await API.fetchSongs(currentUser, _page,
                resultsPerPage
                , _filter, _contentfilter ? _contentfilter : skipEmptyFilters())

            if (response.data.nbHits)
                nbHits.current = response.data.nbHits
            const responseKeys = Object.keys(response.data)
            if (responseKeys.length === 0 || response.data['status'] === false) {
                setSongs([])
                setTotalResults(0)
            } else {
                setData(response.data, responseKeys, !retainValues)
            }
            // if (response.data.songs !== undefined) {
            //     setSongs(response.data.songs)
            //     setTotalResults(response.data.total)
            //     if (response.data.options !== undefined) {
            //         setOptions(response.data.options)
            //     }
            // } else if (response.data.artists !== undefined) {
            //     setSongs(response.data.artists)
            //     setTotalResults(response.data.total)
            // } else if (response.data.buyers !== undefined) {
            //     setSongs(response.data.buyers)
            //     setTotalResults(response.data.total)
            // } else {
            //     setSongs([])
            //     setTotalResults(0)

            // }

            setLoading(false)
            setError(false)
        } catch (error) {
            setLoading(false)
            setError(true)
        }
    }

    useEffect(() => {
        const fetch_song_statuses = async () => {
            try {
                const valid_statuses = await API.fetchSongStatusKey();
                setSongStatusKey(valid_statuses)
                //console.log(valid_statuses)
            } catch (error) {
                //console.log(error)
            }
        }
        // fetch_song_statuses().then(() => { pullSongs(); })
        fetch_song_statuses()
    }, [])

    useEffect(() => {
        if (location.pathname !== '/') {
            triggerReset.current = true
            const { pathname } = location
            let [_type, _value] = pathname.split('/').filter(it => it.length)
            if (_value === 'payout') {
                setContent({
                    type: _type,
                    value: "Pending Payout"
                })
                setLoading(false)
            } else if (_value.toLowerCase() === 'completed') {
                setContent({
                    type: _type,
                    value: "Completed"
                })
                setLoading(false)
            } else {
                _value = toTitleCase(_value.replaceAll('-', ' '))
                _value = getAssociatedValue(_type, _value)
                setContent({
                    type: _type,
                    value: _value.title
                })
                pageRef.current = 1
                setPage(1)
                setFilter(_value.filterValue)
                setContentFilter(initialFilterState)
                pullSongs(1, _value.filterValue, content.value !== _value.title || content.type !== _type)
                triggerReset.current = false
            }

        }
    }, [location])

    return {
        payload,
        options,
        setOptions,
        setPayload,
        sidebarlinks,
        content,
        setContent,
        page,
        setPage,
        pageRef,
        totalResults,
        resultsPerPage,
        adminID,
        songs,
        filter,
        setFilter,
        loading,
        setLoading,
        error,
        setError,
        songStatusKey,
        setSongStatusKey,
        todaysDate,
        pullSongs,
        itemsSubmitting,
        setItemsSubmitting,
        filterDropdowns,
        showFilterDropdown,
        contentFilter,
        setShowFilterDropdown,
        handleGenreChange,
        playlistDropdown,
        top100ColumnSearch,
        onSearchChange,
        onSearchListUpdate,
        onSearchReset,
        triggerReset,
        fetchMoreOnScroll,
        nbHits
    }
}