import styled from "styled-components";

export const NavWrapper = styled.div`
    width: 100%;
    display: flex;
    max-width: 800px;
    align-items: center;
    justify-content: center;

`;

export const Wrapper = styled.div`
width: 446px;
height: 374px;
margin: 0 20px;
display: flex;
align-items: center;
    justify-content: center;

`;

export const QuestionWrapper = styled.div`
position: relative;
width: 400px;
margin-bottom: 40px;
`;