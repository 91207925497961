import React, { useState, useEffect, useRef } from 'react'

import Flexbox from '../Flexbox'
import { throttle } from '../../utils'

const HideShowText = ({ text }) => {
    const containerRef = useRef(null)
    const textRef = useRef(null)
    const [showViewMore, setShowViewMore] = useState(false)
    const [showFullString, setShowFullString] = useState(false)

    useEffect(() => {
        if (containerRef.current && textRef.current) {
            setShowViewMore(textRef.current.offsetWidth > containerRef.current.offsetWidth || textRef.current.offsetHeight > containerRef.current.offsetHeight || text.length > 200)
        }

    }, [text])

    const [windowSize, setWindowSize] = useState(window.innerWidth)

    useEffect(() => {
        let isMounted = true;
        const handleResize = throttle(() => {
            if (isMounted) {
                setWindowSize(window.innerWidth)
            }
        }, 1000)
        window.addEventListener("resize", handleResize);
        return () => {
            isMounted = false
            window.removeEventListener("resize", handleResize)
        };
    }, [])
    console.log("windowSize", windowSize)

    return (
        <Flexbox
            refID={containerRef}
            transition='height 0.25s '
            relative
            row
            maxWidth={0.2 * windowSize + 'px'}
            overflow="hidden"
            maxHeight="30px"
            className=""
            // whiteSpace="nowrap"
            justifyContent="start"
            alignItems="start"
            textOverflow="ellipsis"
            onClick={(e) => {

            }}>
            <p ref={textRef} style={{ position: 'relative', margin: '0' }}>
                {!showFullString && showViewMore ? text.substring(0, windowSize > 1100 ? parseInt(0.03 * windowSize) : 15) + '...' : text}
                {showViewMore &&
                    < span style={{ marginLeft: '2px', whiteSpace: 'nowrap', color: 'var(--yellow)', fontWeight: '700' }} onClick={(e) => {
                        if (!e.target.parentElement.parentElement.classList.contains('showContent')) {
                            e.target.parentElement.parentElement.classList.add('showContent') //e.target.parentElement.className
                            setShowFullString(true)
                            e.target.innerText = 'Hide'
                        }
                        else {
                            e.target.parentElement.parentElement.classList.remove('showContent')
                            setShowFullString(false)
                            e.target.innerText = 'View'
                        }
                    }}>View</span>
                }
            </p>

        </Flexbox >
    )
}

export default HideShowText