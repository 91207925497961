import React, { useState, useEffect, useRef } from 'react'
import {
    DropDownContainerBox,
    DropDownNavBtn,
    DropdownListContainer,
    BackgroundWrapper,
    DropdownArrow,
    DropdownList,
    DropdownHeader,
    DropdownContainer,
    ListItem,
    RemoveButton,
    LabelStyled
} from './Dropdown.styles'

import DropdownIcon from '../../images/dropdownArrow.svg'

import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'

import Logo from '../../images/headerLogo.svg'

import {
    ACCOUNT_ACTIVITY,
    LOGOUT,
    ACCOUNT_SETTINGS,
    DASHBOARD
} from '../../config'

const Label = ({ name, label, marginBottom }) => (
    <LabelStyled htmlFor={name} marginBottom={marginBottom}>{label}</LabelStyled>

)

const CloseDropdown = ({ shouldRotate }) => (

    <DropdownArrow src={DropdownIcon} alt="dropdown-arrow" shouldRotate={shouldRotate} />
)

const Option = ({ showRemoveButton = false, itemPadding, itemHeight, letterSpacing, fontSize, fontWeight, textAlign, color, colorOnSelect, value, defaultSelect, innerText, onClick, isSelected, justifyContent, ...props }) => {
    return (<ListItem height={itemHeight} itemPadding={itemPadding} defaultSelect={defaultSelect} value={value}
        isSelected={isSelected}
        color={color}
        textAlign={textAlign}
        colorOnSelect={colorOnSelect}
        justifyContent={justifyContent}
        letterSpacing={letterSpacing}
        fontSize={fontSize}
        fontWeight={fontWeight}
        onClick={onClick}
        {...props}>


        {innerText}
        {(isSelected && showRemoveButton) && (
            <RemoveButton onClick={onClick} value={innerText} >&#10006;</RemoveButton>
        )}
    </ListItem>)
};

const Dropdown = ({
    hasFocus = false,
    showArrowDropdown = true,
    dropdownRef,
    currSelection,
    textAlign,
    justifyContent,
    label,
    showLabel = false,
    showHeader = false,
    height,
    itemHeight,
    itemPadding,
    showRemoveButton,
    width,
    boxShadow,
    _handleChange,
    flexDirection,
    border,
    background,
    listBackground,
    letterSpacing,
    fontSize,
    fontWeight,
    name,
    marginBottom,
    listMarginBottom,
    color,
    menuState,
    setMenuState,
    dropdownPosition,
    dropdownTop,
    dropdownZIndex,
    colorOnSelect,
    dropdownListMarginTop,
    options, setState, handleStateChange = () => { }, valueX, key, id, handleOrderChange, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState(currSelection || valueX)
    const [currentVal, setCurrentVal] = useState(currSelection || valueX)
    const bgdRef = useRef(null)
    const initial = useRef(true);
    const handleChange = (event) => {

        setIsOpen(!isOpen)

    }

    useEffect(() => {
        if (initial.current) {
            initial.current = false;
            return;
        }
        console.log("change initiated")
        setIsOpen(!isOpen)
    }, [])

    useEffect(() => {
        if (hasFocus && dropdownRef && dropdownRef.current)
            dropdownRef.current.focus()
    }, [hasFocus])

    if (showHeader)
        return (<>
            {/* {isOpen && <BackgroundWrapper ref={bgdRef} onClick={(e) => { if (e.target === bgdRef.current && typeof (_handleChange) === 'function') _handleChange() }} />} */}
            {
                showLabel &&
                <Label
                    name={name}
                    label={label}
                    marginBottom={marginBottom}
                ></Label>
            }
            <DropdownContainer
                textAlign={textAlign}
                width={width}
                id={id}
                boxShadow={boxShadow}
                {...props}>

                <DropdownListContainer
                    border={border} flexDirection={flexDirection}>
                    {
                        showHeader &&
                        <DropdownHeader
                            onClick={setMenuState}
                            fontWeight={fontWeight}
                            {...props}
                        >{valueX || currSelection}
                            {showArrowDropdown && <CloseDropdown shouldRotate={menuState} />}
                        </DropdownHeader>
                    }
                    <DropdownList
                        dropdownPosition={dropdownPosition}
                        dropdownTop={dropdownTop}
                        dropdownZIndex={dropdownZIndex}
                        listMarginBottom={listMarginBottom}
                        // ref={dropdownRef ? dropdownRef : undefined}
                        onFocus={
                            () => {
                                console.log("focus is here")
                            }
                        }
                        boxShadow={boxShadow}
                        listBackground={listBackground}
                        border={border}
                        marginTop={dropdownListMarginTop}
                    >
                        {
                            menuState &&
                            options.map(option => {
                                console.log("option", option)
                                return (
                                    <Option
                                        itemHeight={itemHeight}
                                        itemPadding={itemPadding}
                                        color={color}
                                        letterSpacing={letterSpacing}
                                        fontSize={fontSize}
                                        fontWeight={fontWeight}
                                        textAlign={textAlign}
                                        colorOnSelect={colorOnSelect}
                                        justifyContent={justifyContent}
                                        value={option.value}
                                        showRemoveButton={showRemoveButton}
                                        innerText={option.innerText}
                                        key={option.value}
                                        defaultSelect={option.isDefault}
                                        onClick={(e) => {
                                            option.onClick(e)
                                            handleStateChange(e)
                                        }} //was selectNew
                                        isSelected={valueX ? valueX : currSelection ? currSelection === option.innerText : valueX.length ? valueX.includes(option.value) : false}
                                        {...option.props}
                                    />

                                )
                            })
                        }
                        <input ref={dropdownRef} id="dropdownRef" />
                    </DropdownList>
                </DropdownListContainer >



            </DropdownContainer>
        </>

        )
    return (<>
        {/* {isOpen && <BackgroundWrapper ref={bgdRef} onClick={(e) => { if (e.target === bgdRef.current && typeof (_handleChange) === 'function') _handleChange() }} />} */}
        {
            showLabel &&
            <Label
                name={name}
                label={label}
                marginBottom={marginBottom}
            ></Label>
        }
        <DropdownContainer
            textAlign={textAlign}
            width={width}
            id={id}>
            {menuState && (
                <DropdownListContainer flexDirection={flexDirection}
                    border={border}>
                    {
                        showHeader &&
                        <DropdownHeader
                            onClick={handleChange}
                        >{valueX || currSelection}
                            {showArrowDropdown && <CloseDropdown shouldRotate={isOpen} />}
                        </DropdownHeader>
                    }
                    <DropdownList
                        boxShadow={boxShadow}
                        listMarginBottom={listMarginBottom}
                        // ref={dropdownRef ? dropdownRef : undefined}
                        onFocus={
                            () => {
                                console.log("focus is here")
                            }
                        }
                        listBackground={listBackground}
                        border={border}
                        marginTop={dropdownListMarginTop}
                    >
                        {
                            options.map(option => {
                                return (
                                    <Option
                                        itemHeight={itemHeight}
                                        itemPadding={itemPadding}
                                        color={color}
                                        letterSpacing={letterSpacing}
                                        fontSize={fontSize}
                                        fontWeight={fontWeight}
                                        textAlign={textAlign}
                                        colorOnSelect={colorOnSelect}
                                        justifyContent={justifyContent}
                                        value={option.value}
                                        showRemoveButton={showRemoveButton}
                                        innerText={option.innerText}
                                        key={option.value}
                                        defaultSelect={option.isDefault}
                                        onClick={(e) => {
                                            option.onClick(e)
                                            handleStateChange(e)
                                        }} //was selectNew
                                        isSelected={valueX.length ? valueX.includes(option.value) : currSelection ? currSelection === option.innerText : false}
                                        {...option.props}
                                    />

                                )
                            })
                        }
                        <input ref={dropdownRef} id="dropdownRef" onFocus={() => console.log("focus is here")} />
                    </DropdownList>
                </DropdownListContainer>
            )}


        </DropdownContainer>
    </>)
}

export default Dropdown
