import { AZAA_PLATFORM_FEE_PERCENT } from './config'
export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const returnMonth = (date, abbreviated = false) => {
    const month = date.split('/')[0]
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthsAbbreviated = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    var month_index = month - 1;
    return abbreviated ? monthsAbbreviated[month_index] : months[month_index];
}

export const timeElapsed = (date) => {
    const dateReceived = new Date(date).getTime(); //new Date(date).getTime();
    const now = new Date();
    const dateFormatted = new Date(date).toLocaleString().split(' ')[0]
    const monthReceived = new Date(date).getMonth(); //now.getMonth() - 
    const monthsElapsed = Math.abs(now.getMonth() - new Date(dateReceived).getMonth());

    const nowTime = now.getTime();
    const delta = nowTime - dateReceived;
    const days = Math.floor(delta / (1000 * 60 * 60 * 24))
    const hours = Math.floor((delta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((delta % (1000 * 60)) / 1000);

    const str = monthsElapsed > 0 ? `${returnMonth(dateFormatted, true) + ' ' + dateFormatted.split('/')[1]}` : days > 0 ? `${days}d` : hours > 0 ? `${hours}h` : minutes > 0 ? `${minutes}m` : 'now';

    const result = delta < 0 ? "now" : str !== "now" && monthsElapsed <= 0 ? str : str;



    return result

}

export const timeElapsedFromCreatedAt = (createdAt) => {
    const date = createdAt.split(' ')[0]
    const dateMMDDYYYY = date.split('-')[1] + '/' + date.split('-')[2] + '/' + date.split('-')[0] + ' ' + createdAt.split(' ')[1];
    const result = timeElapsed(dateMMDDYYYY);
    return result
}


export const showTrailing = (price, contributors) => {
    if (price === '-' || price === '' || price === null || price === undefined || contributors.length === 0)
        return false
    const _price = typeof (price) === 'number' ? price : parseFloat(price.replace(/,/g, ''))
    return _price % 1 ? true
        : (_price * (AZAA_PLATFORM_FEE_PERCENT) / 100.00) % 1 ? true
            : contributors.map(c => _price * parseFloat(c.split) / 100.00).reduce((a, b) => { return b % 1 ? true : a }, false)
}

export const calculateCut = (my_split = null, total_price, showTrailing = false) => {
    const myCut = total_price !== undefined && total_price !== null && total_price !== '-' && my_split !== '-' ? (parseFloat(total_price.replace(/,/g, '')) * my_split / 100.00).toString().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
    return adjustPrice(myCut, showTrailing)
}
export const calculatePriceWithFee = (total_price, showTrailing = false) => {
    if (total_price === undefined || total_price === '-' || total_price === null)
        return { fee: '-', total: '-' }
    const price = typeof (total_price) === 'number' ? total_price : parseFloat(total_price.replace(/,/g, '').replace('$', ''))
    const myCut =
    {
        fee: (roundValue(price * (15) / 100.00)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        total: (roundValue(price * (100 + 15) / 100.00)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    return { fee: adjustPrice(myCut.fee, showTrailing), total: adjustPrice(myCut.total, showTrailing) }
}

export const adjustPrice = (price, showTrailing = false) => {
    const priceAdj = price !== undefined && price !== null ?
        price.split('.').length > 1 ?
            price.split('.')[0] + `${price.split('.')[1] !== '00' ? '.' + (price.split('.')[1] + '0').slice(0, 2) : showTrailing ? '.00' : ''}` :
            showTrailing ? price + '.00' :
                price : '-';
    return priceAdj !== '-' ? '$' + priceAdj : priceAdj
}

export const toTitleCase = (str) => {
    return str
        .toLowerCase()
        .split(' ')
        .map((word) => {
            return word[0].toUpperCase() + word.substr(1);
        })
        .join(' ');
}

export const numToFormattedPriceString = (val) => {
    let value = String(roundValue(val)).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    value = value.includes('.') ? value.split('.')[1].length > 1 ? value : value + '0' : value + '.00'
    return ('$' + value)
}

export const formatPrice = (price) => {
    let value = roundValue(price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    value = value.includes('.') ? value.split('.')[1].length > 1 ? value : value + '0' : value + '.00'
    return '$' + value
}

export const shortFormatPrice = (price) => {
    ///String(total_sales).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    if (price === '' || price === undefined || price === null || price === 0)
        return ''
    const factor = Math.log10(price)
    if (factor < 3)
        return numToFormattedPriceString(price)
    if (factor >= 6)
        return numToFormattedPriceString(Math.round(price / Math.pow(10, 6) * 10) / 10) + 'M'
    else
        return numToFormattedPriceString(Math.round(price / 1000 * 10) / 10) + 'k'

}
export const roundValue = (value, decimalPlaces = 2) => {
    return Math.round(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)
}