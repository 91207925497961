import React from 'react'

import { ToolTipItem, EmailLink, } from './ContributorTable.styles'

import { numToFormattedPriceString, showTrailing, calculatePriceWithFee, calculateCut } from '../../helpers'

import ToolTip from '../ToolTip'
import GridItem from '../GridItem'
import Table from '../Table2'
import TableRow from '../TableRow'
import TableCell from '../TableCell'

const TableHeader = ({ songStatus }) => {
    const headerProps = {
        padding: songStatus > 6 ? "20px 10px 0" : undefined
    }
    const paypalProps = {
        padding: songStatus > 6 ? "0 0 10px" : undefined,
        className: "paypalHeader",
        width: 'min-content',
        whiteSpace: "nowrap",
        textAlign: "center"
    }
    return (
        <>
            <TableRow className="tableHeader" >
                <TableCell width="30px" className="tableHeader" {...headerProps} padding="0"></TableCell>
                <TableCell width="max-content" {...headerProps}>Paypal Email</TableCell>
                <TableCell textAlign="right" whiteSpace="nowrap" width="min-content" {...headerProps}>Split [%]</TableCell>
                <TableCell textAlign={songStatus > 6 ? 'left' :"right"}  width="min-content" {...headerProps}>Split [$]</TableCell>
                {
                    songStatus > 6 && <TableCell colSpan="2" width="min-content" className="paypalHeader" textAlign="center">Paypal</TableCell>
                }

            </TableRow>
            {songStatus > 6 &&
                <TableRow className="tableHeader" >
                    {
                        Array(4).fill(0).map(it =>
                            <TableCell key={it} padding="0"></TableCell>)
                    }
                    <TableCell {...paypalProps}>Payout Item ID</TableCell>
                    <TableCell {...paypalProps}>Transaction ID</TableCell>
                </TableRow>
            }

        </>
    )
}

const ContributorTable = ({ data, hideHeader, ...props }) => {
    const paypalProps = {
        className: "paypalTableCell",
        width: 'min-content',
        whiteSpace: "nowrap",
        textAlign: "center"
    }
    const _status = parseInt(data.status)
    const _showTrailing = showTrailing(data.price, data.contributors)
    return (<>
        {!hideHeader && <h2 style={{ margin: '0' }}>Contributors</h2>}

        <Table oddHighlight={parseInt(data.status) > 6 ? true : undefined}>
            <TableHeader modalType='song-details' songStatus={parseInt(data.status)} />

            {

                data.contributors.map((contributor, index) => {

                    return (
                        <TableRow key={contributor.userID} {...props}>
                            <TableCell className={`${index} row`} width="30px" textAlign="center" {...props} padding="0 0 0 10px">
                                {contributor.email === data.email &&
                                    <ToolTipItem left="0"><div style={{ position: 'relative', borderRadius: '50%', height: '5px', width: '5px', background: 'var(--yellow)' }}></div><div className="tooltip" title="t">Primary Contributor</div></ToolTipItem>

                                }
                            </TableCell>
                            <TableCell className={`${index} row`} width="min-content" height="54px" padding="0 10px" {...props}>
                                <ToolTip height="100%" bottom="40px" justifyContent='start' absolute toolTipText={<GridItem gap="10px" display="grid" gridTempCols="min-content 1fr" {...props}>
                                    <div>Paypal:</div>
                                    <EmailLink href={`mailto:${contributor.songwriterPaypal}`} {...props}>{contributor.songwriterPaypal}
                                    </EmailLink>
                                    <div>Contact:</div><EmailLink href={`mailto:${contributor.email}`} {...props}>{contributor.email}
                                    </EmailLink></GridItem>}>
                                    <EmailLink href={`mailto:${contributor.songwriterPaypal}`} {...props}>{contributor.songwriterPaypal}
                                    </EmailLink>
                                </ToolTip>
                            </TableCell>

                            <TableCell textAlign="right" className={`${index} row`} width="min-content" {...props}>{contributor.split_percent || contributor.split}%</TableCell>
                            <TableCell textAlign={_status > 6 ? 'left' :"right"} className={`${index} row`} width={_status > 6 ? '100%' :"min-content"} {...props}>
                                {typeof (contributor.split) === 'number' ? numToFormattedPriceString(contributor.split) : calculateCut(contributor.split, data.price, _showTrailing)}</TableCell>
                            {
                                (_status > 6 && contributor['payoutDetails']) &&
                                <>
                                    <TableCell width="min-content"  {...paypalProps} {...props}>{contributor.payoutDetails.payoutItemID}</TableCell>
                                    <TableCell width="min-content"  {...paypalProps} {...props}>{contributor.payoutDetails.transactionID}</TableCell>
                                </>
                            }
                        </TableRow>
                    )
                })
            }
        </Table>

        </>)
}

export default ContributorTable