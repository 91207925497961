import React, { useState, useEffect, useRef } from 'react'

import Accept from '../../../images/acceptIcon.svg'
import Reject from '../../../images/rejectIcon.svg'
import Close from '../../../images/close.svg'

import {
    Wrapper,
    AcceptButtonContainer,
    RejectButtonContainer,
    PopupWrapperOverlay,
    PopupWrapper,
    Subtext,
    ButtonContainer,
    ValueHighlighted,
    CloseButtonContainer,
    Strong

} from './AcceptAndReject.styles'

import Button from '../../Button'
// import { useNotifications } from '../../../contexts/NotificationsContext'

const AcceptButton = ({ onClick, disabled }) => (
    <AcceptButtonContainer onClick={onClick} disabled={disabled}>
        <img src={Accept} alt="accept-button" />
    </AcceptButtonContainer>
)

const RejectButton = ({ onClick, disabled }) => (
    <RejectButtonContainer onClick={onClick} disabled={disabled}>
        <img src={Reject} alt="reject-button" />
    </RejectButtonContainer>
)

const ClosePopup = ({ closePopup }) => (
    <CloseButtonContainer >
        <img src={Close} alt="close-popup" onClick={closePopup} />
    </CloseButtonContainer>
)

const Content = ({ isFinalWarning, setIsFinalWarning, buttonText, closePopup, prelimWarningMessage, secondConfirmationRequired, action, type, value, handlePayload, warningMessage }) => {
    const subtext = type === 'buyer' ? ' this buyer' : type === 'artist' ? ` this songwriter` : ` this song`
    if (!secondConfirmationRequired)
        return (
            <>
                <div>
                    {warningMessage ? warningMessage : <>Please confirm that you would like to <ValueHighlighted><Strong action={action}>{action}</Strong>{subtext}</ValueHighlighted></>}
                </div>
                <Subtext><ValueHighlighted>{value}</ValueHighlighted></Subtext>
                <ButtonContainer>
                    <Button text={action} width="100%"
                        onClick={() => {
                            handlePayload()
                            closePopup()
                        }}
                        background="transparent" border="1px solid white" />
                </ButtonContainer>
            </>
        )
    else
        return (
            <>
                <div>
                    {warningMessage ?
                        !isFinalWarning && prelimWarningMessage !== undefined ?
                            prelimWarningMessage :
                            warningMessage : <>Please confirm that you would like to <ValueHighlighted><Strong action={action}>{action}</Strong>{subtext}</ValueHighlighted></>}
                </div>
                <Subtext><ValueHighlighted>{value}</ValueHighlighted></Subtext>
                <ButtonContainer>
                    <Button text={buttonText ? buttonText : action} width="100%"
                        onClick={
                            !isFinalWarning ? () => {
                                setIsFinalWarning(true)
                            } :
                                () => {
                                    handlePayload()
                                    closePopup()
                                }}
                        background="transparent" border="1px solid white" />
                </ButtonContainer>
            </>
        )
}

const Popup = ({ title, width, visible, closePopup, children }) => {

    return (
        <PopupWrapperOverlay visible={visible} onClick={closePopup}>
            <PopupWrapper visible={visible} onClick={e => e.stopPropagation()} width={width}>
                {!title && <ClosePopup closePopup={closePopup} />}
                {title !== undefined && <div style={{ display: "flex", justifyContent: 'space-between' }}><div>{title}</div><ClosePopup closePopup={closePopup} /></div>}
                {children}
            </PopupWrapper>
        </PopupWrapperOverlay>

    )
}
const AcceptAndReject = ({ modalOnly = false, disabled, values, title, isFinalWarning, setIsFinalWarning,
    gridCols, buttonText, width, secondConfirmationRequired, warningMessage, prelimWarningMessage, isApproved, preparePayload, handlePayload, type, value, popupVisible, action, closePopup }) => {
    // const { waitingForServerResponse } = useNotifications()

    return (
        <>
            {
                !modalOnly &&
                <Wrapper isApproved={isApproved} gridCols={gridCols}>
                    {!isApproved &&
                        <AcceptButton disabled={disabled} onClick={() => preparePayload("accept")} />}
                    <RejectButton disabled={disabled} onClick={
                        () => {
                            if (isApproved && type === 'song')
                                preparePayload('remove')
                            else
                                preparePayload('reject')
                        }} />
                </Wrapper>
                }
            <Popup
                title={title}
                closePopup={closePopup}
                visible={popupVisible !== undefined ? popupVisible : false} type={type} value={value}
                width={width}>
                <Content
                    setIsFinalWarning={setIsFinalWarning}
                    isFinalWarning={isFinalWarning}
                    buttonText={buttonText}
                    prelimWarningMessage={prelimWarningMessage !== undefined ? prelimWarningMessage : undefined}
                    secondConfirmationRequired={secondConfirmationRequired !== undefined ? secondConfirmationRequired : undefined}
                    warningMessage={warningMessage}
                    handlePayload={handlePayload}
                    action={action}
                    closePopup={closePopup}
                    type={type}
                />
            </Popup>
        </>
    )
}

export default AcceptAndReject
