import styled from 'styled-components'

export const IconWrapper = styled.a`
display: flex;
cursor: ${props => props.isLinked ? 'pointer' : 'default'};
flex-direction: column;
align-items: center;
justify-content: center;
opacity: ${props => props.isLinked ? '1' : '0.3'};
img{
    height: 20px;
}
`;