import styled from "styled-components";

export const TableCellWrapper = styled.td`
display: table-cell;
background: ${props => props.background ? props.background : 'inherit'};
border: ${props => props.border ? props.border : ''};
outline: ${props => props.outline ? props.outline : ''};
outline-offset: ${props => props.outlineOffset ? props.outlineOffset : ''};
color: ${props => props.color ? props.color : ''};
position: relative;
padding: ${props => props.padding ? props.padding : '20px 30px'};
white-space: ${props => props.whiteSpace ? props.whiteSpace : ''};
font-size: ${props => props.fontSize ? props.fontSize : 'inherit'};
font-weight: ${props => props.fontWeight ? props.fontWeight : 'inherit'};
text-transform: ${props => props.textTransform ? props.textTransform : props.uppercase ? 'uppercase' : ''};
letter-spacing: ${props => props.letterSpacing ? props.letterSpacing : ''};
width: ${props => props.width ? props.width : 'auto'};
height: ${props => props.height ? props.height : 'auto'};
vertical-align: ${props => props.verticalAlign ? props.verticalAlign : 'middle'};
min-width: ${props => props.minWidth ? props.minWidth : ''};
text-align: ${props => props.textAlign ? props.textAlign : 'left'};
colspan: ${props => props.colSpan ? props.colSpan : 'auto'};
user-select: ${({ userSelect }) => userSelect};
float: ${({ float }) => float};
/* padding: 0 30px; */


`;

export const TableCellHeaderWrapper = styled.th`
display: table-cell;
background: ${props => props.background ? props.background : 'inherit'};
border: ${props => props.border ? props.border : ''};
outline: ${props => props.outline ? props.outline : ''};
outline-offset: ${props => props.outlineOffset ? props.outlineOffset : ''};
color: ${props => props.color ? props.color : ''};
position: relative;
padding: ${props => props.padding ? props.padding : '20px 30px'};
white-space: ${props => props.whiteSpace ? props.whiteSpace : ''};
font-size: ${props => props.fontSize ? props.fontSize : 'inherit'};
font-weight: ${props => props.fontWeight ? props.fontWeight : 'inherit'};
width: ${props => props.width ? props.width : 'auto'};
height: ${props => props.height ? props.height : 'auto'};
vertical-align: ${props => props.verticalAlign ? props.verticalAlign : 'middle'};
min-width: ${props => props.minWidth ? props.minWidth : ''};
text-align: ${props => props.textAlign ? props.textAlign : 'left'};
colspan: ${props => props.colSpan ? props.colSpan : 'auto'};
user-select: ${({ userSelect }) => userSelect};
float: ${({ float }) => float};
`;

export const LinkWrapper = styled.a`
text-decoration: none;
font-size: 12px;
letter-spacing: 0.03rem;
color: var(--yellow);
cursor: pointer;
font-weight:700;
`;