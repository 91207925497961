import React, { useEffect, useRef, useState } from 'react'

import { Wrapper, InputStyled, LabelStyled, InnerWrapper, DollarSign, SearchIcon } from './InputField.styles'

import searchIcon from '../../images/searchIcon.svg'
import closeIcon from '../../images/close.svg'

const Label = ({ name, marginBottom, label, labelFontSize, labelTextTransform }) => (


    <LabelStyled
        htmlFor={name}
        labelFontSize={labelFontSize}
        labelTextTransform={labelTextTransform}
        marginBottom={marginBottom}
    >{label}</LabelStyled>
)




const InputField = ({ background, onKeyDown, onKeyUp, showInLine, list, listItem, activeSearch, clearAll, clearListOnSearch, onSearch, isSearch, maxWidth, width, uppercaseOff, borderRadius, height, disabled, labelFontSize, labelTextTransform, type, image, onChange, placeholder, value, label, name, marginBottom, onKeyPress, priceOnBlur, ...props }) => {
    const initial = useRef(true);
    const inputRef = useRef(null);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [initialLength, setInitialLength] = useState(0);
    const handleCursor = (event) => {

        event.preventDefault();

        setCursorPosition(inputRef.current.selectionStart)
        setInitialLength(inputRef.current.value.length)
        onChange(event);
    }
    useEffect(() => {
        if (initial.current) {
            initial.current = false;
            return; // if initial render, skip useEffect
        }
        if (name === 'price') {
            let length = inputRef.current.value.length
            let caretPos = length - initialLength + cursorPosition;
            //console.log(length, initialLength, cursorPosition);
            inputRef.current.setSelectionRange(caretPos, caretPos);



        }
    }, [value])

    if (label !== undefined) {

        return (
            <Wrapper width={width} maxWidth={maxWidth} height={height}>
                <Label htmlFor={name}
                    marginBottom={marginBottom}
                    label={label}
                    labelFontSize={labelFontSize}
                    labelTextTransform={labelTextTransform}
                ></Label>
                <InnerWrapper  height={height} background={background}>
                    {name === 'price' && <DollarSign>$</DollarSign>}
                    <InputStyled
                        height={height}
                        isSearch={isSearch}
                        uppercaseOff={uppercaseOff}
                        borderRadius={borderRadius}
                        type={type}
                        placeholder={placeholder}
                        disabled={disabled ? true : false}
                        value={value}
                        onChange={handleCursor}
                        name={name}
                        onKeyPress={onKeyPress}
                        onKeyUp={onKeyUp}
                        onKeyDown={onKeyDown}
                        ref={inputRef}
                        onBlur={priceOnBlur !== undefined ? priceOnBlur : undefined}
                        {...props}
                    />
                    {isSearch &&
                        <>
                            <SearchIcon src={closeIcon} alt="clear-search" onClick={(e) => {
                                if (typeof (onSearch) === 'function')
                                    onSearch(e)
                            }} />
                            <SearchIcon src={searchIcon} alt="search" onClick={(e) => {
                                if (typeof (onSearch) === 'function')
                                    onSearch(e)
                            }} />
                        </>
                    }
                </InnerWrapper>
            </Wrapper >

        )
    }
    else {
        return (
            <InnerWrapper width={width} maxWidth={maxWidth} height={height} background={background}>
                {name === 'price' && <DollarSign>$</DollarSign>}
                <InputStyled
                    type={type}
                    background={background}
                    isSearch={isSearch}
                    height={height}
                    uppercaseOff={uppercaseOff}
                    borderRadius={borderRadius}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled ? true : false}
                    onChange={handleCursor}
                    name={name}
                    onKeyPress={onKeyPress}
                    onKeyUp={onKeyUp}
                    onKeyDown={onKeyDown}
                    ref={inputRef}
                    onBlur={priceOnBlur !== undefined ? priceOnBlur : undefined}
                    {...props}
                />
                {
                    (isSearch && clearListOnSearch && activeSearch) && <SearchIcon src={closeIcon} opacity="1.0" alt="clear-search" onClick={(e) => {
                        if (typeof (clearAll) === 'function')
                            clearAll()
                    }} />
                }
                {(isSearch && !clearListOnSearch || (clearListOnSearch && !activeSearch)) &&
                    <>

                        <SearchIcon src={searchIcon} alt="search" onClick={(e) => {
                            if (typeof (onSearch) === 'function')
                                onSearch(e)
                        }} />
                    </>
                }
            </InnerWrapper>
        )
    }



}

export default InputField
