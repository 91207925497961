import React from 'react'

import { Wrapper } from './Flexbox.styles'

const Flexbox = ({
    isHeader,
    refID,
    innerRef,
    width,
    maxWidth,
    height,
    minHeight,
    position,
    relative,
    fixed,
    absolute,
    transition,
    top,
    right,
    bottom,
    boxShadow,
    left,
    margin,
    children,
    whiteSpace,
    gridArea,
    gridCol,
    gridRow,
    display,
    gridTempRows,
    gridTempCols,
    gridTemplate,
    gap,
    alignText,
    flexWrap,
    flexGrow,
    flexBasis,
    justifyContent,
    flexDirection,
    column,
    row,
    justifyItems,
    justifySelf,
    alignSelf,
    alignItems,
    alignContent,
    fontSize,
    letterSpacing,
    transformText,
    overflow,
    overflowX,
    color,
    background,
    border,
    borderRadius,
    zIndex,
    onClick,
    cursor,
    padding,
    fontWeight,
    borderBottom,
    className,
    ...props }) => {

    return (
        <Wrapper
            className={className}
            flexGrow={flexGrow}
            flexBasis={flexBasis}
            minHeight={minHeight}
            maxWidth={maxWidth}
            transition={transition}
            transformText={transformText}
            borderRadius={borderRadius}
            isHeader={isHeader ? isHeader : undefined}
            ref={refID ? refID : innerRef ? innerRef : undefined}
            position={relative ? 'relative' : absolute ? 'absolute' : fixed ? 'fixed' : position}
            zIndex={zIndex}
            top={top}
            right={right}
            bottom={bottom}
            background={background}
            boxShadow={boxShadow}
            cursor={cursor}
            display={display}
            onClick={typeof (onClick) === 'function' ? onClick : undefined}
            margin={margin}
            padding={padding}
            overflow={overflow}
            overflowX={overflowX}
            height={height}
            left={left}
            width={width}
            flexDirection={row ? 'row' : column ? 'column' : flexDirection}
            flexWrap={flexWrap}
            justifySelf={justifySelf}
            alignSelf={alignSelf}
            gap={gap}
            whiteSpace={whiteSpace}
            letterSpacing={letterSpacing}
            alignText={alignText}
            justifyContent={justifyContent}
            justifyItems={justifyItems}
            alignContent={alignContent}
            alignItems={alignItems}
            color={color}
            fontSize={fontSize}
            fontWeight={fontWeight}
            borderBottom={borderBottom}
            border={border}
            {...props}
        >{children}</Wrapper>
    )
}

export default Flexbox