import { createGlobalStyle } from 'styled-components';
import CanoOtf from './fonts/Cano.otf';
export const GlobalStyle = createGlobalStyle`
    :root{
        --maxWidth: 1280px;
        --background: #000;
        --backgroundActiveLi: rgba(255,255,255,0.25);
        --borderRightActiveLi: 2px solid rgb(255,255,255);
        --yellow: #F2BD00;
        --white: #fff;
        --defaultFont: 'Raleway', sans-serif;
        --greyBackground: #262626;
        --fontMicro: 10px;
        --fontSmall: 11px;
        --fontMedium: 1.1em;
        --fontBig: 1.5em;
        --buttonFontSize: 11px;
        --buttonHeight: 56px;
        --purpleGrey: #696980;
        --defaultInputHeight: 49px;
        --dropdownBackground: #1A1A1A;
        --paypalBlueLight: #0079C1;
        --paypalBlueDark: #002b45;
        --paypalYellow: #ffc43a;
        --paypalYellowHover: #ffb70f;
    }

    * {
        box-sizing: border-box;
        font-family: 'Raleway', sans-serif;
    }

    body{
        margin: 0;
        padding: 0;
        background: var(--background);
        color: var(--white);
    }
    @font-face {
        font-family: 'Cano';
        src: local('Cano'), url({CanoOtf}) format('opentype');
        font-weight: 300;
        font-style: normal;
    }
    html{
        margin: 0;
            font-size: 15px;
            font-family: 'Colombia';
    }
    .paypalHeader{
        background: var(--paypalBlueDark);
        color: var(--paypalBlueLight);
        font-weight: 500;
    }
    .paypalTableCell{
        background: var(--paypalBlueLight);
        color: var(--paypalBlueDark);
        outline: 1px solid #00457C;
    }
    @media screen and (min-width:768px) {
    html {
        font-size: 16px;
    }
    }

    @media screen and (min-width:1024px) {
    html {
        font-size: 16px;
    }
    }

    @media screen and (min-width:1300px) {
    html {
        font-size: 18px;
    }
}
`;