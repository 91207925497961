import React, {useRef} from 'react'

import { Wrapper, RowElementWrapper } from './TableEntry.styles'

const Row = ({ align, column }) => (
    <RowElementWrapper
        align={align}
    >{column}</RowElementWrapper>

)


const TableEntry = ({ rowHighlight, headerFontSize, contentFontSize, padding, gridTemplateAreas, gridTemplateColumns, gridColGap, content, rowAlignmentArray, id, onClick, header, noHover, tableHeaderTextStyling }) => {
    const refID = useRef(id)
    return (
        <Wrapper
            // onClick={ (e) => { e.preventDefault(); handleSelect('input is')}}
            onClick={onClick}
            rowHighlight={rowHighlight}
            fontSize={header !== undefined ? headerFontSize : contentFontSize}
            tableHeaderTextStyling={tableHeaderTextStyling}
            padding={padding}
            noHover={noHover !== undefined ? true : false}
            isHeader={ header !== undefined ? true : undefined }
            gridTemplateAreas={gridTemplateAreas}
            gridColGap={gridColGap}
            gridTemplateColumns={gridTemplateColumns}>
            {
                content.map((column, index) => (
                    <Row 
                    align={rowAlignmentArray !== undefined ? rowAlignmentArray.length === content.length ? rowAlignmentArray[index] : undefined : undefined}
                    column={column}
                    key={index}
                    />
                ))
            }
        </Wrapper>
    )
}

export default TableEntry
