import styled from 'styled-components';

export const FormStyled = styled.form`
height: calc(100vh - 50px);
width: 100vw; // changed this to 100vw to keep stuff from shifting due to vert scroll bar
top: 50px;
position: absolute;
flex-direction: column;
display: flex;
align-items: center;
justify-content: center;
background: #000;
`;


export const Wrapper = styled.div`
max-width: 1000px;
width: 100%;
    display: flex;
    max-width: 800px;
    align-items: center;
    justify-content: center;
`;