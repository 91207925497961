import React, { useState, useRef, useEffect } from 'react'
import Tag from '../Tag'
import Flexbox from '../Flexbox'
import InputField from '../InputField'
import Dropdown from '../Dropdown'
import SearchTermHints from '../SearchTermHints'
import SuggestSearch from '../SuggestSearch'

import API from '../../API'

import { InnerContainer, TagItem, TagsInputContainer, Input } from './EditSongTags.styles'

const EditSongTags = ({ multiTermEnabled = false, data, onChange }) => {

    //onChange={(_tags) => { setPayload(prev => ({ ...prev, tags: _tags })) }}
    const getSuggestions = async (_input, items, setItems, setInput) => {
        try {
            console.log("items", items)
            const res = await API.getTags(_input)
            if (res && res.tags && res.tags.filter(it => !items.findIndex(el => el.id === it.name).length > 0)) {

                const nonDuplicates =
                    res.tags
                        .filter(it => !items.find(el => el.id === it.name))
                        .map(it => ({
                            innerText: it.name,
                            value: it.name,
                            onClick: async () => {
                                if (!items.find(el => el.id === it.name)) {
                                    const newItems = [...items, { id: it.name, value: it.name }]
                                    setItems(newItems)
                                    await onChange(newItems)
                                }
                                setInput('')
                            }
                        }))
                return nonDuplicates

            } else return []
        } catch (error) {
            console.log("error occured", error)
        }

    }

    const selectSuggestsion = (input, setItems, setInput, setSuggestions) => {

    }

    const validatorAddItem = (trimmedInput, items) => {
        return !items.find(it => it.id === trimmedInput) && trimmedInput.length
    }

    const validatorRemoveItem = (trimmedInput, items) => {
        return !trimmedInput.length && items.length
    }

    const removeLastItem = (trimmedInput, items) => {
        return !trimmedInput.length && items.length
    }

    const formatItems = (item, setItems) => {

    }

    return (
        <>
            <h2 style={{ margin: '0' }}>Song Tags</h2>
            <SuggestSearch
                validatorAddItem={validatorAddItem}
                validatorRemoveItem={validatorRemoveItem}
                multiTermEnabled={true}
                data={data}
                onChange={onChange}
                getSuggestions={getSuggestions}
            />
        </>
    )
}

export default EditSongTags