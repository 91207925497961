import styled from 'styled-components'

export const Wrapper = styled.div`
text-transform: uppercase;
font-size: 10px;
display: grid;
grid-template-areas: ${props => props.gridTemplateAreas ? `"${props.gridTemplateAreas}"` : ''};
grid-template-columns: ${ props => props.gridTemplateCols ? props.gridTemplateCols : ''};
grid-column-gap: 10px;
grid-template-rows: min-content;
border-bottom: 2px solid #000;
padding: 10px 20px 10px;
align-content: center;
justify-content: center;
`;

export const Process = styled.div`
display: grid;
grid-area: acceptreject;
grid-column-gap: 10px;
text-align: center;
grid-template-columns: 50px 50px;
grid-row-gap: 5px;
grid-template-areas: ${ props => props.isApproved ? `'reject reject'` :
    `'header header'
    'accept reject'`};
`;

export const ProcessHeader = styled.div`
grid-area: header;
text-align: center;
`;

export const Email = styled.div`
grid-area: email;

`;

export const GridItem = styled.div`
display: flex;
justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};
grid-area: ${props => props.gridArea ? props.gridArea : ''};
text-align: ${props => props.textAlign ? props.textAlign : 'left'};

`;

export const Status = styled.div`
grid-area: status;

`;

export const Accept = styled.div`
grid-area: accept;
`;

export const Reject = styled.div`
grid-area: reject;
`;

export const SubText = styled.div`
    font-size: 10px;
    letter-spacing: 0.05rem;
`;

export const Song = styled.div`
grid-area: song;
`;

export const Tempo = styled.div`
grid-area: tempo;
`;

export const Genre = styled.div`
grid-area: genre;
`;

export const Spotify = styled.div`
grid-area: spotify;
display: flex;
justify-content: center;

`;

export const Artist = styled.div`
grid-area: artist;
`;

export const Date = styled.div`
grid-area: date;
display: flex;
justify-content: center;
`;
