import styled from 'styled-components'

export const OuterWrapper = styled.div`
position: relative;
z-index: 1;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 200px;
top: 0;
margin-bottom: 20px;
`;

export const Wrapper = styled.div`
width: 50px;
background: rgb(100,100,100);
height:50px;
position: relative;
/* mask-image: ${props => props.maskImage}; */
/* clip-path:  ${props => props.clip.current ? props.clip.current.id : ""}; */

svg{
    height: 100%;
    width: 100%;
    position: absolute;
}

#slider{
    transition: height 0.5s;
}
`;

export const Text = styled.div`
font-size: var(--fontSmall);
text-transform: uppercase;
letter-spacing: 0.05rem;
`;