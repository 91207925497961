import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import PayoutPendingTransactions from '../PayoutPendingTransactions'
import Payout from '../Payout'
import CompletedTransaction from '../CompletedTransaction'

const Transactions = ({ songs, type }) => {
    const location = useLocation()
    const { songID } = useParams()

    if (location.pathname.includes('/payout/') && songID)
        return <Payout songID={songID} />
    if (location.pathname.includes('/completed/') && songID)
        return <CompletedTransaction songID={songID} />
    if (location.pathname.includes('pending-payout'))
        return <PayoutPendingTransactions songs={songs} type={type}/>
    else return <></>
}

export default Transactions