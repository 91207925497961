import React from 'react'

import { Wrapper, Button } from './FilterTile.styles';


const FilterTile = ({ onClick, name, ...props }) => {
    return (
        <Wrapper value={name} {...props}><div>{name}</div><Button onClick={onClick}  {...props}>&#10006;</Button></Wrapper>
    )
}

export default FilterTile