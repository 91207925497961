import React, { useContext, useState, useEffect } from 'react'
import API from '../API'
import { auth } from '../firebase'
import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    EmailAuthProvider,
    reauthenticateWithCredential,
    sendPasswordResetEmail,
    updatePassword,
    signOut
} from "firebase/auth";

const AuthContext = React.createContext()

export const useAuth = () => {
    return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null)
    const [user, setUser] = useState(null)
    const signup = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password)
    }
    const login = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
    }
    const sendResetPasswordEmail = (email) => {
        return sendPasswordResetEmail(auth, email)
    }
    const changePassword = (password) => {
        return updatePassword(user, password)
    }
    const logout = (email, password) => {
        return signOut(auth)
    }
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUser(user);
                setUser(user)
                try {
                    const settoken = API.setAccessToken(user.email, user.accessToken)
                }
                catch (e) {
                    console.log(e)
                }
            }
            else {
                //console.log("user has logged out")
                setCurrentUser(null);
                setUser(null)
            }

        })
        return unsubscribe // unsubscribe from listener on unmount
    }, [])
    const value = { currentUser, signup, login, logout, sendResetPasswordEmail, changePassword }
    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

