import React, { useState, useEffect } from 'react';

import Modal from '../../Modal'
import GridItem from '../../GridItem'
import InputField from '../../InputField'


const Content = ({ modalType, spot, onChange, setTop100Action, top100Action, handleActionForTop100Spot, data }) => {
    switch (modalType) {
        case 'column-2':
            return (
                <GridItem justifyContent="end" gridColGap="20px" gridTempCols="min-content min-content min-content min-content" display="grid" gridTempRows="min-content min-content min-content">
                    <GridItem gridCol="1" alignItems="start"><p style={{ textAlign: 'right', margin: '0', fontSize: "10px", width: '90px' }}>Enter a spot for this song on the <strong style={{ whiteSpace: 'nowrap', fontSize: '12px' }}>Top 100:</strong></p></GridItem>
                    <GridItem gridRow="1" gridCol="2" gridTempCols="1fr 1fr" display="grid" width="min-content" gridColGap="10px">

                        <GridItem width="70px" position="relative"><InputField name="spot" type="input" value={spot} onChange={onChange} height="40px" />
                        </GridItem>
                    </GridItem>
                    <GridItem alignItems="start" gridRow="1" gridCol=" 3 / span 1" ><p style={{ textAlign: 'right', margin: '0', fontSize: "12px", width: '160px' }}>Shift entires down or replace the current entry at this position?</p></GridItem>
                    <GridItem
                        gridRow="1" gridCol=" 4 / span 1"
                        position="relative"
                        width="150px"
                        padding="5px"
                        borderRadius="25px"
                        height="40px"
                        display="grid"
                        gridTempCols="1fr 1fr"
                        gridTempRows="1fr"
                        alignContent="center"
                        justifyItems="center"
                        boxShadow="inset 0 0 5px rgba(0,0,0,.5)"
                        background='#3c3c3c'>
                        {Object.keys(top100Action.options).map((it, index) => {
                            console.log(it, top100Action.options[it])
                            return (
                                <GridItem
                                    zIndex="3"
                                    position="relative"
                                    color={top100Action.active === it ? '#000000' : '#ffffff'}
                                    justifyContent="center"
                                    height="100%"
                                    fontWeight="700"
                                    borderRadius={index === 0 ? "20px 0 0 20px" : "0 20px 20px 0"}
                                    fontSize="10px"
                                    onClick={() => {
                                        handleActionForTop100Spot(data.spot, top100Action.options[it].title.toLowerCase())
                                        setTop100Action(prev => ({ ...prev, active: top100Action.options[it].id })
                                        )
                                    }
                                    }>
                                    {top100Action.options[it].title.toUpperCase()}
                                </GridItem>
                            )
                        })}

                        <GridItem
                            position="absolute"
                            justifyContent="center"
                            left={top100Action.active === 'option-1' ? '5px' : 'calc(50% - 5px)'}
                            height="calc(100% - 10px)"
                            transition="all 0.25s ease"
                            width="50%"
                            borderRadius="20px"
                            background="var(--yellow)" >

                        </GridItem>
                    </GridItem>
                </GridItem >)

        default:
            return <></>
    }


}

const BarItem = ({ handleActionForTop100Spot, modalType, showModal, closeModal, data }) => {
    // const [spot, setSpot] = useState(1)
    const [top100Action, setTop100Action] = useState({
        options: {
            'option-1': { id: 'option-1', title: 'Shift' },
            'option-2': { id: 'option-2', title: 'Replace' }
        },
        active: 'option-2'
    }) // shift is 1, replace is 2
    const [modalDetails, setModalDetails] = useState({
        title: '',
        subtext: '',
        onClick: () => { }
    })
    console.log("modalType", modalType)

    const returnInRange = (val) => {
        if (val === '')
            return ''
        const value = parseInt(val)
        if (value > 100)
            return 100
        else if (value < 1)
            return 1
        else return value
    }

    const onChange = (e) => {
        const value = returnInRange(e.target.value.replace(/\D/g, ''))
        // setSpot(value)
        handleActionForTop100Spot(value)
    }
    console.log("data::::::::::", data)
    const getModal = () => {
        switch (modalType) {
            case 'column-2':
                setModalDetails({ title: 'Top 100', subtext: 'Replace or shift rows down', onClick: () => { } })
                break;
            case 'column-1':
                setModalDetails({ title: 'Place A Hold', subtext: '', onClick: () => { } })
                break;
            default:
                break;
        }
    }
    useEffect(() => {
        if (modalType)
            getModal()
    }, [modalType])

    return (
        <Content
            top100Action={top100Action}
            setTop100Action={setTop100Action}
            modalType={modalType} spot={data['spot']}
            onChange={onChange} data={data}
            handleActionForTop100Spot={handleActionForTop100Spot} />
    )
}

export default BarItem