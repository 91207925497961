import React, { useRef } from 'react'

import { Wrapper, Button } from './MessageInputBar.styles'

import InputField from '../InputField'

import sendMessageIcon from '../../images/sendMessageIcon.svg'

const SendButton = ({ onClick }) => (
    <Button onClick={onClick}>
        <img src={sendMessageIcon} alt="send" />
    </Button>
)

const MessageInputBar = ({ onChange, messageInput, setMessageInput, onClick, sendMessage, onKeyPress }) => {

    return (
        <Wrapper>
            <InputField
                type="text"
                onKeyPress={onKeyPress}
                onChange={onChange}
                name='message'
                placeholder='Enter your message'
                marginBottom={'20px'}
                value={messageInput}
                padding="0 60px 0 20px"
            />
            <SendButton
                onClick={() => { sendMessage().then(() => { setMessageInput((prev) => ({ ...prev, message: "" })) }) }}
            ></SendButton>
        </Wrapper>
    )
}


export default MessageInputBar
