import React, { useState, useEffect, useRef } from 'react'
import API from '../../../API'
import TableCell from '../../TableCell'
import TableRow from '../../TableRow'
import Table from '../../Table2'
import Flexbox from '../../Flexbox'
import ToolTip from '../../ToolTip'
import SearchBar from '../../SearchBar'
import Tag from '../../Tag'

import dayjs from 'dayjs'

import AlertIconRed from '../../../images/alertIconRed.svg'

import { shortFormatPrice, showTrailing, calculatePriceWithFee, calculateCut } from '../../../helpers'
import { updateRelTime, throttle } from '../../../utils'
import { PAYLOAD_TYPES } from '../../../config'

import AcceptAndReject from '../AcceptAndReject'
import SongDetailsModal from '../SongDetailsModal'
import GridItem from '../../GridItem'
import DotMenu from '../../DotMenu'
import DetailsSideBar from '../../DetailsSideBar'

import PageNav from '../../AdminPanel/PageNav'

import clock from '../../../images/clockIcon.svg'
import favorites from '../../../images/favoriteIcon.svg'

import { useSongwriterHook } from '../../../hooks/useSongwriterHook'
import { useTransactionHook } from '../../../hooks/useTransactionHook'

import { useAuth } from '../../../contexts/AuthContext'

import Play from '../../../images/play.svg'
import Pause from '../../../images/pause.svg'
import SpotifyIcon from '../../../images/spotify.svg'
import InstagramIcon from '../../../images/instagramIcon.svg'

import { ColWithSubText, Strong, AlertIcon, Spotify, Instagram } from './Songs.styles'
import { async } from '@firebase/util'

const SpotifyLink = ({ spotify }) => {
    const spotifyLink = spotify === null ? spotify : spotify.includes('https://') ? spotify : 'https://' + spotify
    return (
        <Spotify
            href={spotifyLink != '' ? spotifyLink : undefined}
            target="_blank"
            spotify={spotify}>
            <img src={SpotifyIcon} alt="spotifyIcon" />
        </Spotify>
    )
}

const InstagramLink = ({ instagram }) => {
    const instagramLink = instagram === null ? '' : `https://www.instagram.com/${instagram.split('@').length > 1 ? instagram.split('@')[1] : instagram.split('@')[0]}`;
    return (
        <Instagram
            href={instagramLink != '' ? instagramLink : undefined}
            target="_blank"
            instagram={instagram}>
            <img src={InstagramIcon} alt="spotifyIcon" />
        </Instagram>
    )
}

const PlayButton = ({ onClick, playing, isCurrent }) => {
    if (playing && isCurrent) {
        return (
            <Flexbox relative height="20px" width="20px" onClick={onClick} justifyContent="center" alignItems="center">
                <img src={Pause} alt="play-button" style={{ objectFit: 'contain', height: '10px' }} />
            </Flexbox >
        )
    }
    else {
        return (
            <Flexbox relative height="20px" width="20px" onClick={onClick} justifyContent="center" alignItems="center">
                <img src={Play} alt="play-button" style={{ objectFit: 'contain', height: '10px' }} />
            </Flexbox >
        )
    }

}

const Song = ({ sideBarDetails, pullSongs, currentUser, selected, setSelected, setSideBar, handlePayload, setPayload, song, itemsSubmitting, content, songStatusKey, handleSongChange, playing, nowPlaying }) => {

    const dotMenuRef = useRef(null)
    const [hover, setHover] = useState(false)
    const [action, setAction] = useState('')
    const [isDisabled, setIsDisabled] = useState(false)
    const [popupVisible, setPopupVisibility] = useState(false)
    const [isFinalWarning, setIsFinalWarning] = useState(false) // for removing songs
    const [showRemoveApprSongModal, setShowRemoveApprSongModal] = useState(false)
    const tempo = song.bpm ? song.bpm.split(' ')[0] : ''
    const song_state = songStatusKey.filter(st => st.id === song.song_status)[0].status;
    const functionalStatus = song.song_status >= 3 || (parseInt(song.song_status) === 2 && song.contributors !== undefined)
    const genres = JSON.parse(song.genre)
    const currentTop100Index = song.top_100_history.length === 0 ? '-' :
        song.top_100_history.find(it => it.removed_at === null) ? song.top_100_history.findIndex(it => it.removed_at === null) : '-'
    const currentTop100Spot = currentTop100Index >= 0 ? song.top_100_history[currentTop100Index].spot : '-'
    const prevTop100Index = song.top_100_history.length === 0 ? '-' :
        song.top_100_history.find(it => it.removed_at !== null) ? song.top_100_history.findIndex(it => it.removed_at !== null) : '-'

    const getDate = () => {
        return dayjs.unix(parseInt(song.song_status) === 1 ? song.created_at : song.updated_at).fromNow()
    }
    const date = getDate();


    const closePopup = () => {
        setIsFinalWarning(false)
        setShowRemoveApprSongModal(false);
        setPopupVisibility(false);
        setAction('')
    }

    useEffect(() => {
        if (itemsSubmitting.length > 0)
            itemsSubmitting.forEach(item => {
                if (item.contentType === content.type &&
                    item.contentValue === content.value &&
                    item.payload.userID === song.user_id &&
                    item.payload.songID === song.song_id)
                    if (item.waitingForServerResponse)
                        setIsDisabled(true)
                    else
                        setIsDisabled(false)

            })
        else {

            setIsDisabled(false)
        }

    }, [itemsSubmitting])

    const getDotMenu = (song) => {
        const status = parseInt(song.song_status)
        const noPricing = song.price === null
        const withoutPricing = [{
            value: 3, innerText: 'Songwriter Details', onClick: () => {
                setSideBar('songwriter-details', song.id)
            }
        },
        {
            value: 5, innerText: 'Message Songwriter', onClick: () => {
                setSideBar('message-songwriter', song.id)
            }
        },
        {
            value: 7, innerText: 'Delete Song',
            onClick: () => {

                setShowRemoveApprSongModal(true)
                setAction('remove')
                const _payload = {
                    userID: song.user_id,
                    songID: song.song_id,
                    status: '0',
                    email: song.email,
                    name: song.name,
                    songTitle: song.song_title
                }
                setPayload(_payload)

            }
        }
        ,{
            value: 8, innerText: 'Edit Genre/Tempo & Tags', onClick: () => {
                // 
                const _payload = {
                    userID: song.user_id,
                    songID: song.song_id,
                    genres: JSON.parse(song.genre).map(it => it.toUpperCase()),
                    tags: song.tags,
                    tempo: song.bpm,
                    initialGenres: JSON.parse(song.genre).map(it => it.toUpperCase()),
                    initialTags: song.tags,
                    initialTempo: song.bpm,
                }
                setPayload(_payload)
                setSideBar('edit-genre-tempo', song.id)
            }
        },
        ]
        if (noPricing)
            return withoutPricing
        const baseOptions = [
            {
                value: 2, innerText: 'Song Details', onClick: () => {
                    setSideBar('song-details', song.id)
                }
            }, ...withoutPricing
        ]
        const baseAndBuyer = [...baseOptions, {
            value: 4, innerText: 'Buyer Details', onClick: () => {
                setSideBar('buyer-details', song.id)
            }
        },

        {
            value: 6, innerText: 'Message Buyer', onClick: () => {
                setSideBar('message-buyer', song.id)
            }
        }]
        if (status < 5)
            return baseOptions;
        else if (status === 6)
            return [{
                value: 1, innerText: 'Payout', onClick: () => {
                    // setSideBar(column.id, song.id)
                }
            }, ...baseAndBuyer]
        else return baseAndBuyer

    }
    const isActive = selected.includes(song.id) && sideBarDetails.showSideBar
    const textColor = isActive ? '#000' : '#fff'

    return (
        <>
            <TableRow
                color={textColor}
                fontWeight={isActive ? '600' : undefined}
                background={isActive ? 'var(--yellow)' : undefined}
                onMouseEnter={() => { setHover(true) }}
                onMouseLeave={() => { setHover(false) }}>
                <TableCell className={`${song.id} row`} props={{ width: '20px' }} padding="20px 10px">
                    <PlayButton onClick={() =>
                        handleSongChange(`${song.file_path}`, `${song.song_id}`)}
                        playing={playing} isCurrent={nowPlaying === song.song_id ? true : false} /></TableCell>
                <TableCell padding="20px 10px" className={`${song.id} row`} >
                    <ColWithSubText>
                        <span>{song.song_title}</span>
                        <span>{song.name}</span>
                    </ColWithSubText>
                </TableCell>
                <TableCell padding="20px 10px" className={`${song.id} row`} >
                    <Flexbox row gap="5px">
                        {song.tags.map(it =>
                            <Tag
                                color={textColor}
                                key={it}
                                onClick={
                                    async () => {
                                        await API.removeTags(currentUser, song.id, it)
                                        await pullSongs()
                                    }} name={it} />)}
                    </Flexbox>
                </TableCell>
                <TableCell
                    padding="20px 10px"
                    fontSize="12px"
                    uppercase
                    letterSpacing="0.03rem"
                    color={functionalStatus ? isActive ? '#000' : 'var(--yellow)' : undefined}
                    cursor={functionalStatus ? 'pointer' : undefined}
                    fontWeight={functionalStatus ? '700' : undefined}
                    onClick={song.song_status >= 3 || (parseInt(song.song_status) === 2 && song.contributors !== undefined) ? () => setPopupVisibility(true) : undefined}
                    className={`${song.id} row`} width="max-content" textAlign="right" ><span>{song_state}</span></TableCell>
                <TableCell padding="20px 10px" className={`${song.id} row`} textAlign="right">{calculatePriceWithFee(song.price).total}</TableCell>
                <TableCell padding="20px 10px" className={`${song.id} row`} textAlign="right">
                    <ColWithSubText float="right">
                        <span>{tempo}</span>
                        <span>{genres.map((genre, idx) => (idx > 0 ? ", " + genre : genre))}</span>
                    </ColWithSubText>
                </TableCell>
                <TableCell padding="20px 10px" className={`${song.id} row`} textAlign="center" width="fit-content">{song.favorites > 0 ? song.favorites : '-'}</TableCell>
                <TableCell padding="20px 10px" width="min-content" textAlign="center" className={`${song.id} row`} >{currentTop100Spot}</TableCell>
                <TableCell padding="20px 10px" width="20px" textAlign="center">
                    {date}
                </TableCell>

                {/* <TableCell padding="20px 10px" className={`${song.id} row`} width="5rem">{tempo}</TableCell> */}
                {/* <TableCell padding="20px 10px" className={`${song.id} row`} width="5rem">{genres.map((genre, idx) => (idx > 0 ? ", " + genre : genre))}</TableCell> */}
                {/* <TableCell padding="20px 10px" width="20px" textAlign="center">
                    <SpotifyLink spotify={song.spotify} />
                </TableCell> */}
                {/* <TableCell padding="20px 10px" className={`${song.id} row`} width="max-content">{song.name}</TableCell> */}
                {/* <TableCell padding="20px 10px">
                    <AcceptAndReject
                        gridCols="1fr"
                        width="500px"
                        disabled={isDisabled}
                        data={song}
                        secondConfirmationRequired
                        handlePayload={async () => await handlePayload(PAYLOAD_TYPES.APPROVED_SONG)} //NEW_SONG, APPROVED_SONG, NEW_SONGWRITER
                        popupVisible={showRemoveApprSongModal}
                        action={action}
                        buttonText="Permanently Delete Song"
                        preparePayload={(input) => {

                            if (input === 'remove')
                                setShowRemoveApprSongModal(true)
                            else
                                setPopupVisibility(true)
                            setAction(input)
                            const artist = {
                                userID: song.user_id,
                                songID: song.song_id,
                                status: input === "accept" ? '1' : '0',
                                email: song.email,
                                name: song.name,
                                songTitle: song.song_title
                            }
                            //console.log("artist", artist)
                            setPayload(artist)

                        }}
                        closePopup={closePopup}
                        isFinalWarning={isFinalWarning}
                        setIsFinalWarning={setIsFinalWarning}
                        title={<><div style={{ display: 'flex', alignItems: 'start' }}><AlertIcon src={AlertIconRed} alt="warning" />
                            <h3 style={{ color: "#9e0000", fontSize: '20px', height: '20px', margin: '0' }}>WARNING:</h3></div></>}
                        isApproved={true}
                        prelimWarningMessage={
                            <div style={{ textAlign: "left" }}><p style={{ display: 'inline', fontSize: '16px', textAlign: "left" }}>
                                <span>You are about to </span>
                                <Strong action={action}>{action}</Strong>
                                <span> the approved song, '{song.song_title}' by {song.name}.
                                    <br /><br />This will remove ALL messages and data associated with this song.
                                    This is process is <span style={{ color: "#9e0000", fontWeight: '700' }}>irreversible</span>.
                                    <br /><br />
                                    Would you like to continue?</span></p></div>}
                        warningMessage={<p style={{ display: 'inline', fontSize: '16px' }}>
                            <span>Please confirm that you would like to </span>
                            <Strong action={action} >permanently delete</Strong> the song '{song.song_title}' by {song.name}.</p>}
                        type={"song"}
                        value={song.song_title} />
                </TableCell> */}
                <TableCell padding="0" width="30px" props={{ width: '30px', height: '100%' }}>
                    {hover && <GridItem position="relative" width='30px'>
                        <DotMenu
                            conditionMet={() => true}
                            _options={getDotMenu(song)}
                            right="0"
                            hoverActive={hover}
                            bottom="25px"
                            _ref={dotMenuRef}
                            selected={selected}
                            fill={textColor}
                            // fill={payload.songID === song.song_id ? "#000000" : "#ffffff"}
                            status="1" />
                    </GridItem>}
                </TableCell>
            </TableRow>

            {(song.song_status >= 3 || (parseInt(song.song_status) === 2 && song.contributors !== undefined)) && <SongDetailsModal selectedSong={song} closePopup={closePopup} popupVisible={popupVisible} />}
            {
                showRemoveApprSongModal &&
                <AcceptAndReject
                    gridCols="1fr"
                    modalOnly={true}
                    width="500px"
                    disabled={isDisabled}
                    data={song}
                    secondConfirmationRequired
                    handlePayload={async () => await handlePayload(PAYLOAD_TYPES.APPROVED_SONG)} //NEW_SONG, APPROVED_SONG, NEW_SONGWRITER
                    popupVisible={showRemoveApprSongModal}
                    action={action}
                    buttonText="Permanently Delete Song"
                    preparePayload={(input) => {

                        if (input === 'remove')
                            setShowRemoveApprSongModal(true)
                        else
                            setPopupVisibility(true)
                        setAction(input)
                        const artist = {
                            userID: song.user_id,
                            songID: song.song_id,
                            status: input === "accept" ? '1' : '0',
                            email: song.email,
                            name: song.name,
                            songTitle: song.song_title
                        }
                        //console.log("artist", artist)
                        setPayload(artist)

                    }}
                    closePopup={closePopup}
                    isFinalWarning={isFinalWarning}
                    setIsFinalWarning={setIsFinalWarning}
                    title={<><div style={{ display: 'flex', alignItems: 'start' }}><AlertIcon src={AlertIconRed} alt="warning" />
                        <h3 style={{ color: "#9e0000", fontSize: '20px', height: '20px', margin: '0' }}>WARNING:</h3></div></>}
                    isApproved={true}
                    prelimWarningMessage={
                        <div style={{ textAlign: "left" }}><p style={{ display: 'inline', fontSize: '16px', textAlign: "left" }}>
                            <span>You are about to </span>
                            <Strong action={action}>{action}</Strong>
                            <span> the approved song, '{song.song_title}' by {song.name}.
                                <br /><br />This will remove ALL messages and data associated with this song.
                                This is process is <span style={{ color: "#9e0000", fontWeight: '700' }}>irreversible</span>.
                                <br /><br />
                                Would you like to continue?</span></p></div>}
                    warningMessage={<p style={{ display: 'inline', fontSize: '16px' }}>
                        <span>Please confirm that you would like to </span>
                        <Strong action={action} >permanently delete</Strong> the song '{song.song_title}' by {song.name}.</p>}
                    type={"song"}
                    value={song.song_title} />
            }
        </>
    )
}

// const Songs = ({ itemsSubmitting, payload, setPayload, handlePayload, songs, handleSongChange, playing, nowPlaying, songStatusKey }) => {
const Songs = ({ songs, ...props }) => {
    updateRelTime()
    const { currentUser } = useAuth()
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    useEffect(() => {
        let isMounted = true;
        const handleResize = throttle(() => {
            if (isMounted) {
                setWindowSize(window.innerWidth)
            }
        }, 1000)
        window.addEventListener("resize", handleResize);
        return () => {
            isMounted = false
            window.removeEventListener("resize", handleResize)
        };
    }, [])

    const payload = useRef({
        songId: '',
        category: null
    })
    const [selected, setSelected] = useState([])
    const initialSideBarDetails = {
        showSideBar: false,
        sideBarType: 1,
        fullSideBar: false,
        data: {},
        title: '',
        songTitle: '',
        buttonText: ''

    }
    const initRender = useRef(true)
    const [sideBarDetails, setSideBarDetails] = useState(initialSideBarDetails)
    useEffect(() => {
        if (initRender.current) {
            initRender.current = false;
            return
        }
        payload.current = { songId: '', category: null }
        setSideBarDetails(initialSideBarDetails)
        setSelected([])

    }, [songs])
    const secondColumnSize = useRef('2fr min-content')
    const closeSideBar = () => { secondColumnSize.current = '2fr min-content'; setSideBarDetails(prev => ({ ...prev, showSideBar: false })); }
    const [sideBarType, setSideBarType] = useState(false)
    const headerPadding = "20px 10px"
    const headerProps = { verticalAlign: 'top' }
    const songDetails = {
        options: {
            'option-1': {
                id: 'songwriter-details',
                title: 'Songwriter Details',
                onClick: async (songId) => { return await API.fetchUserBySongId(currentUser, songId) },
            },
            'option-2': {
                id: 'song-details',
                title: 'Song Details',
                onClick: async (songId) => { return await API.fetchSong(currentUser, songId) },

            },
            'option-3': {
                id: 'buyer-details',
                title: 'Buyer Details',
                onClick: async (songId) => { return await API.fetchBuyerBySongId(currentUser, songId) },
            },
            'option-4': {
                id: 'message-songwriter',
                title: 'Message Songwriter',
                onClick: async (songId) => {
                    const res = await API.fetchSong(currentUser, songId)

                    return { ...res, receiver_id: res.primary_songwriter_id, buttonText: 'Message Songwriter' }
                },
            },
            'option-5': {
                id: 'message-buyer',
                title: 'Message Buyer',
                onClick: async (songId) => {
                    const res = await API.fetchSong(currentUser, songId)
                    return { ...res, receiver_id: res.buyer_user_id, buttonText: 'Message Buyer' }
                },
            },
            'option-6': {
                id: 'edit-genre-tempo',
                title: 'Edit Genre/Tempo & Tags',
                onClick: async (songId) => { return await API.fetchSong(currentUser, songId) },
            }
        },

    }


    const mapSongDetails = (_sideBarId, songId) => {
        const typeId = Object.keys(songDetails.options).find(id => {
            const _option = songDetails.options[id]
            return _option.id === _sideBarId
        })

        return songDetails.options[typeId]
    }

    const setSideBar = async (_sideBarId, songId) => {
        payload.current = { ...payload.current, songId: songId }
        setSelected([songId])

        const _detailsType = mapSongDetails(_sideBarId, songId)
        const res = await _detailsType.onClick(songId)

        const _selectedSongDetails = songs.find(it => parseInt(it.song_id) === parseInt(songId)).song_title
        if (res) {
            secondColumnSize.current = '2fr minmax(500px, 1fr)'
            setSideBarDetails(prev => ({ ...prev, sideBarType: _sideBarId, showSideBar: true, fullSideBar: true, title: _detailsType.title, data: { ...res }, songTitle: _selectedSongDetails }))

        }

    }


    const { loading, songStatusKey, setPayload } = props

    const handleTempoGenreChange = (_type, val) => {

        setPayload(prev => ({
            ...prev,
            [`${_type}`]: val
        }))
    }


    return (
        <Flexbox column height="100vh" overflow="hidden" background="#121212">
            <GridItem
                overflow="hidden"
                alignItems="start"
                position="relative"
                gridTempCols="2fr min-content"
                gridTempRows="1fr min-content"
                height="100vh"
                justifyContent="start"
                width="100%"
                flexDirection="row"
                flexGrow="1"
                // display="grid" 
                style={{ flexGrow: '1' }}>
                <Flexbox column height="100vh" overflow="hidden" >
                    <PageNav
                        altLayout={true}
                        {...props}
                        padding={props.content.type === 'buyers' ? '50px 50px 0' : undefined}>

                    </PageNav>
                    <GridItem overflow="auto" column width="100%" height="100%" flexShrink="3" justifyContent="start" alignItems="start" padding="0 30px 50px " >

                        <Table background="#1c1c1c">
                            <TableRow fontSize="11px" className="tableHeader row" childrenProps={{ verticalAlign: 'top' }}>
                                <TableCell isHeader className="tableHeader" padding={headerPadding}></TableCell>
                                <TableCell isHeader className="tableHeader" padding={headerPadding} verticalAlign="top">
                                    <ColWithSubText>
                                        <span>Song</span>
                                        <span>Songwriter</span>
                                    </ColWithSubText>
                                </TableCell>

                                <TableCell isHeader className="tableHeader" padding={headerPadding} width="30%" verticalAlign="top">Tags</TableCell>
                                <TableCell isHeader className="tableHeader" padding={headerPadding} textAlign="right" verticalAlign="top">Status</TableCell>
                                <TableCell isHeader className="tableHeader" padding={headerPadding} textAlign="right" >
                                    <ColWithSubText float="right">
                                        <span>Price</span>
                                        <span>[Inc. Fee]</span>
                                    </ColWithSubText>
                                </TableCell>
                                <TableCell isHeader className="tableHeader" padding={headerPadding} textAlign="right" >
                                    <ColWithSubText float="right">
                                        <span>Tempo</span>
                                        <span>Genre</span>
                                    </ColWithSubText>
                                </TableCell>
                                <TableCell isHeader className="tableHeader" padding={headerPadding}><ToolTip absolute width="250px" right="0" bottom="-10px" toolTipText="No. of buyers who favorited this song"><img src={favorites} alt="numberFavs" style={{ width: "12px", height: '12px' }} /></ToolTip></TableCell>
                                <TableCell isHeader className="tableHeader" padding={headerPadding} whiteSpace="nowrap" textAlign="center">Top 100<br />Spot</TableCell>
                                <TableCell isHeader className="tableHeader" padding={headerPadding}><img src={clock} alt="clockIcon" style={{ width: "15px", height: '15px' }} /></TableCell>
                                {/* <TableCell isHeader className="tableHeader" padding={headerPadding} width="min-content">Tempo</TableCell>
        <TableCell isHeader className="tableHeader" padding={headerPadding}>Genre</TableCell> */}

                                {/* <TableCell isHeader className="tableHeader" padding={headerPadding}><img src={SpotifyIcon} style={{ width: "15px", height: '15px' }} alt="spotifyIcon" /></TableCell> */}
                                {/* <TableCell isHeader className="tableHeader" padding={headerPadding}>Songwriter</TableCell> */}
                                {/* <TableCell isHeader className="tableHeader" padding={headerPadding} width="4.8rem" textAlign="center">Delete Song</TableCell> */}
                                <TableCell props={{ padding: '0', width: '50px' }} width="30px" minWidth="30px"></TableCell>

                            </TableRow>
                            {songStatusKey &&
                                songs.map(song => {
                                    return (<Song song={song}  {...props} selected={selected} setSelected={setSelected} setSideBar={setSideBar} currentUser={currentUser} sideBarDetails={sideBarDetails} />)
                                })
                            }

                        </Table>

                    </GridItem>
                </Flexbox>
                <DetailsSideBar
                    handleTempoGenreChange={handleTempoGenreChange}
                    left={windowSize < 1250 && !sideBarDetails.showSideBar ? 'calc(100vw)' : '0'}
                    flexGrow="5"
                    width="600px"
                    gap="10px"
                    position={windowSize < 1250 ? 'absolute' : undefined}
                    {...props}
                    // margin={windowSize < 1500 ? '0 calc(-100vw) 0 0' : '0 calc(-100vw) 0 0'}
                    zIndex="5"
                    modalDetails={sideBarDetails}
                    modalType={sideBarDetails.sideBarType}
                    showModal={sideBarDetails.showSideBar}
                    closeModal={closeSideBar}

                />
            </GridItem>
        </Flexbox>
    )
}

export default Songs