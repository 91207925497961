import React, { useEffect, useState, useRef } from 'react'

import { Wrapper, OuterWrapper, Row } from './SongwritersTab.styles'

import Songwriter from '../Songwriter'
import Top100 from '../../Top100'
import Top100New from '../../Top100New'
import SongwriterTabHeader from '../SongwriterTabHeader'
import SongDetailsModal from '../SongDetailsModal'

const SongwritersTab = ({
    payload,
    setPayload,
    type,
    isApproved,
    handlePayload,
    songs,
    loading,
    playing,
    nowPlaying,
    handleSongChange,
    songStatusKey,
    setItemsSubmitting,
    itemsSubmitting,
    songwriterProps,
    content
}) => {
    console.log("songs", songs)
    if (loading) {
        return <OuterWrapper></OuterWrapper>
    }
    else if (((isApproved && type !== "songs") || songs !== null && songwriterProps !== undefined) && content.value !== 'Top 100') {
        return (
            <OuterWrapper>
                <Wrapper minWidth={type === 'buyers' ? '880px' : undefined}>
                    <SongwriterTabHeader isApproved={isApproved} type={type} songStatusKey={songStatusKey} />
                    {songs.map((item) => (
                        <Songwriter
                            songStatusKey={songStatusKey}
                            handlePayload={handlePayload}
                            setPayload={setPayload}
                            key={isApproved && type !== "songs" ? item.id : item.song_id}
                            data={item}
                            playing={playing}
                            handleSongChange={handleSongChange}
                            nowPlaying={nowPlaying}
                            isApproved={isApproved}
                            type={type}
                            itemsSubmitting={itemsSubmitting}
                            setItemsSubmitting={setItemsSubmitting}
                            songwriterProps={songwriterProps}
                            content={content}
                        />
                    ))}
                </Wrapper>
            </OuterWrapper>
        )
    }
    else if (songs !== null && content.value === 'Top 100')
        return <Top100New songs={songs} />
    else {
        return <OuterWrapper>No new content.</OuterWrapper>
    }

}

export default SongwritersTab
