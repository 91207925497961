import React, { useState, useEffect } from 'react'

import { RowItem } from '../AdminPanel/SongDetailsModal'
import Table from '../Table2'
import TableRow from '../TableRow'
import TableCell from '../TableCell'

import { calculatePriceWithFee } from '../../helpers'
import dayjs from 'dayjs'




const TransactionDetailsBuyerPaypal = ({ selectedSong, horizontal, vertical, customHeader, customHeaderHTML }) => {
    const [data, setData] = useState(null)
    useEffect(() => {
        const headerProps = [
            {
                fontSize: '1.1rem', background: 'var(--paypalBlueDark)', color: '#0079C1', padding: '20px 10px', minWidth: '', height: '', textAlign: 'center', width: 'min-content',
                minorProps: { background: 'var(--paypalBlueDark)', color: '#0079C1', textAlign: 'center', width: '127px', padding: '0 10px 5px' }
            },
            {
                background: 'var(--paypalBlueDark)', color: '#0079C1', padding: '20px 10px 0', minWidth: '', height: '', width: 'min-content', whiteSpace: 'nowrap', textAlign: 'center',
                minorProps: { background: 'var(--paypalBlueDark)', color: '#0079C1', textAlign: 'center', width: '127px', padding: '0 10px 5px' }
            },
            {
                background: 'var(--paypalBlueDark)', color: '#0079C1', colSpan: '3', textAlign: 'center',
                minorProps: { background: 'var(--paypalBlueDark)', color: '#0079C1', textAlign: 'center', width: '127px', padding: '0 10px 5px' }
            },
            {
                width: 'auto', background: 'var(--paypalBlueDark)', color: '#0079C1', padding: '20px 10px 0', colSpan: '3', textAlign: 'center',
                minorProps: { background: 'var(--paypalBlueDark)', color: '#0079C1', textAlign: 'center', padding: '5px 10px 5px', whiteSpace: 'nowrap', }
            },
            // {colSpan: '3', textAlign: 'center', minorProps: { color: '#0079C1', textAlign: 'center', width: '127px', padding: '0 10px 5px' }},
            {
                width: 'auto', background: 'var(--paypalBlueDark)', color: '#0079C1', padding: '20px 10px 0', colSpan: '3', textAlign: 'center',
                minorProps: { background: 'var(--paypalBlueDark)', color: '#0079C1', textAlign: 'center', padding: '5px 10px 5px', whiteSpace: 'nowrap', }
            },
        ]
        const d = (({ song_title, songwriter_email, primarySongwriterName, buyerOrderID, buyerTransactionID, buyerInvoiceID, buyerPaypalPayerID, buyerPaypalPayerEmail, buyer_email, stemTransfer, price, saleDetails, payoutComplete, payoutDate }) =>
        ({
            'Price [Inc. Fee]': {
                major: 'Paypal Transaction Info', title: calculatePriceWithFee(price).total,
                props: {
                    padding: '20px 10px',
                    background: "#0079C1",
                    outlineOffset: '0',
                    // border: '1px solid var(--paypalBlueDark)',
                    color: 'var(--paypalBlueDark)',
                    textAlign: 'center',
                    fontWeight: '700',
                }
            },
            'Date Purchased': {
                major: 'Paypal Transaction Info', title: dayjs.unix(saleDetails.dateSold).format('M/D/YY'), props: {
                    padding: '20px 10px',
                    background: "#0079C1",
                    outlineOffset: '0',
                    // border: '1px solid var(--paypalBlueDark)',
                    color: 'var(--paypalBlueDark)',
                    textAlign: 'center',
                    fontWeight: '700',
                }
            },
            'Order Id': {
                major: 'Paypal Transaction Info', title: buyerOrderID,
                props: {
                    width: 'auto', padding: '20px 10px', fontWeight: '700', background: "#0079C1", outlineOffset: '0',
                    // border: '1px solid var(--paypalBlueDark)', 
                    color: 'var(--paypalBlueDark)', textAlign: 'center',
                },
            },
            'Transaction ID': {
                major: 'Paypal Transaction Info', title: buyerTransactionID, props: {
                    padding: '20px 10px',
                    background: "#0079C1",
                    outlineOffset: '0',
                    // border: '1px solid var(--paypalBlueDark)',
                    color: 'var(--paypalBlueDark)',
                    textAlign: 'center',
                    fontWeight: '700',
                },
            },
            'Invoice Id': {
                major: 'Paypal Transaction Info', title: buyerInvoiceID,
                props: {
                    width: 'auto',
                    padding: '20px 10px',
                    fontWeight: '700',
                    background: "#0079C1",
                    outlineOffset: '0',
                    //  border: '1px solid var(--paypalBlueDark)', 
                    color: 'var(--paypalBlueDark)', textAlign: 'center',
                },
            },
        }))(selectedSong)
        const withHeader = Object.keys(d).map((it, index) => ([it, { ...d[it], headerProps: headerProps[index] }]))
        const _formattedData = { data: Object.fromEntries(withHeader), id: selectedSong.song_id }


        setData({ ..._formattedData })
    }, [selectedSong])

    if (data === null || data.data === undefined)
        return <></>
    else if (vertical)
        return (
            <>
                {customHeaderHTML}
                {!customHeaderHTML && <h2 style={{ margin: '20px 0 0' }}>{customHeader ? customHeader : 'Transaction Details - Buyer'}</h2>}
                <Table background="#1c1c1c" oddHighlight borderCollapse="collapse" borderSpacing="1px">
                    {
                        Object.keys(data.data).map((el, index) => {
                            console.log("element", el, data.data[el])
                            const props = data.data[el].headerProps
                            const _data = data.data[el]
                            const title = Object.keys(_data).includes('major') ? el : undefined
                            return (
                                <TableRow>
                                    <TableCell key={index} isHeader className="tableHeader" id={index} content={{
                                        title: title,
                                        props: { ...props.minorProps, width: props.minorProps.width ? props.minorProps.width : 'auto', textAlign: 'left', fontWeight: '700' },
                                    }}></TableCell>
                                    <TableCell {..._data.props} userSelect='all' textAlign="left" fontWeight="500" outline="1px solid var(--paypalBlueDark)">{_data.title}</TableCell>
                                </TableRow>
                            )
                        })
                    }
                </Table>
            </>

        )
    else return (
        <>
            {customHeaderHTML}
                {!customHeaderHTML && <h2 style={{ margin: '20px 0 0' }}>{customHeader ? customHeader : 'Transaction Details - Buyer'}</h2>}
            <Table background="#1c1c1c" oddHighlight borderCollapse="collapse" borderSpacing="1px">

                <TableRow fontSize="11px" className="tableHeader row" >
                    {
                        Object.keys(data.data).reduce((res, el, index) => {
                            const props = data.data[el].headerProps
                            const _data = data.data[el]
                            const title = Object.keys(_data).includes('major') ? _data.major : el
                            if (!res.find(it => it.title === title)) {
                                const trueSpan = Object.keys(data.data).reduce((a, b) => {
                                    const _data = data.data[b]

                                    if (!Object.keys(_data).includes('major'))
                                        return a
                                    if (_data.major !== title)
                                        return a
                                    a = a + 1;
                                    return a
                                }, 0)
                                res.push({
                                    title: title, el:
                                        <TableCell key={index} isHeader className="tableHeader" id={index} content={{ title: title, props: { ...props, width: props.width ? props.width : 'auto', colSpan: trueSpan !== 0 ? trueSpan : 1, padding: props.padding ? props.padding : '0' } }}></TableCell>
                                })
                            }
                            return res
                        }
                            , []).map(it => it.el)
                    }

                </TableRow>
                <TableRow className="tableHeader row" fontSize="12px" >
                    {
                        Object.keys(data.data).reduce((res, el, index) => {
                            const props = data.data[el].headerProps
                            const _data = data.data[el]
                            const title = Object.keys(_data).includes('major') ? el : undefined
                            if (title !== undefined && !res.find(it => it.title === title)) {

                                res.push({
                                    title: title, el: <TableCell key={index} isHeader className="tableHeader" id={index} content={{
                                        title: title,
                                        props: { ...props.minorProps, width: props.minorProps.width ? props.minorProps.width : 'auto', },
                                    }}></TableCell>
                                })
                            }
                            else res.push({ el: <TableCell key={index} isHeader className="tableHeader" id={index} content={{ props: { padding: '0' } }}></TableCell> })
                            return res
                        }
                            , []).map(it => it.el)
                    }
                </TableRow>
                <RowItem key={data.id} c={data} selectedSong={selectedSong} />
            </Table>
        </>

    )
}

export default TransactionDetailsBuyerPaypal