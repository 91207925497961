import styled from "styled-components";

export const Wrapper = styled.div`
display: grid;
width: 100%;
position: relative;
grid-template-columns: ${props => props.gridCols ? props.gridCols : '50px 50px'};
grid-template-areas: ${props => props.isApproved ? `'reject reject'` : `'accept reject'`};
grid-column-gap: 10px;
`;

export const PopupWrapperOverlay = styled.div`
position: fixed;
display: flex;
justify-content: center;
align-items: center;
width: ${props => props.visible ? '100%' : '0'};
height: ${props => props.visible ? '100%' : '0'};
top: 0;
left: 0;
background: rgba(0,0,0,0.15);
z-index: 2;
visibility: ${props => props.visible ? 'visible' : 'hidden'};
`;

export const ButtonContainer = styled.div`

height: 100%;
display: flex;
flex-direction: column;
justify-content: flex-end;
width: 100%;
align-content: end;
`;

export const PopupWrapper = styled.div`
width: ${ props => props.width ? props.width : '300px'};
display: grid;
color: #ffffff;
grid-template-rows: repeat(4,min-content);
z-index: 1;
visibility: ${props => props.visible ? 'visible' : 'hidden'};
grid-row-gap: 10px;
text-align: center;
padding: 20px;
user-select: none;

position: relative;
background: var(--greyBackground);
`;
export const Subtext = styled.div`
display: flex;
text-align: center;
text-transform: uppercase;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export const ValueHighlighted = styled.div`
font-weight: 600;
text-transform: none;
letter-spacing: 0.03rem;
`;

export const Strong = styled.strong`
color: ${props => props.action === 'accept' ? '#03cc00' : '#9e0000'};
text-transform: uppercase;
`;

export const Action = styled.div`
img{
    width: 20px;
    height: 20px;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
}
opacity: ${props => props.disabled ? '.3' : '1'};
position: relative;
display: flex;
align-items: center;
justify-content: center;
`;

export const AcceptButtonContainer = styled(Action)`

`;

export const RejectButtonContainer = styled(Action)`
grid-area: reject;
`;

export const CloseButtonContainer = styled.div`
display: flex;
width: 100%;
justify-content: flex-end;
position: relative;
img{
height: 20px;
width: 20px;
cursor: pointer;
}
`;