import styled from 'styled-components'

export const Table = styled.div`
display: table;
width: 100%;
margin: ${props => props.margin ? props.margin : ''};
overflow: hidden auto;
background: #1c1c1c;
/* tr{
    background: ${props => props.background ? props.background : ''};
} */
tr:nth-of-type(2n){
    background: #313131;
    //#313131
    
}
tr.selected{
    background: var(--yellow);
    color: #000000;
    outline: inset 1px #1A1A1A;
    
}
tr:not(.tableHeader):not(.selected):hover{
    background: ${props => props.isTop100 ? '#282828' : '#313131'};
    
}
tr.tableHeader{
    text-transform: ${props => props.noUppercase ? '' : 'uppercase'};
}
`;

export const TableRow = styled.tr`
transition: background 0.5s ease-out, color 0.3s ease-out;
background: ${props => props.background ? props.background : ''};
color: ${props => props.color ? props.color : ''};
width: 100%;

`;

export const TableCell = styled.div`
display: table-cell;
text-transform: ${props => props.textTransform ? props.textTransform : ''};
white-space: ${({ whiteSpace }) => (whiteSpace)};
width: ${props => props.width ? props.width : 'auto'};
height: ${props => props.height ? props.height : 'auto'};
vertical-align: middle;
text-align: ${props => props.textAlign ? props.textAlign : 'left'};
padding: ${props => props.padding ? props.padding : '20px 10px'};
font-size: ${({ fontSize }) => fontSize};
colspan: ${({ colSpan }) => colSpan};
`;

export const ToolTipItem = styled.div`
position: relative;
width: 100%;
display: flex;
font-size: ${({ fontSize })=> fontSize};
justify-content: center;
.tooltip{
    border-radius: 5px;
    background: var(--greyBackground);
    border: 1px solid #858585;
    padding: 10px 20px;
    position: absolute;
    visibility: hidden;
    z-index: 1;
    text-transform: none;
    right: ${props => props.right ? props.right : ''};
    bottom: ${props => props.bottom ? props.bottom : '30px'};
    left: ${props => props.left ? props.left : ''};
    white-space: nowrap;
}
&:hover .tooltip{
    visibility: visible;
}
`;

export const EmailLink = styled.a`
all: unset;
cursor: pointer;
color: var(--yellow);
font-weight: bold;
font-size: ${({ fontSize })=> fontSize};
`;