import React from 'react'

import { Wrapper } from './GridItem.styles'

const GridItem = ({
    isHeader,
    refID,
    innerRef,
    width,
    height,
    position,
    transition,
    top,
    right,
    bottom,
    boxShadow,
    left,
    margin,
    children,
    whiteSpace,
    gridArea,
    gridCol,
    gridRow,
    display,
    gridTempRows,
    gridTempCols,
    gridTemplate,
    gridAutoRows,
    gridAutoCols,
    gridAutoFlow,
    gridRowGap,
    gridColGap,
    alignText,
    flexWrap,
    justifyContent,
    flexDirection,
    justifyItems,
    justifySelf,
    gap,
    alignSelf,
    alignItems,
    alignContent,
    fontSize,
    letterSpacing,
    overflow,
    overflowX,
    color,
    background,
    border,
    borderRadius,
    flexGrow,
    flexShrink,
    flexBasis,
    zIndex,
    onClick,
    cursor,
    padding,
    fontWeight,
    borderBottom,
    ...props }) => {
    const isObject = (variable) => {
        return typeof (variable) === 'object' && !Array.isArray(variable) && variable !== null
    }
    return (
        <Wrapper
            transition={transition}
            borderRadius={borderRadius}
            isHeader={isHeader ? isHeader : undefined}
            ref={refID ? refID : innerRef ? innerRef : undefined}
            position={position}
            zIndex={zIndex}
            top={top}
            right={right}
            bottom={bottom}
            background={background}
            boxShadow={boxShadow}
            cursor={cursor}
            display={display}
            onClick={typeof (onClick) === 'function' ? onClick : undefined}
            margin={margin}
            padding={padding}
            overflow={overflow}
            overflowX={overflowX}
            height={height}
            left={left}
            width={width}
            flexDirection={flexDirection}
            flexWrap={flexWrap}
            justifySelf={justifySelf}
            alignSelf={alignSelf}
            gridTempRows={isObject(gridTemplate) ? gridTemplate.rows : gridTempRows}
            gridTempCols={isObject(gridTemplate) ? gridTemplate.cols : gridTempCols}
            gridAutoRows={gridAutoRows}
            gridAutoCols={gridAutoCols}
            gridAutoFlow={gridAutoFlow}
            gridColGap={gridColGap}
            gridRowGap={gridRowGap}
            gap={gap}
            whiteSpace={whiteSpace}
            gridArea={gridArea}
            gridCol={gridCol}
            gridRow={gridRow}
            letterSpacing={letterSpacing}
            alignText={alignText}
            justifyContent={justifyContent}
            justifyItems={justifyItems}
            alignContent={alignContent}
            alignItems={alignItems}
            flexGrow={flexGrow}
            flexShrink={flexShrink}
            flexBasis={flexBasis}
            color={color}
            fontSize={fontSize}
            fontWeight={fontWeight}
            borderBottom={borderBottom}
            border={border}
            {...props}
        >{children}</Wrapper>
    )
}

export default GridItem