import styled from 'styled-components';

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
height: 100vh;

h1{
    margin: 0;
    font-weight: 400;
    font-size: 2em;
    
}

h2{
    margin-top: 0.5rem;
    font-size: 1.3em;
    font-weight: 300;
    color: var(--purpleGrey);
    
}
`;

export const LoadingWheel = styled.div`
    border: 10px solid var(--greyBackground);
    border-top: 10px solid #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;

    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }
`;