import React from 'react'

import FilterTile from '../SearchBar/FilterTile'

const Tag = ({ color, onClick, name, ...props }) => {

    return (
        <FilterTile
            className="tag"
            color={color}
            buttonPosition="relative"
            buttonRight="0"
            buttonOpacity="0.35"
            textLeft="0"
            height="min-content"
            borderRadius="5px"
            overflowOff={true}
            textWidth="fit-content"
            border="none"
            width="fit-content"
            gap="5px"
            padding="10px"
            letterSpacing="unset"
            boxShadow="0 0 2px #000"
            onClick={onClick}
            name={name}
            {...props}></FilterTile>
    )
}


export default Tag