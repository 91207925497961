import styled, {css} from 'styled-components'
import { NavLink as Link } from 'react-router-dom'
import {FaBars} from 'react-icons/fa'


export const Nav = styled.nav`
  position: absolute;
  font-weight: 400;
  font-size: 0.85em;
  display: grid;
  letter-spacing: .02rem;
  grid-template-columns: 3.22vw 1fr 1fr 1fr 3.22vw;
  grid-template-areas: "spacer home links login spacer2";
  grid-template-rows: 1fr;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: var(--white);
  z-index: 1;
  
  

  button{
    grid-area: login;
    justify-self: end;
  }
  
`
export const HomeContainer = styled.div`
grid-area: home;
`;

export const NavLinkHome = styled(Link)`
all: unset;
width: min-content;
    color: #fff;
    font-family: Cano;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 1.1em;
    letter-spacing: 0.05rem;
    height: 100%;
    cursor: pointer;
    grid-area: home;
    
    color: #fff;

`


export const NavLink = styled(Link)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
    cursor: pointer;
    text-transform: uppercase;

    &.active{
        font-weight: 700;
        letter-spacing: 0.02rem;
    }

    color: #fff;

`

export const Bars = styled(FaBars)`
    display: none;
    color: #fff;

    @media screen and (max-width: 768px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, -75%);
        
        cursor: pointer;
    }
`

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    
    letter-spacing: .02rem;
    grid-area: links;
    @media screen and (max-width: 768px){
        display: none;
    }
`


export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    justify-content: end;
    grid-area: login;
    img{
     height: 100%;
     width: 23px;
     position: relative;
 }

    @media screen and (max-width: 768px)  {
        display: none;

    }
    `

export const NavBtnLink = styled(Link)`

all: unset;
width: min-content;
height:25px;
display: flex;
align-items: center;
position: relative;
background: transparent;
border: none;
cursor: pointer;
 img{
     height: 100%;
     width: 23px;
     position: relative;
 }

    /* &:hover{
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
    } */

    span{
        color: #000;
white-space: nowrap;
height: 100%;
position: relative;
width: fit-content;
display: flex;
justify-content: center;
align-items: center;
padding: 0 4px;
font-size: inherit;
text-transform: uppercase;
font-weight: 400;
letter-spacing: .02rem;
background: var(--white);
margin-left: 2px;
border-radius: 2px;
font-weight: 400; 
    }


`