import React, { useState, useEffect, useRef } from 'react'

import Table from '../Table2'
import Flexbox from '../Flexbox'
import TableCell from '../TableCell'
import TableRow from '../TableRow'
import GridItem from '../GridItem'
import DotMenu from '../DotMenu'
import SearchBarFooter from '../SearchBarFooter'
import Top100Modal from '../Top100New/Top100Modal'
import AcceptAndReject from '../AdminPanel/AcceptAndReject'
import dayjs from 'dayjs'

import { useBuyerApplicantHook } from '../../hooks/useBuyerApplicantHook'
import { useSongwriterHook } from '../../hooks/useSongwriterHook'

import { throttle } from '../../utils'
import { PAYLOAD_TYPES } from '../../config'

import { TransactionsWrapper } from './Buyers.styles'

const excludeKey = (obj, keyArray) => {
    return { ...Object.fromEntries(Object.entries(obj).filter(it => !keyArray.includes(it[0]))) }
}

const RowItem = ({
    c,
    selectedSong,
    getDotMenu,
    handleSelect,
    handlePayload,
    setPayload,
    showSideBar,
    selected,
    ...remainingProps }) => {


    const dotMenuRef = useRef(null)
    const [hover, setHover] = useState(false)
    const [disabled, setIsDisabled] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const {
        isDisabled,
        action,
        setAction, }
        = useSongwriterHook({ ...remainingProps, data: c.payload, setPayload })

    //content, setItemsSubmitting, itemsSubmitting, data, setPayload

    return (
        <TableRow content={(excludeKey(c.data, ['Accept', 'Reject']))} selectedSong={selectedSong} key={c.song_id} handleSelect={() => handleSelect(c)}
            onMouseEnter={() => { setHover(true) }}
            onMouseLeave={() => { setHover(false) }}
            onClick={(e) => {

                // if (e.target !== dotMenuRef.current && e.target !== dotMenuRefWrapper.current && !e.target.classList.contains('dotmenu') && e.target.classList.contains('row')) {
                //     console.log("TRIGGERED BY", e, e.target)
                //     onCheck(column.id, song.id, e)
                // }
            }}>
            <TableCell props={{ colSpan: '2', padding: '0', width: '50px', textAlign: 'center', }}>
                <AcceptAndReject
                    disabled={isDisabled}
                    handlePayload={async () => await handlePayload(PAYLOAD_TYPES.NEW_BUYER)}
                    popupVisible={showModal}
                    action={action}
                    preparePayload={(input) => {
                        setShowModal(true)
                        setAction(input)
                        const artist = {
                            userID: c.payload.userID,
                            status: input === "accept" ? '1' : '0',
                            email: c.payload.email,
                            songID: null,
                            name: c.payload.userID,
                            songTitle: null
                        }
                        //console.log("artist", artist)
                        setPayload(artist)

                    }}
                    closePopup={() => {
                        setShowModal(false)
                        setAction('')
                    }}
                    userID={c.payload.user_id}
                    isApproved={false}
                    type={'buyer'}
                    value={c.payload.user_id} />
            </TableCell>
            {/* <TableCell props={{ padding: '0', width: '50px' }}>
                <GridItem position="relative" width='50px'>
                    <DotMenu
                        _options={getDotMenu(c)}
                        right="0"
                        hoverActive={hover}
                        bottom="0"
                        _ref={dotMenuRef}
                        selected={selected}
                        fill={selected.includes(c.song_id) ? "#000000" : "#ffffff"}
                        status="1" />
                </GridItem>
            </TableCell> */}
        </TableRow>)
}
const RowItem2 = ({ c,
    selectedSong,
    getDotMenu,
    handleSelect,
    handlePayload,
    setPayload,
    showSideBar,
    selected }) => {

    const dotMenuRef = useRef(null)
    const [hover, setHover] = useState(false)
    const [disabled, setIsDisabled] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [action, setAction] = useState('')

    // const {
    // isDisabled,
    // preparePayload,
    // popupVisible,
    // setPopupVisibility,
    // action,
    // showRemoveApprSongModal,
    // closePopup,
    // isFinalWarning,
    // setIsFinalWarning }
    // = useSongwriterHook({ ...remainingProps, type, content, data, setPayload })



    return (
        <TableRow content={(excludeKey(c.data, ['Accept', 'Reject']))} selectedSong={selectedSong} key={c.song_id} handleSelect={() => handleSelect(c)}
            onMouseEnter={() => { setHover(true) }}
            onMouseLeave={() => { setHover(false) }}
            onClick={(e) => {

                // if (e.target !== dotMenuRef.current && e.target !== dotMenuRefWrapper.current && !e.target.classList.contains('dotmenu') && e.target.classList.contains('row')) {
                //     console.log("TRIGGERED BY", e, e.target)
                //     onCheck(column.id, song.id, e)
                // }
            }}>
            <TableCell props={{ colSpan: '2', padding: '0', width: '50px', textAlign: 'center', }}>
                <AcceptAndReject
                    disabled={disabled}
                    handlePayload={async () => await handlePayload(PAYLOAD_TYPES.NEW_BUYER)}
                    popupVisible={showModal}
                    action={action}
                    preparePayload={(input) => {
                        setShowModal(true)
                        setAction(input)
                        console.log("c", c)
                        const artist = {
                            userID: c.payload.userID,
                            status: input === "accept" ? '1' : '0',
                            email: c.payload.email,
                            songID: null,
                            name: c.payload.userID,
                            songTitle: null
                        }
                        //console.log("artist", artist)
                        setPayload(artist)

                    }}
                    closePopup={() => {
                        setShowModal(false)
                        setAction('')
                    }}
                    userID={c.user_id}
                    isApproved={false}
                    type={'buyer'}
                    value={c.name} />
            </TableCell>
            {/* <TableCell props={{ padding: '0', width: '50px' }}>
                <GridItem position="relative" width='50px'>
                    <DotMenu
                        _options={getDotMenu(c)}
                        right="0"
                        hoverActive={hover}
                        bottom="0"
                        _ref={dotMenuRef}
                        selected={selected}
                        fill={selected.includes(c.song_id) ? "#000000" : "#ffffff"}
                        status="1" />
                </GridItem>
            </TableCell> */}
        </TableRow>)
}

const Buyers = ({ buyers, type, ...remainingProps }) => {
    const { content, setPayload, handlePayload, payload, itemsSubmitting, setItemsSubmitting } = remainingProps
    console.log("remaining props", remainingProps)

    const props = useBuyerApplicantHook({ buyers, type, ...remainingProps })
    const { tableData,
        selectedSong,
        getDotMenu,
        handleSelect,
        showSideBar,
        closeSideBar,
        secondColumnSize,
        selected,
        sideBarDetails,
        filters,
        setFilters,
        onSearchChange,
        searchValue,
    } = props
    const { data, header, subHeader } = tableData

    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const { sidebarlinks, setContent, setPage, setFilter, pullSongs, pullBuyers } = props

    useEffect(() => {
        let isMounted = true;
        const handleResize = throttle(() => {
            if (isMounted) {
                setWindowSize(window.innerWidth)
            }
        }, 1000)
        window.addEventListener("resize", handleResize);
        return () => {
            isMounted = false
            window.removeEventListener("resize", handleResize)
        };
    }, [])

    if (data.length > 0)
        return (
            <Flexbox column height="100%" overflow="hidden">
                <TransactionsWrapper
                    overflow="hidden visible"
                    alignItems="start"
                    position="relative"
                    gridTempCols="2fr min-content"
                    gridTempRows="1fr min-content"
                    height="100%"
                    justifyContent="start"
                    width="100%"
                    flexDirection="row"
                    flexGrow="1"
                    // display="grid" 
                    style={{ flexGrow: '1' }}>

                    <GridItem column width="100%" height="100%" flexShrink="3" justifyContent="start" alignItems="start" background="#1c1c1c" margin='0 50px'>

                        <Table oddHighlight>
                            <TableRow fontSize="11px" className="tableHeader row" >
                                {
                                    Object.keys(data[0].data).reduce((res, el, index) => {
                                        const props = data[0].data[el].headerProps
                                        const _data = data[0].data[el]

                                        const title = Object.keys(_data).includes('major') ? _data.major : Object.keys(props).includes('headerHtmlTitle') ? props.headerHtmlTitle : el
                                        if (!res.find(it => it.title === title)) {
                                            const trueSpan = Object.keys(data[0].data).reduce((a, b) => {
                                                const _data = data[0].data[b]

                                                if (!Object.keys(_data).includes('major'))
                                                    return a
                                                if (_data.major !== title)
                                                    return a

                                                a = a + 1;
                                                return a
                                            }, 0)

                                            res.push({
                                                title: title, el:
                                                    <TableCell key={index} isHeader className="tableHeader" id={index} content={{ title: title, props: { ...props, width: props.width ? props.width : 'auto', colSpan: trueSpan !== 0 ? trueSpan : 1, padding: props.padding ? props.padding : '0' } }}></TableCell>
                                            })
                                        }
                                        return res
                                    }
                                        , []).map(it => it.el)
                                }

                            </TableRow>
                            <TableRow className="tableHeader row" fontSize="12px">
                                {/* {
                                    subHeader.map((it, index) => <Tabl<AcceptAndReject
                    disabled={isDisabled}
                    handlePayload={async () => await handlePayload(PAYLOAD_TYPES.NEW_BUYER)}
                    popupVisible={popupVisible}
                    action={action}
                    preparePayload={preparePayload}
                    closePopup={closePopup}
                    userID={data.user_id}
                    isApproved={isApproved}
                    type={'buyer'}
                    value={data.name} />eCell key={index} isHeader fontSize="10px" className="tableHeader" id={index} content={{ ...it, props: { padding: '0' } }}></TableCell>)
                                } */}


                                {
                                    Object.keys(data[0].data).reduce((res, el, index) => {
                                        const props = data[0].data[el].headerProps
                                        const _data = data[0].data[el]
                                        const title = Object.keys(_data).includes('major') ? el : undefined
                                        if (title !== undefined && !res.find(it => it.title === title)) {

                                            res.push({
                                                title: title, el: <TableCell key={index} isHeader className="tableHeader" id={index} content={{
                                                    title: title,
                                                    props: { ...props.minorProps, width: props.minorProps.width ? props.minorProps.width : 'auto', },
                                                }}></TableCell>
                                            })
                                        }
                                        else res.push({ el: <TableCell key={index} isHeader className="tableHeader" id={index} content={{ props: { padding: '0' } }}></TableCell> })
                                        return res
                                    }
                                        , []).map(it => it.el)
                                }
                            </TableRow>
                            {/* {
                                data.map((c) => (
                                    <RowItem key={c.id} c={c} closePopup={closePopup} handlePayload={handlePayload} setPayload={setPayload} {...props}  />
                                ))
                            } */}
                            {
                                data.map((c) => (
                                    <RowItem key={c.id} c={c} data={c.payload} handlePayload={handlePayload} setPayload={setPayload} {...props} content={content} setItemsSubmitting={setItemsSubmitting} itemsSubmitting={itemsSubmitting} />
                                ))
                            }
                        </Table>
                    </GridItem>
                    <Top100Modal
                        left={windowSize < 1250 && !sideBarDetails.showSideBar ? 'calc(100vw)' : '0'}
                        flexGrow="5"
                        width="600px"
                        position={windowSize < 1250 ? 'absolute' : undefined}
                        // margin={windowSize < 1500 ? '0 calc(-100vw) 0 0' : '0 calc(-100vw) 0 0'}
                        zIndex="5"
                        modalDetails={sideBarDetails}
                        modalType={sideBarDetails.sideBarType}
                        showModal={sideBarDetails.showSideBar}
                        closeModal={closeSideBar} />

                </TransactionsWrapper>
                {/* <SearchBarFooter showBar={!sideBarDetails.showSideBar} searchValue={searchValue} onSearchChange={onSearchChange} setFilters={setFilters} filters={filters} /> */}
            </Flexbox>
        )
    else return <></>
}

export default Buyers