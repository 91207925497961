import styled from 'styled-components'

export const Wrapper = styled.div`
background: ${props => props.background ? props.background : ''};
letter-spacing: ${props => props.letterSpacing ? props.letterSpacing : ''};
z-index: ${props => props.zIndex ? props.zIndex : ''};
width: ${props => props.width ? props.width : '100%'};
max-width: ${props => props.maxWidth ? props.maxWidth : ''};
max-height: ${props => props.maxHeight ? props.maxHeight : ''};
height: ${props => props.height ? props.height : ''};
min-height: ${props => props.minHeight ? props.minHeight : ''};
margin: ${props => props.margin ? props.margin : ''};
box-shadow: ${props => props.boxShadow ? props.boxShadow : ''};
left: ${props => props.left ? props.left : ''};
right: ${props => props.right ? props.right : ''};
top: ${props => props.top ? props.top : ''};
position: ${props => props.position ? props.position : ''};
flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row'}; 
flex-wrap: ${props => props.flexWrap ? props.flexWrap : ''};
flex-grow: ${props => props.flexGrow ? props.flexGrow : ''}; 
flex-basis: ${props => props.flexBasis ? props.flexBasis : ''};
display: ${props => props.display ? props.display : 'flex'};
gap: ${props => props.gap ? props.gap : ''};
transition: ${props => props.transition ? props.transition : ''};
white-space: ${props => props.whiteSpace ? props.whiteSpace : ''};
text-align: ${props => props.alignText ? props.alignText : 'left'};
text-transform: ${props => props.transformText ? props.transformText : ''};
justify-content: ${props => props.justifyContent ? props.justifyContent : 'start'};
justify-items: ${props => props.justifyItems ? props.justifyItems : 'start'};
align-content: ${props => props.alignContent ? props.alignContent : 'start'};
align-items: ${props => props.alignItems ? props.alignItems : 'center'};
color: ${props => props.color ? props.color : 'var(--fontColor)'};
font-size: ${props => props.isHeader ? '10px' : props.fontSize ? props.fontSize : '12px'};
font-weight: ${props => props.fontWeight ? props.fontWeight : ''};
border-bottom: ${props => props.borderBottom ? props.borderBottom : ''};
border: ${props => props.border ? props.border : ''};
border-radius: ${props => props.borderRadius ? props.borderRadius : ''};
padding: ${props => props.padding ? props.padding : ''};
overflow-x: ${props => props.overflowX ? props.overflowX : ''};
overflow: ${props => props.overflow ? props.overflow : ''};
justify-self: ${props => props.justifySelf ? props.justifySelf : ''};
align-self: ${props => props.alignSelf ? props.alignSelf : ''};
cursor: ${props => props.cursor ? props.cursor : 'default'};
text-overflow: ${props => props.textOverflow ? props.textOverflow : ''};
opacity: ${({ opacity }) => opacity};
.showContent{
    max-height: 100%;
    max-width: 400px;
    overflow: visible;
    white-space: normal;
}

`;