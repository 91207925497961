import styled from 'styled-components'

export const Strong = styled.strong`
color: ${props => props.action === 'accept' ? '#03cc00' : '#9e0000'};
text-transform: uppercase;
`;

export const AlertIcon = styled.img`
height: 20px;
width: 20px;
object-fit: contain;
`;

export const Spotify = styled.a`
display: flex;
cursor: ${props => props.spotify ? 'pointer' : 'default'};
flex-direction: column;
align-items: center;
justify-content: center;
height: 100%;
opacity: ${props => props.spotify ? '1' : '0.3'};
img{
    height: 15px;
    width: 15px;
}
`;

export const Instagram = styled.a`
display: flex;
cursor: ${props => props.instagram ? 'pointer' : 'default'};
flex-direction: column;
align-items: center;
justify-content: center;
opacity: ${props => props.instagram ? '1' : '0.3'};
img{
    height: 15px;
    width: 15px;
}
`;

export const ColWithSubText = styled.div`
display: flex;
flex-direction: column;
gap: 3px;
width: fit-content;
float: ${({ float }) => float};
span{
    user-select: all;
}
span:nth-of-type(2){
    opacity: 0.35;
    font-size: 10px;
    
}
`;