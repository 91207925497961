import styled from 'styled-components'

export const DropDownContainerBox = styled.div`
z-index: 10;
position: ${props => props.position ? props.position : 'absolute'};
bottom: ${props => props.bottom ? props.bottom : ''};
right: ${props => props.right ? props.right : ''};
top: ${props => props.top ? props.top : ''};
left: ${props => props.left ? props.left : ''};
`;

export const Action = styled.div`

    width: 30px;
height: 30px;

border: ${props => props.border ? props.border : ''};
border-radius: ${props => props.borderRadius ? props.borderRadius : ''};
position: relative;
display: flex;
align-items: center;
justify-content: center;
`;

export const DotMenuWrapper = styled(Action)`
opacity: ${props => props.emptySelection ? '0.25' : '1.0'};
transform: ${props => props.transform ? props.transform  : 'none'};
svg{
    height: 15px;
}

`;