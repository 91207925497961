import React from 'react'

import Agreed from '../../images/agreedIcon.svg'
import Disagreed from '../../images/close.svg'
import EmailIcon from '../../images/footerEmailUsIcon.svg'
import Table from '../Table'
import { adjustPrice, calculateCut, calculatePriceWithFee } from '../../helpers'
import { AZAA_PLATFORM_FEE_PERCENT } from '../../config'
import {
    TableContainer,
    Email,
    EmailIconWrapper
} from './SongDetailsTable.styles'

const SongDetailsTable = ({ margin, selectedSong, border, boxShadow, borderBottom, padding }) => {

    const showTrailing = selectedSong.price === '-' || selectedSong.price === '' || selectedSong.price === null || selectedSong.price === undefined || selectedSong.contributors.length === 0 ? false
        : parseFloat(selectedSong.price.replace(/,/g, '')) % 1 ? true
            : (parseFloat(selectedSong.price.replace(/,/g, '')) * (AZAA_PLATFORM_FEE_PERCENT) / 100.00) % 1 ? true
                : selectedSong.contributors.map(c => parseFloat(selectedSong.price.replace(/,/g, '')) * parseFloat(c.split) / 100.00).reduce((a, b) => { return b % 1 ? true : a }, false)
    const tableContent = selectedSong.contributors.map((content) => (
        {
            contentID: selectedSong.id,
            rowHighlight: undefined,
            contentArr: [
                <EmailIconWrapper><a href={`mailto:${content.email}`}><img src={EmailIcon} /></a></EmailIconWrapper>,
                <Email><div className="emailShortened">{content.email}</div><div className="tooltip" title="t">{content.email}</div></Email>,
                `${content.split}%`,
                `${calculateCut(content.split, selectedSong.price, showTrailing)}`,
                content.agreement !== null ? <><img src={content.agreement === '1' ? Agreed : Disagreed} alt={content.agreement === '1' ? "agreed" : "disagreed"} style={{ width: "15px" }} /></> : '-'
            ]
        })
    )
    const rowAlignmentArray = ["flex-start", "flex-start", "center", "center", "center"];
    return (
        <TableContainer border={border} boxShadow={boxShadow} borderBottom={borderBottom} padding={padding} margin={margin}>
            <Table
                content={tableContent}
                noHover={true}
                headerFontSize="10px"
                contentFontSize="12px"
                tableHeaderTextStyling={{ textTransform: 'uppercase', fontSize: '10px' }}
                tableHeader={[<EmailIconWrapper><img src={EmailIcon} /></EmailIconWrapper>, "Contributor", "Split [%]", "Split [$]", "Agreement"]}
                gridTemplateColumns='20px 1fr 45px 3.5rem 63px'
                rowAlignmentArray={rowAlignmentArray}
                gridColGap=".75rem"
                padding="10px 0"
            />
        </TableContainer>
    )
}

export default SongDetailsTable
