import { useState, useEffect, useRef } from 'react';
import API from '../API';
import { useAuth } from '../contexts/AuthContext'

export const useNotifications = () => {
    const [showNotificationModal, setShowNotificationModal] = useState(false)
    const [waitingForServerResponse, setWaitingForServerResponse] = useState(false)
    const [notificationModalMessage, setNotificationModalMessage] = useState("")
    const [disabledItem, setDisabledItem] = useState(null)
    const [stateCheck, setStateCheck] = useState(0)
    useEffect(() => {
        if (showNotificationModal && !waitingForServerResponse) {
            let id = setTimeout(() => setShowNotificationModal(false), 5 * 1000)
            return () => clearTimeout(id);
        }
    }, [showNotificationModal, waitingForServerResponse])
    return {
        waitingForServerResponse,
        setStateCheck,
        setWaitingForServerResponse,
        showNotificationModal,
        setShowNotificationModal,
        notificationModalMessage,
        setNotificationModalMessage
    }
}