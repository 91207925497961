import styled from "styled-components";

export const Wrapper = styled.div`
    background: transparent;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: ${props => props.border ? props.border : '1px solid rgba(255,255,255, 0.25)'};
    box-shadow: ${({ boxShadow }) => boxShadow};
    background: ${props => (props.itemIndex + 1) === (props.currentIndex + 1) && props.step != 1 ? 'rgba(255,255,255,1)' : 'transparent'};
    color: ${props => props.color ? props.color : (props.itemIndex + 1) === (props.currentIndex + 1) && props.step != 1 ? 'rgba(0,0,0,1)' : '#fff'};
    font-weight: ${props => (props.itemIndex + 1) === (props.currentIndex + 1) && props.step != 1 ? '700' : '400'};
    padding: ${({ padding }) => padding};
    border-radius: ${({ borderRadius }) => borderRadius || '20px'};
    position: relative;
    gap: ${({ gap }) => gap};
    margin: ${({ margin }) => margin};
    width: ${({ width }) => width || '180px'};
    height: ${({ height }) => height || '40px'};
    letter-spacing: ${({ letterSpacing }) => letterSpacing || '0.1rem'};
    font-size: 10px;

    >div{
        width: ${({ textWidth }) => textWidth || 'calc(100% - 60px)'};
        left: ${({ textLeft }) => textLeft || '20px'};
        text-overflow: ${props => props.overflowOff ? '' : 'ellipsis'};
        white-space: nowrap;
        position: relative;
        overflow: ${props => props.overflowOff ? '' : 'hidden'};
    }

`;

export const Button = styled.button`
    all: unset;
    font-family: var(--defaultFont);
    color: ${props => props.color ? props.color : (props.itemIndex + 1) === (props.currentIndex + 1) && props.step != 1 ? 'rgba(0,0,0,1)' : '#fff'};
    position: ${({ buttonPosition }) => buttonPosition || 'absolute'};
    right: ${({ buttonRight }) => buttonRight || '20px'};
    opacity: ${({ buttonOpacity }) => buttonOpacity};
    cursor: pointer;
   

`;