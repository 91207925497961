import React from 'react'
import formNavArrow from '../../images/formNavArrow.svg'
import styled from 'styled-components'

const NavArrowIcon = styled.img`
position: relative;
height: 10px;
right: 1px;
width: 10px;
transform: ${((transform) => transform)} ;
`;

const Wrapper = styled.div`
position: relative;
height: 25px;
width: 25px;
display: flex;
justify-content: center;
align-items: center;
border: 1px solid #fff;
flex-shrink: 0;
border-radius: 50%;
`;

const NavArrow = ({ onClick, next, back, down, up, ...props }) => {

    const getDirection = () => {
        if (next)
            return '';
        if (back)
            return 'rotate(180deg)';
        if (down)
            return 'rotate(90deg)';
        if (up)
            return 'rotate(-90deg)';
        return ''
    }

    return (
        <Wrapper onClick={onClick}>
            <NavArrowIcon src={formNavArrow} transform={getDirection()} />
        </Wrapper>
    )
}

export default NavArrow