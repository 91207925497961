import styled from "styled-components";

export const Wrapper = styled.div`
    background: transparent;
    flex-shrink: 0;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid rgba(255,255,255, 0.25);
    background: ${props => (props.itemIndex + 1) === (props.currentIndex + 1) && props.step != 1 ?  'rgba(255,255,255,1)' : 'transparent' };
    color: ${props => (props.itemIndex + 1) === (props.currentIndex + 1) && props.step != 1 ?  'rgba(0,0,0,1)' : '#fff' };
    font-weight: ${props => (props.itemIndex + 1) === (props.currentIndex + 1) && props.step != 1 ?  '700' : '400' };
    
    
    position: relative;
    width: 300px;
    height: 45px;
    font-size: 11px;
    letter-spacing: 0.1rem;
    text-transform: uppercase;

    span{
        height: 45px;
        display: flex;
        align-items: center;
        width: calc(100% - 60px);
        text-overflow: ellipsis;
        white-space: nowrap;
        left: 20px;
        position: relative;
        overflow: hidden;
    }

`;

export const Button = styled.button`
    all: unset;
    position: absolute;
    right: 20px;
    cursor: pointer;
   

`;