import React from 'react'



import {
    Wrapper,
    Process,
    ProcessHeader,
    SubText,
    Song,
    Tempo,
    Genre,
    Spotify,
    Artist,
    Email,
    Accept,
    Reject,
    Status,
    Date,
    tem
} from './SongwriterTabHeader.styles'

import clock from '../../../images/clockIcon.svg'
import favorites from '../../../images/favoriteIcon.svg'

import GridItem from '../../GridItem'

const SongwriterTabHeader = ({ isApproved, type }) => {
    if (isApproved && type === 'buyers') {
        return (
            <Wrapper
                // gridTemplateAreas={"artist email spotify acceptreject"}
                // gridTemplateCols={'1fr 1fr 60px 110px'}
                gridTemplateAreas={"artist email publisher website concierge instagram purchases favorites date acceptreject"}
                gridTemplateCols={'1fr minmax(200px,1fr) 2fr 1fr 60px 60px 70px 25px 40px 110px'}
            >
                <Artist>Buyer</Artist>
                <GridItem isHeader gridArea="publisher" fontSize="inherit" alignItems="start">Label/Mgmt. Aff.</GridItem>
                <GridItem isHeader gridArea="concierge" alignItems="start" >Concierge</GridItem>
                <GridItem isHeader gridArea="instagram" alignItems="center" >Instagram</GridItem>
                <GridItem isHeader gridArea="website" alignItems="start" >Website</GridItem>
                <GridItem isHeader gridArea="purchases" fontSize="inherit" alignItems="center">Purchases</GridItem>
                <GridItem isHeader gridArea="favorites" fontSize="inherit" ><img src={favorites} alt="favorites" style={{ width: "10px", height: '10px' }} /></GridItem>
                <Email>Email</Email>
                <Date><img src={clock} alt="clockIcon" style={{ width: "12px", height: '12px' }} /></Date>
                <Process isApproved={isApproved}>
                    <Reject>
                        Revoke Acceptance
                    </Reject>
                </Process>
            </Wrapper>
        )
    }
    else if (!isApproved && type === 'buyers') { //1fr 80px 80px 70px 25px 70px 110px'
        return (
            <Wrapper
                gridTemplateAreas={"artist email publisher website concierge instagram date acceptreject"}
                gridTemplateCols={'1fr minmax(200px,1fr) 2fr 1fr 60px 60px 40px min-content'}
            >
                <Artist>Buyer</Artist>
                <GridItem isHeader gridArea="publisher" alignItems="start">Label/Mgmt. Aff.</GridItem>
                <GridItem isHeader gridArea="instagram" alignItems="center" >Instagram</GridItem>
                <GridItem isHeader gridArea="concierge" alignItems="start" >Concierge</GridItem>
                <GridItem isHeader gridArea="website" alignItems="start" >Website</GridItem>
                <Email>Email</Email>
                <GridItem isHeader gridArea="date" alignItems="center" ><img src={clock} alt="clockIcon" style={{ width: "12px", height: '12px' }} /></GridItem>
                <Process>
                    <ProcessHeader>
                        Buyer
                    </ProcessHeader>
                    <SubText>Accept</SubText>
                    <SubText>Reject</SubText>
                </Process>
            </Wrapper>
        )
    }
    else if (isApproved && type === 'songwriters') {
        return (
            <Wrapper
                // gridTemplateAreas={"artist email spotify acceptreject"}
                // gridTemplateCols={'1fr 1fr 60px 110px'}
                gridTemplateAreas={"artist email publisher instagram spotify acceptreject"}
                gridTemplateCols={'1fr minmax(200px,2fr) 1fr 80px 60px 110px'}
            >
                <Artist>Songwriter</Artist>
                <GridItem isHeader gridArea="publisher" alignItems="start">Publisher</GridItem>
                <GridItem isHeader justifyContent="start" gridArea="instagram">Instagram</GridItem>
                <Email>Email</Email>
                <Spotify>Spotify</Spotify>
                <Process isApproved={isApproved}>
                    <Reject>
                        Revoke Acceptance
                    </Reject>
                </Process>
            </Wrapper>
        )
    }
    else if (type === 'songwriters') {
        return (
            <Wrapper
                gridTemplateAreas={"spacer artist song tempo genre publisher date instagram spotify acceptreject"}
                gridTemplateCols={'20px 2fr repeat(2,1fr) 120px 1fr 70px 80px 60px min-content'}
            >
                <Song>Song</Song>
                <Tempo>Tempo</Tempo>
                <Genre>Genre</Genre>
                <Artist>Songwriter</Artist>
                <GridItem isHeader gridArea="publisher" alignItems="start">Publisher</GridItem>
                <GridItem isHeader justifyContent="start" gridArea="instagram">Instagram</GridItem>
                <Date>Received</Date>
                <Spotify>Spotify</Spotify>
                <Process>
                    <ProcessHeader>
                        {isApproved ? 'Song' : 'Songwriter'}
                    </ProcessHeader>
                    <SubText>Accept</SubText>
                    <SubText>Reject</SubText>
                </Process>
            </Wrapper>
        )
    }
    else if (isApproved && type === "songs") {

        return (
            <Wrapper
                gridTemplateAreas={"spacer song artist tempo genre status price date spotify removeSong"}
                gridTemplateCols={'20px repeat(4,1fr) 250px 100px 60px 60px 60px'}>
                <Song>Song</Song>
                <Tempo>Tempo</Tempo>
                <Genre>Genre</Genre>
                <Artist>Songwriter</Artist>
                <GridItem isHeader gridArea="price">Price</GridItem>
                <Date><img src={clock} alt="clockIcon" style={{ width: "15px" }} /></Date>
                <Status>Status</Status>
                <Spotify>Spotify</Spotify>
                <GridItem isHeader gridArea="removeSong" alignText="center">Delete Song</GridItem>
            </Wrapper>
        )
    }
    else if (!isApproved && type === "songs") {

        return (
            <Wrapper
                gridTemplateAreas={"spacer artist song tempo genre date spotify acceptreject"}
                gridTemplateCols={'20px repeat(5,1fr) 60px min-content'}
            >
                <Song>Song</Song>
                <Tempo>Tempo</Tempo>
                <Genre>Genre</Genre>
                <Artist>Songwriter</Artist>
                <Date>Received</Date>
                <Spotify>Spotify</Spotify>
                <Process>
                    <ProcessHeader>
                        Song
                    </ProcessHeader>
                    <SubText>Accept</SubText>
                    <SubText>Reject</SubText>
                </Process>
            </Wrapper>
        )
    }
    else {
        return <Wrapper></Wrapper>
    }

}

export default SongwriterTabHeader
