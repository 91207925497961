import styled from 'styled-components'

export const Wrapper = styled.div`
border-bottom: 2px solid var(--greyBackground);
position: relative;
width: 100%;
height: 60px;
display: grid;
grid-template-columns:  auto auto auto 1fr;
grid-template-areas: "one two three date";
justify-content: center;

`;



export const Option = styled.div`
    position: relative;
    display: flex;
    user-select: none;
    justify-content: center;
    align-items: center;
    padding: 20px;
    white-space: nowrap;

    &.current{
        border-bottom: 2px solid #fff;
    }
`;

export const Applicant = styled(Option)`
    grid-area: one;
`;

export const Registered = styled(Option)`
    grid-area: two;
`;
export const Rejected = styled(Option)`
    grid-area: three;
`;

export const Date = styled.div`
    grid-area: date;
`;