import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom'
import API from '../API';
import { useAuth } from '../contexts/AuthContext'
import { updateRelTime } from '../utils'
import { PAYLOAD_TYPES } from '../config'
import dayjs from 'dayjs'
import { useNotifications } from './useNotifications'
import { useAdminPanelResponseHook } from './useAdminPanelResponseHook'
import { shortFormatPrice, showTrailing, calculatePriceWithFee, calculateCut } from '../helpers'

import agreedIcon from '../images/agreedIcon.svg'
import notAccepted from '../images/close.svg'

import ToolTip from '../components/ToolTip'

import clock from '../images/clockIcon.svg'
import InstagramIcon from '../images/instagramIcon.svg'

import AcceptAndReject from '../components/AdminPanel/AcceptAndReject'
import Flexbox from '../components/Flexbox'
import HideShowText from '../components/HideShowText'

export const getCompletionStatus = (payoutComplete = undefined, stemTransfer = undefined, index = 0, justifyContent = 'center') => {
    if (payoutComplete === undefined)
        return stemTransfer[index].updated_at ?
            <ToolTip justifyContent={justifyContent} boxShadow="0 0 10px rgb(0,0,0)" background="#000000" bottom="20px" border="#000000" absolute toolTipText={`Confirmed ${dayjs.unix(stemTransfer[index].updated_at).format('M/D/YY')}`}>
                <img src={agreedIcon} style={{ width: '15px', height: '15px' }} /></ToolTip > : <img src={notAccepted} style={{ height: '15px', width: '15px' }} />
    else if (!stemTransfer[0].updated_at || !stemTransfer[1].updated_at)
        return '-'
    else return payoutComplete === '1' ? <img src={agreedIcon} style={{ height: '15px', width: '15px' }} /> : <img src={notAccepted} style={{ height: '15px', width: '15px' }} />
}

export const useBuyerApplicantHook = (args) => {
    const { buyers, type, handlePayload, payload, setPayload } = args
    const pRef = useRef(null)
    const [action, setAction] = useState('')
    const [tableData, setTableData] = useState({
        data: [],

    })
    const isDisabled = useRef(Array(buyers.length).fill(false))
    updateRelTime()
    const { currentUser } = useAuth()
    const location = useLocation()

    const popupVisible = useRef(false)
    const [selected, setSelected] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const onSearchChange = (input) => {
        // setSearchValue(e.target.value)
        setSearchValue(input)
    }
    const [filters, setFilters] = useState([])
    const [loading, setLoading] = useState(true)
    const secondColumnSize = useRef('2fr min-content')
    const [sideBarDetails, setSideBarDetails] = useState({
        showSideBar: false,
        sideBarType: 1,
        fullSideBar: false,
        data: {},
        title: '',
        songTitle: '',
        buttonText: ''

    })
    const [sideBarType, setSideBarType] = useState(false)
    const selectedSong = useRef(null)
    const handleSelect = (c) => {
        selectedSong.current = c
    }

    const songDetails = {
        options: {
            'option-1': {
                id: 'songwriter-details',
                title: 'Songwriter Details',
                onClick: async (songId) => { return await API.fetchUserBySongId(currentUser, songId) },
            },
            'option-2': {
                id: 'song-details',
                title: 'Song Details',
                onClick: async (songId) => { return await API.fetchSong(currentUser, songId) },

            },
            'option-3': {
                id: 'buyer-details',
                title: 'Buyer Details',
                onClick: async (songId) => { return await API.fetchBuyerBySongId(currentUser, songId) },
            },
            'option-4': {
                id: 'message-songwriter',
                title: 'Message Songwriter',
                onClick: async (songId) => {
                    const res = await API.fetchSong(currentUser, songId)

                    return { ...res, receiver_id: res.primary_songwriter_id, buttonText: 'Message Songwriter' }
                },
            },
            'option-5': {
                id: 'message-buyer',
                title: 'Message Buyer',
                onClick: async (songId) => {
                    const res = await API.fetchSong(currentUser, songId)
                    return { ...res, receiver_id: res.buyer_user_id, buttonText: 'Message Buyer' }
                },
            }
        }
    }

    const getDotMenu = (song) => {
        console.log("song is", song)
        return [
            {
                value: 2, innerText: 'Accept', onClick: () => {
                    //setSideBar('accept-buyer', song.id)
                }
            },
            {
                value: 2, innerText: 'Reject', onClick: () => {
                    //setSideBar('accept-buyer', song.id)
                }
            },
            // {
            //     value: 4, innerText: 'Buyer Details', onClick: () => {
            //         setSideBar('buyer-details', song.id)
            //     }
            // },
            // {
            //     value: 5, innerText: 'Message Songwriter', onClick: () => {
            //         setSideBar('message-songwriter', song.id)
            //     }
            // },
            // {
            //     value: 6, innerText: 'Message Buyer', onClick: () => {
            //         setSideBar('message-buyer', song.id)
            //     }
            // },
        ]
    }

    const mapSongDetails = (_sideBarId, songId) => {
        const typeId = Object.keys(songDetails.options).find(id => {
            const _option = songDetails.options[id]
            return _option.id === _sideBarId
        })
        console.log("type id is", typeId, _sideBarId)
        return songDetails.options[typeId]
    }

    const setSideBar = async (_sideBarId, songId) => {
        payload.current = { ...payload.current, songId: songId }
        setSelected([songId])

        const _detailsType = mapSongDetails(_sideBarId, songId)
        const res = await _detailsType.onClick(songId)
        console.log("songId", songId, buyers)
        const _selectedSongDetails = buyers.find(it => parseInt(it.song_id) === parseInt(songId)).song_title
        if (res) {
            secondColumnSize.current = '2fr minmax(500px, 1fr)'
            setSideBarDetails(prev => ({ ...prev, sideBarType: _sideBarId, showSideBar: true, fullSideBar: true, title: _detailsType.title, data: { ...res }, songTitle: _selectedSongDetails }))
            // setButton(prev => ({ ...prev, fullSideBar: true }))

        }

    }

    const closePopup = (e) => {
        console.log("popup visibility")
        // setPopupVisibility(false);
        popupVisible.current = false
        setAction('')
    }

    const handleArtistsList = (entry) => {
        if (!entry)
            return '-'
        else
            return (
                <HideShowText text={entry} />)
    }

    const replaceBlankEntry = (entry) => { return (entry ? entry : '-') }
    useEffect(() => {
        if (buyers) {
            isDisabled.current = Array(buyers.length).fill(false)
            const formattedData = []
            const headerProps = [
                { textAlign: 'center', headerHtmlTitle: <img src={InstagramIcon} alt="insta" style={{ width: "15px", height: '15px', opacity: '0.35' }} />, verticalAlign: 'top', padding: '20px 10px 0', whiteSpace: 'nowrap', minWidth: '', height: '' },
                {
                    verticalAlign: 'top', padding: '20px 10px 0 20px', minWidth: '', height: '', whiteSpace: '', textAlign: ''
                },
                { verticalAlign: 'top', padding: '20px 10px 0', textAlign: 'left', minWidth: '', height: '' },
                { verticalAlign: 'top', padding: '20px 10px 0', whiteSpace: 'nowrap', minWidth: '', height: '' },
                { verticalAlign: 'top', padding: '20px 10px 0', whiteSpace: 'nowrap', minWidth: '', height: '' },
                { verticalAlign: 'top', padding: '20px 10px 0', whiteSpace: 'nowrap', minWidth: '', height: '' },
                { headerHtmlTitle: <img src={clock} alt="clockIcon" style={{ width: "15px", height: '15px' }} />, textAlign: 'center', verticalAlign: 'top', padding: '20px 10px  0', whiteSpace: 'nowrap', minWidth: '', height: '' },
                { textAlign: 'center', verticalAlign: 'top', padding: '20px 10px 0', whiteSpace: 'nowrap', minWidth: '', height: '', minorProps: { color: 'var(--purpleGrey)', width: '50px', fontSize: '10px', textAlign: 'center', padding: '0 0 10px ' } },
                { textAlign: 'center', verticalAlign: 'top', padding: '20px 10px 0', whiteSpace: 'nowrap', minWidth: '', height: '', minorProps: { color: 'var(--purpleGrey)', width: '50px', fontSize: '10px', textAlign: 'center', padding: '0 0 10px' } },
            ]
            const getAdditionalInfo = ({ payoutComplete = null, payoutDate = null, stemTransfer }) => {
                if (type === 'Completed')
                    return ({
                        'Admin Payout': { width: 'auto', title: getCompletionStatus(payoutComplete, stemTransfer), props: { padding: '20px 10px', textAlign: 'center' }, },
                        'Date of Payout': { width: 'auto', title: payoutDate ? dayjs.unix(payoutDate).format('M/D/YY') : '-', props: { padding: '20px 10px', textAlign: 'center' }, },
                    })
                else return
            }
            buyers.forEach((buyer, index) => {
                const d = (({ name, email, label, artists, website, instagram, created_at }) =>
                ({
                    'Instagram': { title: instagram, props: { textAlign: 'center', padding: '20px 10px', type: 'instagram', }, },
                    'Buyer': { title: replaceBlankEntry(name), props: { padding: '20px 10px 20px 20px', }, },
                    'Email': { title: replaceBlankEntry(email), props: { padding: '20px 10px', }, },
                    'Label/Mgmt. Aff.': { title: replaceBlankEntry(label), props: { padding: '20px 10px', }, },
                    'Artists Representing': { title: handleArtistsList(artists), props: { padding: '20px 10px', width: '20vw', }, },
                    'Website': { title: replaceBlankEntry(website), props: { padding: '20px 10px', type: 'link', } },
                    'Clock': { title: dayjs(created_at).fromNow(), props: { textAlign: 'center', padding: '20px 10px', }, },
                    'Accept': { major: 'Applicant', title: 'Accept', props: { colSpan: '2' } },
                    'Reject': { major: 'Applicant', title: 'Reject', props: { colSpan: '2' } }

                }))(buyer)
                if (index === 0) {
                    console.log("D", d)
                    const withHeader = Object.keys(d).map((it, index) => ([it, { ...d[it], headerProps: headerProps[index] }]))

                    formattedData.push({
                        data: Object.fromEntries(withHeader), id: buyer.user_id,
                        payload: {
                            userID: buyer.user_id,
                            email: buyer.email,
                            songID: null,
                            name: buyer.user_id,
                            songTitle: null,
                            user_id: buyer.user_id,
                            song_id: null,
                            song_title:null
                        }
                    })
                }
                else
                    formattedData.push({
                        data: d, id: buyer.user_id,
                        payload: {
                            userID: buyer.user_id,
                            email: buyer.email,
                            songID: null,
                            name: buyer.user_id,
                            songTitle: null,
                            user_id: buyer.user_id,
                            song_id: null,
                            song_title:null
                        }
                    })
            })

            setTableData(prev => ({ ...prev, data: formattedData }))
        } else {
            setTableData(prev => ({ ...prev, data: [] }))
        }
    }, [buyers])

    const closeSideBar = () => { secondColumnSize.current = '2fr min-content'; setSideBarDetails(prev => ({ ...prev, showSideBar: false })); }

    return {
        tableData,
        selectedSong,
        handleSelect,
        getDotMenu,
        sideBarDetails,
        selected,
        sideBarDetails,
        closeSideBar,
        secondColumnSize,
        filters,
        setFilters,
        onSearchChange,
        searchValue,
    }

}