import dayjs from 'dayjs'
import API from './API'
import RelativeTime from 'dayjs/plugin/relativeTime'

export const throttle = (func, delay) => {
    let inProgress = false;
    return (...args) => {
        if (inProgress) {
            return
        }
        inProgress = true
        setTimeout(() => {
            func(...args)
            inProgress = false;
        }, delay)
    }
}

export const updateRelTime = () => {
    dayjs.extend(RelativeTime)
    var updateLocale = require('dayjs/plugin/updateLocale')
    dayjs.extend(updateLocale)
    dayjs.updateLocale('en', {
        relativeTime: {
            future: "in %s",
            past: "%s",
            s: 'now',
            m: "1m",
            mm: "%dm",
            h: "1hr",
            hh: "%dhrs",
            d: "1d",
            dd: "%dd",
            M: "1M",
            MM: "%dMs",
            y: "a year",
            yy: "%dyears"
        }
    })
}

export const objectsEqual = (o1, o2) => {
    return Object.keys(o1).length === Object.keys(o2).length
        && Object.keys(o1).every(p => o1[p] === o2[p]);
}