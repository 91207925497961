import styled from 'styled-components';


import { NavLink as Link } from 'react-router-dom'

export const Wrapper = styled.div`
    position: absolute;
    width: 100vw;
    overflow: hidden;

    top: 0;
    z-index: 5;
    height: 100vh;
    background: rgba(0, 0, 0, .85);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Content = styled.div`
background: #000;
max-width: 426px;
width: 90%;
box-shadow: 0 0 10px 0 rgba(255,255,255,0.15);
height: 600px;
display: flex;
position: relative;
flex-direction: column;
justify-content: space-around;
align-items: center;
padding: 60px 20px;
img{
    height: 100px;
}

`;


export const FormWrapper = styled.div`
width: 100%;
position: relative;
display: flex;
flex-direction: column;
`;

export const QuestionWrapper = styled.div`
position: relative;
margin-top: ${props => props.marginTop ? props.marginTop : '20px'};
width: 100%;
`;

export const CloseButtonStyle = styled.div`
 align-self: flex-end;
    top: 15px;
    cursor: pointer;
    user-select: none;
    position: absolute;
`;

export const NavContainerStyle = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
`;

export const NavLink = styled(Link)`
text-decoration: none;
font-size: var(--fontMicro);
letter-spacing: 0.07rem;
font-weight: 600;
margin-top: 0.5rem;
color: var(--purpleGrey);
text-transform: uppercase;
align-self: flex-start;

:hover{
    color: #fff;
}

`;

export const SwitchField = styled.div`
text-decoration: none;
user-select: none;
cursor: pointer;
font-size: var(--fontMicro);
letter-spacing: 0.07rem;
font-weight: 600;
margin-top: 0.5rem;
color: var(--purpleGrey);
text-transform: uppercase;
align-self: flex-start;

:hover{
    color: #fff;
}

`;


export const LinkButton = styled(Link)`
    all: unset;
    height: ${props => props.height ? props.height : 'var(--buttonHeight)'};
    font-size: var(--buttonFontSize);
    color: ${ ({ color }) => color};
    width: ${({ width }) => width};
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    cursor: ${({ cursor }) => cursor};
    border: ${({ border }) => border};
    background: ${({ background }) => background};
    font-weight: ${({ fontWeight }) => fontWeight};
    /* border: ${props => props.validContinue ? props.border : '2px solid rgba(255,255,255,0.1)'};
    background: ${props => props.validContinue ? props.background : 'rgba(255,255,255,0.15)'}; */
    /* font-weight: ${props => props.validContinue ? '500' : '700'}; */
    letter-spacing: 0.08rem;
    :hover{
        background: ${({ background }) => background} ;

    }
`;

export const SubmitButton = styled.div`
    all: unset;
    height: ${props => props.height ? props.height : 'var(--buttonHeight)'};
    font-size: var(--buttonFontSize);
    color: ${ ({ color }) => color};
    width: ${({ width }) => width};
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    cursor: ${({ cursor }) => cursor};
    border: ${({ border }) => border};
    background: ${({ background }) => background};
    font-weight: ${({ fontWeight }) => fontWeight};
    /* border: ${props => props.validContinue ? props.border : '2px solid rgba(255,255,255,0.1)'};
    background: ${props => props.validContinue ? props.background : 'rgba(255,255,255,0.15)'}; */
    /* font-weight: ${props => props.validContinue ? '500' : '700'}; */
    letter-spacing: 0.08rem;
    :hover{
        background: ${({ background }) => background} ;

    }
`;

export const Error = styled.div`
font-size: 10px;
max-width: 100%;
user-select: none;
`;

export const PwdReset = styled.div`
text-decoration: none;
user-select: none;
cursor: pointer;
font-size: var(--fontMicro);
letter-spacing: 0.07rem;
font-weight: 600;
margin-top: 0.5rem;
color: var(--purpleGrey);
text-transform: uppercase;
align-self: flex-start;

:hover{
    color: #fff;
}
`;