import React, { useState, useEffect, useRef } from 'react'
import Tag from '../Tag'
import Flexbox from '../Flexbox'
import InputField from '../InputField'
import Dropdown from '../Dropdown'
import SearchTermHints from '../SearchTermHints'


import { InnerContainer, TagItem, TagsInputContainer, Input } from './SuggestSearch.styles'

const PartitionedSearchDropdown = ({ isFooter = false, searchPartitions, selectedPartition, listMarginBottom }) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const [value, setValue] = useState(selectedPartition)

    return (
        <Flexbox position="absolute" width="100px" whiteSpace="nowrap" alignSelf="end" zIndex="4">
            <Flexbox height="25px" background="rgba(255,255,255,0.5)" width="1px" relative></Flexbox>
            <Dropdown

                listMarginBottom={listMarginBottom}
                flexDirection="column-reverse"
                showArrowDropdown={false}
                setMenuState={() => setShowDropdown(!showDropdown)}
                _handleChange={() => setShowDropdown(!showDropdown)}
                handleStateChange={() => setShowDropdown(!showDropdown)}
                showHeader={true}
                itemHeight="45px"
                headerHeight="45px"
                options={searchPartitions} menuState={showDropdown} currSelection={searchPartitions.find(it => it.id === selectedPartition).innerText} /></Flexbox>)
}

const SuggestSearch = ({
    validatorAddItem,
    validatorRemoveItem,
    partitionedSearch = false,
    searchPartitions = [],
    listMarginBottom,
    selectedPartition,
    onSearchReset,
    triggerReset,
    placeHolder = 'Enter Tag', maxTerms = -1,
    multiTermEnabled = false,
    data,
    onChange,
    getSuggestions,
    isFooter,
    maxWidth,
    minHeight,
    height,
    margin,
    padding }) => {
    // update if list length is zero still
    const parentContainer = useRef(null)
    const dropdownRef = useRef(null)
    const initial = useRef(true)
    const parsingMenu = useRef(false)
    const [items, setItems] = useState(data.map(it => ({ id: it, value: it })))
    const [dropdownSelection, setDropdownSelection] = useState(null)
    const [suggestions, setSuggestions] = useState([])

    useEffect(() => {
        if (items) {
            if (initial.current) {
                initial.current = false
                return
            }
            console.log("item change")
            onChange(items)
        }
    }, [items])
    console.log("isFooterisFooterisFooter", isFooter)
    useEffect(() => {
        if (data) {
            initial.current = true
            console.log("DATA CHANGED DATA CHANGED DATA CHANGED DATA CHANGED DATA CHANGED DATA CHANGED DATA CHANGED DATA CHANGED DATA CHANGED DATA CHANGED DATA CHANGED DATA CHANGED DATA CHANGED DATA CHANGED DATA CHANGED DATA CHANGED ")
            setItems(data.map(it => ({ id: it, value: it })))
            setSuggestions([])
            setInput('')

        }
    }, [data])

    const [input, setInput] = useState('')

    const shouldExecuteSearch = () => input.length > 0 && !parsingMenu.current

    const shouldResetSearchSuggestions = () => !parsingMenu.current || input.length === 0

    const _validatorAddItem = (trimmedInput, items) => {
        if (typeof (validatorAddItem) === 'function')
            return validatorAddItem(trimmedInput, items)
        return !items.find(el => el.id === trimmedInput) && trimmedInput.length
    }

    const _validatorRemoveItem = (trimmedInput, items) => {
        if (typeof (validatorRemoveItem) === 'function')
            return validatorRemoveItem(trimmedInput, items)
        return !items.find(el => el.id === trimmedInput) && trimmedInput.length
    }

    const resetSearchResults = () => {
        setSuggestions([])
        setShowDropdown(false)
        setDropdownSelection(-1)
    }

    const onBlurProps = {
        outline: 'none',
        boxShadow: 'inset 0 0 5px rgb(0, 0, 0)'
    }
    const onFocusProps = {
        outline: '1px solid white',
        boxShadow: 'inset 0 0 5px rgba(255, 255, 255, .5)'
    }
    const [focusProperties, setFocusProperties] = useState(onBlurProps)
    const [isKeyReleased, setIsKeyReleased] = useState(false);

    const handleLongTermsList = () => {
        if (parentContainer.current) {
            const { scrollWidth, offsetWidth } = parentContainer.current
            if (scrollWidth > offsetWidth)
                parentContainer.current.scrollLeft = parentContainer.current.scrollLeftMax

        }
    }

    const onKeyDown = (e) => {

        const { key } = e;
        const trimmedInput = input.trim();

        if ((key === 'ArrowDown' || key === 'ArrowUp')) {
            toggleThroughMenu(key === 'ArrowUp' ? -1 : 1)

        } else {
            parsingMenu.current = false
        }
        if ((key === ',' || key === 'Enter') && _validatorAddItem(trimmedInput, items)) {
            e.preventDefault();
            setItems(prevState => [...prevState, { value: trimmedInput, id: trimmedInput }].slice(0, maxTerms > 0 ? maxTerms : [...prevState, trimmedInput].length));
            setInput('');
            setSuggestions([])
        }

        if (key === "Backspace" && isKeyReleased && _validatorRemoveItem(trimmedInput, items)) {
            const itemsCopy = [...items];
            const poppedTag = itemsCopy.pop();
            e.preventDefault();
            setItems(itemsCopy.slice(0, maxTerms > 0 ? maxTerms : itemsCopy.length));
            setInput(poppedTag || '');
            setSuggestions([])
        }

        setIsKeyReleased(false);
        handleLongTermsList()
    };

    const toggleThroughMenu = (dir) => {
        if (suggestions.length > 0) {
            const _suggestionsNoKeys = suggestions.filter(it => !it.isKey)
            let val = _suggestionsNoKeys.findIndex(it => it.innerText === dropdownSelection) + dir
            val =
                val >= _suggestionsNoKeys.length ? 0 :
                    val < 0 ? _suggestionsNoKeys.length - 1 :
                        val
            onToggleThroughMenu(_suggestionsNoKeys[val].innerText, val)
        }
    }


    const onToggleThroughMenu = (value, index) => {
        parsingMenu.current = true
        setInput(value)
        setDropdownSelection(value)
    }

    const onKeyUp = () => {
        setIsKeyReleased(true);
    }

    const { outline, boxShadow } = focusProperties
    const [showDropdown, setShowDropdown] = useState(false)

    return (
        <>
            <Flexbox flexDirection={isFooter ? 'column-reverse' : 'column'} position="relative" padding="1px" height={height} >
                <TagsInputContainer zIndex="3"  outline={outline} boxShadow={boxShadow} maxWidth={maxWidth} height={height} margin={margin} padding={padding}>
                    <InnerContainer ref={parentContainer} minHeight={minHeight} height={height}>
                        {items.map((tag, index) => (
                            <Tag
                                margin="1px"
                                color={'#fff'}
                                key={tag.id}
                                onClick={() => { setItems(prev => ([...prev.filter(it => it.value !== tag.value)])) }} name={tag.value} />)
                        )}
                        {(multiTermEnabled || items.length === 0) &&
                            <InputField

                                onBlur={() => setFocusProperties(onBlurProps)}
                                background="none"
                                height={height}
                                onFocus={() => { setFocusProperties(onFocusProps) }}
                                boxShadow="none"
                                outline="none"
                                padding={partitionedSearch ? "0 100px 0 20px" : "0 20px"}
                                boxShadowFocus="none"
                                minWidth="200px"
                                type="text" placeholder={placeHolder} onKeyDown={onKeyDown} onKeyUp={onKeyUp} value={input} onChange={(e) => setInput(e.target.value)} />}

                    </InnerContainer>

                </TagsInputContainer>
                {
                    partitionedSearch && <PartitionedSearchDropdown searchPartitions={searchPartitions} selectedPartition={selectedPartition} listMarginBottom={listMarginBottom} />
                }
                <SearchTermHints
                    isFooter={isFooter}
                    dropdownRef={dropdownRef}
                    trigger={input}
                    getAndFormatSearchResults={async () => await getSuggestions(input, items, setItems, setInput)}
                    currSelection={dropdownSelection}
                    suggestions={suggestions}
                    setSuggestions={setSuggestions}
                    setDropdownSelection={setDropdownSelection}
                    shouldResetSearchSuggestions={shouldResetSearchSuggestions}
                    resetSearchResults={resetSearchResults}
                    shouldExecuteSearch={shouldExecuteSearch}
                    height={height}
                    minHeight={minHeight} />
            </Flexbox>
        </>
    )
}

export default SuggestSearch