import styled from "styled-components";

export const Wrapper = styled.div`
width: 100%;
position: relative;
display: flex;
align-items: center;
padding-bottom:1px;
`;

export const Button = styled.div`
height: 100%;
position: absolute;
width: 30px;
right: 10px;
display: flex;
align-items: center;
justify-content: center;
`;