import React, { useState, useEffect, useRef } from 'react'

import Flexbox from '../Flexbox'
import Dropdown from '../Dropdown'

const SearchTermHints = ({
    dropdownRef,
    setSuggestions,
    isFooter = false,
    suggestions = [], //array of objects { innerText, value, onClick }
    trigger,
    shouldExecuteSearch,
    shouldResetSearchSuggestions,
    currSelection,
    resetSearchResults,
    onToggleThroughMenu,
    dropdownSelection,
    setDropdownSelection,
    getAndFormatSearchResults,
    height,
    minHeight
}) => {

    const [showDropdown, setShowDropdown] = useState(false)
    // const [suggestions, setSuggestions] = useState([])
    const [selected, setSelected] = useState(null)
    const initial = useRef(true)

    const _resetSearchResults = () => {
        setSuggestions([])
        setDropdownSelection(null)
        setShowDropdown(false)
    }

    const searchFunction = async () => {
        const res = await getAndFormatSearchResults()
        if (res && res.length > 0) {
            setSuggestions(res)
            setDropdownSelection(null)
            setShowDropdown(true)
        } else {
            setSuggestions([])
            setDropdownSelection(null)
            setShowDropdown(false)
        }
    }


    useEffect(() => {
        if (initial.current) {
            // note we can mutate this directly instead of using a "setStateXyz"
            // function because it's a useRef
            initial.current = false;
            return; // if initial render, skip useEffect
        }

        if (shouldExecuteSearch()) {
            const timer = setTimeout(async () => {
                await searchFunction()
            }, 500) // triggers after .5 s

            // triggers this before a new render
            return () => clearTimeout(timer)
        } else if (shouldResetSearchSuggestions()) {
            if (typeof (resetSearchResults) === 'function')
                resetSearchResults()
            // _resetSearchResults()
        }

    }, [trigger])

    return (
        <>
            {suggestions.length > 0 &&
                <Flexbox position={! isFooter ? 'relative' : 'absolute'} width="100%" height={height} minHeight={minHeight} flexDirection={isFooter ? 'column-reverse' : undefined}>
                    <Dropdown
                        dropdownRef={dropdownRef}
                        listMarginBottom={isFooter ? "10px" : undefined}
                        flexDirection={isFooter ? 'column-reverse' : undefined}
                        label='Tempo'
                        headerHeight="49px"
                        itemHeight="49px"
                        dropdownListMarginTop="5px"
                        showHeader={false}
                        name={'Suggested Tags'}
                        marginBottom={'1rem'}
                        options={suggestions}
                        menuState={showDropdown}
                        handleStateChange={() => setShowDropdown(!showDropdown)}
                        // currSelection={currSelection >= 0 ? suggestions[currSelection].innerText : null}
                        currSelection={currSelection ? currSelection : null}
                        valueX={[]}
                        id={'suggested-tags'}
                        setValidContinue={() => { }}
                        justifyContent="start"
                        textAlign="left"
                        letterSpacing="0.1rem"
                        fontSize="var(--fontSmall)"
                        fontWeight="700"
                        boxShadow='0 0 2px #000'
                        background="#000"
                        width="100%"
                    ></Dropdown>
                </Flexbox>
            }
        </>
    )
}

export default SearchTermHints