import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Table from '../Table2'
import CompletedTransaction from '../CompletedTransaction'
import Flexbox from '../Flexbox'
import TableCell from '../TableCell'
import TableRow from '../TableRow'
import GridItem from '../GridItem'
import DotMenu from '../DotMenu'
import Payout from '../Payout'
import SearchBarFooter from '../SearchBarFooter'
import DetailsSideBar from '../DetailsSideBar'
import dayjs from 'dayjs'

import { useTransactionHook } from '../../hooks/useTransactionHook'

import { throttle } from '../../utils'

import { TransactionsWrapper } from './PayoutPendingTransactions.styles'
const RowItem = ({ c,
    selectedSong,
    getDotMenu,
    handleSelect,
    showSideBar,
    selected,
    highlight }) => {

    const dotMenuRef = useRef(null)
    const [hover, setHover] = useState(false)
    console.log("c.data", c.data)


    return (
        <TableRow content={c.data} selectedSong={selectedSong} key={c.song_id} handleSelect={() => handleSelect(c)}
            onMouseEnter={() => { setHover(true) }}
            onMouseLeave={() => { setHover(false) }}
            onClick={(e) => {

                // if (e.target !== dotMenuRef.current && e.target !== dotMenuRefWrapper.current && !e.target.classList.contains('dotmenu') && e.target.classList.contains('row')) {
                //     console.log("TRIGGERED BY", e, e.target)
                //     onCheck(column.id, song.id, e)
                // }
            }}
            background={highlight ? 'var(--yellow) !important' : undefined}
            color={highlight ? '#000 !important' : undefined}
        >
            <TableCell props={{ padding: '0', width: '50px' }}>
                <GridItem position="relative" width='50px'>
                    <DotMenu
                        _options={getDotMenu(c)}
                        right="0"
                        hoverActive={hover}
                        bottom="10px"
                        _ref={dotMenuRef}
                        selected={selected}
                        fill={highlight ? "#000000" : "#ffffff"}
                        status="1" />
                </GridItem>
            </TableCell>
        </TableRow>)
}

const PayoutPendingTransactions = ({ songs, type }) => {
    const location = useLocation()
    const { songID } = useParams()
    const props = useTransactionHook({ songs, type })
    const { tableData,
        selectedSong,
        getDotMenu,
        handleSelect,
        showSideBar,
        closeSideBar,
        secondColumnSize,
        selected,
        sideBarDetails,
        filters,
        setFilters,
        onSearchChange,
        searchValue,
    } = props
    const { data, header, subHeader } = tableData

    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const { content, sidebarlinks, setContent, setPage, setFilter, pullSongs, pullBuyers } = props
    console.log("sideBarDetails", sideBarDetails)

    useEffect(() => {
        let isMounted = true;
        const handleResize = throttle(() => {
            if (isMounted) {
                setWindowSize(window.innerWidth)
            }
        }, 1000)
        window.addEventListener("resize", handleResize);
        return () => {
            isMounted = false
            window.removeEventListener("resize", handleResize)
        };
    }, [])
    if (data.length > 0)
        return (
            <Flexbox column height="100%" overflow="hidden">
                <TransactionsWrapper
                    overflow="hidden visible"
                    alignItems="start"
                    position="relative"
                    gridTempCols="2fr min-content"
                    gridTempRows="1fr min-content"
                    height="100%"
                    justifyContent="start"
                    width="100%"
                    flexDirection="row"
                    flexGrow="1"
                    // display="grid" 
                    style={{ flexGrow: '1' }}>

                    <GridItem column overflow="hidden auto" width="100%" height="100%" flexShrink="3" justifyContent="start" alignItems="start" padding="50px 30px" background="#1c1c1c">
                        <h3 >{'Transactions'.toUpperCase()}</h3>
                        <p style={{ color: '#555555', fontWeight: '500' }}>{dayjs().format('ddd, MMMM D, YYYY')}</p>
                        <div style={{ borderTop: '2px solid rgba(255,255,255,0.15)', width: "100%" }}></div>
                        <Table oddHighlight>
                            <TableRow fontSize="11px" className="tableHeader row" >
                                {
                                    Object.keys(data[0].data).reduce((res, el, index) => {
                                        const props = data[0].data[el].headerProps
                                        const _data = data[0].data[el]
                                        const title = Object.keys(_data).includes('major') ? _data.major : el
                                        if (!res.find(it => it.title === title)) {
                                            const trueSpan = Object.keys(data[0].data).reduce((a, b) => {
                                                const _data = data[0].data[b]

                                                if (!Object.keys(_data).includes('major'))
                                                    return a
                                                if (_data.major !== title)
                                                    return a
                                                console.log(a, b, _data.major, title, Object.keys(_data))
                                                a = a + 1;
                                                return a
                                            }, 0)
                                            console.log(trueSpan)
                                            res.push({
                                                title: title, el:
                                                    <TableCell key={index} isHeader className="tableHeader" id={index} content={{ title: title, props: { ...props, width: props.width ? props.width : 'auto', colSpan: trueSpan !== 0 ? trueSpan : 1, padding: props.padding ? props.padding : '0' } }}></TableCell>
                                            })
                                        }
                                        return res
                                    }
                                        , []).map(it => it.el)
                                }

                            </TableRow>
                            <TableRow className="tableHeader row" fontSize="12px">
                                {/* {
                                subHeader.map((it, index) => <TableCell key={index} isHeader fontSize="10px" className="tableHeader" id={index} content={{ ...it, props: { padding: '0' } }}></TableCell>)
                            } */}
                                {
                                    Object.keys(data[0].data).reduce((res, el, index) => {
                                        const props = data[0].data[el].headerProps
                                        const _data = data[0].data[el]
                                        const title = Object.keys(_data).includes('major') ? el : undefined
                                        if (title !== undefined && !res.find(it => it.title === title)) {

                                            res.push({
                                                title: title, el: <TableCell key={index} isHeader className="tableHeader" id={index} content={{
                                                    title: title,
                                                    props: { ...props.minorProps, width: props.minorProps.width ? props.minorProps.width : 'auto', },
                                                }}></TableCell>
                                            })
                                        }
                                        else res.push({ el: <TableCell key={index} isHeader className="tableHeader" id={index} content={{ props: { padding: '0' } }}></TableCell> })
                                        return res
                                    }
                                        , []).map(it => it.el)
                                }
                            </TableRow>
                            {
                                data.map((c) => (
                                    <RowItem key={c.id} c={c} {...props} highlight={selected.length && selected[0] === c.id && sideBarDetails.showSideBar ? true : undefined} />
                                ))
                            }
                        </Table>
                    </GridItem>
                    <DetailsSideBar
                        left={windowSize < 1250 && !sideBarDetails.showSideBar ? 'calc(100vw)' : '0'}
                        flexGrow="5"
                        width="600px"
                        position={windowSize < 1250 ? 'absolute' : undefined}
                        // margin={windowSize < 1500 ? '0 calc(-100vw) 0 0' : '0 calc(-100vw) 0 0'}
                        zIndex="5"
                        modalDetails={sideBarDetails}
                        modalType={sideBarDetails.sideBarType}
                        showModal={sideBarDetails.showSideBar}
                        closeModal={closeSideBar} />

                </TransactionsWrapper>
                {/* <SearchBarFooter showBar={!sideBarDetails.showSideBar} searchValue={searchValue} onSearchChange={onSearchChange} setFilters={setFilters} filters={filters} /> */}
            </Flexbox>
        )
    else return <></>
}

export default PayoutPendingTransactions