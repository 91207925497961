import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import API from '../API';
import { useAuth } from '../contexts/AuthContext'
import { updateRelTime } from '../utils'
import dayjs from 'dayjs'
import { useNotifications } from './useNotifications'
import { shortFormatPrice, showTrailing, calculatePriceWithFee, calculateCut } from '../helpers'

import agreedIcon from '../images/agreedIcon.svg'
import notAccepted from '../images/close.svg'

import ToolTip from '../components/ToolTip'
import Flexbox from '../components/Flexbox'

export const getCompletionStatus = (payoutComplete = undefined, stemTransfer = undefined, index = 0, justifyContent = 'center') => {
    if (payoutComplete === undefined) {

        return stemTransfer[index].updated_at ?
            <div
            >{dayjs.unix(stemTransfer[index].updated_at).format('MMM D, YYYY')}</div > : <img src={notAccepted} style={{ height: '15px', width: '15px' }} />
        // <ToolTip justifyContent={justifyContent}
        //     boxShadow="0 0 10px rgb(0,0,0)"
        //     background="#000000"
        //     bottom="20px"
        //     border="#000000"
        //     absolute
        //     toolTipText={`Confirmed ${dayjs.unix(stemTransfer[index].updated_at).format('M/D/YY')}`}>
        //     <img src={agreedIcon} style={{ width: '15px', height: '15px' }} /></ToolTip > : <img src={notAccepted} style={{ height: '15px', width: '15px' }} />
    }
    else if (!stemTransfer[0].updated_at || !stemTransfer[1].updated_at)
        return '-'
    else return payoutComplete === '1' ? <img src={agreedIcon} style={{ height: '15px', width: '15px' }} /> : <img src={notAccepted} style={{ height: '15px', width: '15px' }} />
}

export const getMostRecentAgreementDate = (stemTransfer) => {
    if (!stemTransfer.length)
        return '-'
    return Math.max(...stemTransfer.map(it => it.updated_at))

}

export const useTransactionHook = (args) => {
    const { songs, type } = args
    const [tableData, setTableData] = useState({
        data: [],

    })
    updateRelTime()
    const { currentUser } = useAuth()
    const location = useLocation()
    const navigate = useNavigate()


    const [selected, setSelected] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const onSearchChange = (input) => {
        // setSearchValue(e.target.value)
        setSearchValue(input)
    }
    const [filters, setFilters] = useState([])
    const [loading, setLoading] = useState(true)
    const secondColumnSize = useRef('2fr min-content')
    const [sideBarDetails, setSideBarDetails] = useState({
        showSideBar: false,
        sideBarType: 1,
        fullSideBar: false,
        data: {},
        title: '',
        songTitle: '',
        buttonText: ''

    })
    const [sideBarType, setSideBarType] = useState(false)
    const selectedSong = useRef(null)
    const handleSelect = (c) => {
        selectedSong.current = c
    }
    const payload = useRef({
        songId: '',
        category: null
    })
    const songDetails = {
        options: {
            'option-1': {
                id: 'songwriter-details',
                title: 'Songwriter Details',
                onClick: async (songId) => { return await API.fetchUserBySongId(currentUser, songId) },
            },
            'option-2': {
                id: 'song-details',
                title: 'Song Details',
                onClick: async (songId) => { return await API.fetchSong(currentUser, songId) },

            },
            'option-3': {
                id: 'buyer-details',
                title: 'Buyer Details',
                onClick: async (songId) => { return await API.fetchBuyerBySongId(currentUser, songId) },
            },
            'option-4': {
                id: 'message-songwriter',
                title: 'Message Songwriter',
                onClick: async (songId) => {
                    const res = await API.fetchSong(currentUser, songId)

                    return { ...res, receiver_id: res.primary_songwriter_id, buttonText: 'Message Songwriter' }
                },
            },
            'option-5': {
                id: 'message-buyer',
                title: 'Message Buyer',
                onClick: async (songId) => {
                    const res = await API.fetchSong(currentUser, songId)
                    return { ...res, receiver_id: res.buyer_user_id, buttonText: 'Message Buyer' }
                },
            }
        }
    }

    const getDotMenu = (song) => {
        return [
            {
                value: 1, innerText: 'Payout', onClick: () => {
                    // setSideBar(column.id, song.id)
                    navigate('/transactions/payout/' + song.id)
                }
            },
            {
                value: 2, innerText: 'Song Details', onClick: () => {
                    setSideBar('song-details', song.id)
                }
            },
            {
                value: 3, innerText: 'Songwriter Details', onClick: () => {
                    setSideBar('songwriter-details', song.id)
                }
            },
            {
                value: 4, innerText: 'Buyer Details', onClick: () => {
                    setSideBar('buyer-details', song.id)
                }
            },
            {
                value: 5, innerText: 'Message Songwriter', onClick: () => {
                    setSideBar('message-songwriter', song.id)
                }
            },
            {
                value: 6, innerText: 'Message Buyer', onClick: () => {
                    setSideBar('message-buyer', song.id)
                }
            },
        ]
    }

    const mapSongDetails = (_sideBarId, songId) => {
        const typeId = Object.keys(songDetails.options).find(id => {
            const _option = songDetails.options[id]
            return _option.id === _sideBarId
        })
        return songDetails.options[typeId]
    }

    const setSideBar = async (_sideBarId, songId) => {
        payload.current = { ...payload.current, songId: songId }
        setSelected([songId])
        // if (!needsFullModal(_sideBarId)) {
        //     setModalDetails(prev => ({ ...prev, modalType: _sideBarId, showModal: true, fullModal: needsFullModal(_sideBarId) }))
        //     setButtonActions(mapButtonToData(_sideBarId))
        // }
        // else {
        console.log("setting sidebar")
        const _detailsType = mapSongDetails(_sideBarId, songId)
        const res = await _detailsType.onClick(songId)
        const _selectedSongDetails = songs.find(it => parseInt(it.song_id) === parseInt(songId)).song_title
        if (res) {
            secondColumnSize.current = '2fr minmax(500px, 1fr)'
            setSideBarDetails(prev => ({ ...prev, sideBarType: _sideBarId, showSideBar: true, fullSideBar: true, title: _detailsType.title, data: { ...res }, songTitle: _selectedSongDetails }))
            // setButton(prev => ({ ...prev, fullSideBar: true }))

        }
        // }
    }
    useEffect(() => {
        if (songs) {
            console.log("the songs are", songs)
            const formattedData = []
            // const headerProps = [
            //     {
            //         verticalAlign: 'top', padding: '20px 10px 0', minWidth: '', height: '', whiteSpace: '', textAlign: ''
            //     },
            //     { background: '#002b45', color: '#0079C1', colSpan: '3', textAlign: 'center', minorProps: { background: '#002b45', color: '#0079C1', padding: '0 10px 5px' } },
            //     // {colSpan: '3', textAlign: 'center', minorProps: { color: '#545454', textAlign: 'center', width: '127px', padding: '0 10px 5px' }},
            //     { colSpan: '3', textAlign: 'center', minorProps: { background: '#002b45', color: '#0079C1', textAlign: 'center', padding: '0 10px 5px' } },
            //     // {colSpan: '3', textAlign: 'center', minorProps: { color: '#545454', textAlign: 'center', width: '127px', padding: '0 10px 5px' }},
            //     { background: '#002b45', color: '#0079C1', colSpan: '3', textAlign: 'center', minorProps: { background: '#002b45', color: '#0079C1', textAlign: 'center', padding: '0 10px 5px' } },
            //     { width: 'min-content', verticalAlign: 'top', padding: '20px 10px 0', minWidth: '', height: '', textAlign: 'center', minorProps: { width: 'min-content', background: '#002b45', color: '#0079C1', textAlign: 'center', padding: '0 10px 5px' } },
            //     { colSpan: '2', textAlign: 'center', minorProps: { color: '#545454', textAlign: 'center', padding: '0 10px 5px' } },
            //     { colSpan: '2', textAlign: 'center', minorProps: { color: '#545454', textAlign: 'center', padding: '0 10px 5px' } },
            //     {
            //         textAlign: 'center', verticalAlign: 'top', padding: '20px 10px 0', textAlign: 'center', minWidth: '', height: '',
            //         minorProps: { color: '#545454', textAlign: 'center', padding: '0 10px 5px' }
            //     },
            //     { textAlign: 'center', verticalAlign: 'top', padding: '20px 10px 0', textAlign: 'center', minWidth: '', height: '' },
            //     { textAlign: 'center', verticalAlign: 'top', padding: '20px 10px 0', whiteSpace: 'nowrap', textAlign: 'center', minWidth: '', height: '' },
            // ]
            const headerProps = [
                {
                    verticalAlign: 'top', padding: '20px 10px 0', minWidth: '', height: '', whiteSpace: '', textAlign: ''
                },
                { colSpan: '2', textAlign: 'center', minorProps: { color: '#545454', textAlign: 'center', padding: '0 10px 5px' } },
                { colSpan: '2', textAlign: 'center', minorProps: { color: '#545454', textAlign: 'center', padding: '0 10px 5px' } },
                {
                    textAlign: 'center', verticalAlign: 'top', padding: '20px 10px 0', textAlign: 'center', minWidth: '', height: '',
                    minorProps: { color: '#545454', textAlign: 'center', padding: '0 10px 5px' }
                },
                { textAlign: 'center', verticalAlign: 'top', padding: '20px 10px 0', textAlign: 'center', minWidth: '', height: '' },
                { textAlign: 'center', verticalAlign: 'top', padding: '20px 10px 0', whiteSpace: 'nowrap', textAlign: 'center', minWidth: '', height: '' },
            ]
            const getAdditionalInfo = ({ payoutComplete = null, payoutDate = null, stemTransfer }) => {
                if (type === 'Completed')
                    return ({
                        'Admin Payout': { width: 'auto', title: getCompletionStatus(payoutComplete, stemTransfer), props: { padding: '20px 10px', textAlign: 'center' }, },
                        'Date of Payout': { width: 'auto', title: payoutDate ? dayjs.unix(payoutDate).format('M/D/YY') : '-', props: { padding: '20px 10px', textAlign: 'center' }, },
                    })
                else return
            }
            // songs.forEach((song, index) => {
            //     console.log("the song is", song)
            //     const d = (({ song_title, buyerOrderID, buyerTransactionID, buyerInvoiceID, buyerPaypalPayerID, buyerPaypalPayerEmail, songwriter_email, buyer_email, stemTransfer, price, dateSold, payoutComplete, payoutDate }) =>
            //     ({
            //         'Song Title': { title: song_title, props: { padding: '20px 10px', }, },
            //         'Paypal Email': {
            //             major: 'Buyer Paypal Transaction Info', title: buyerPaypalPayerEmail,
            //             props: { width: 'auto', fontWeight: '700', background: "#0079C1", outlineOffset: '0', outline: '1px solid #00457C', padding: '20px 10px', color: '#00457C', },
            //         },
            //         'Order Id': {
            //             major: 'Buyer Paypal Transaction Info', title: buyerOrderID,
            //             props: { width: 'auto', padding: '20px 10px', fontWeight: '700', background: "#0079C1", outlineOffset: '0', outline: '1px solid #00457C', color: '#00457C', textAlign: 'center', },
            //         },
            //         // 'Transaction ID': { major: 'Buyer Paypal Transaction Info', title: buyerTransactionID, props: {textAlign: 'center', }, },
            //         'Invoice ID': {
            //             major: 'Buyer Paypal Transaction Info', title: buyerInvoiceID,
            //             props: { width: 'auto', padding: '20px 10px', fontWeight: '700', background: "#0079C1", outlineOffset: '0', outline: '1px solid #00457C', color: '#00457C', textAlign: 'center', },
            //         },
            //         'Date of Purchase': {
            //             major: 'Buyer Paypal Transaction Info', width: 'min-content', title: dayjs.unix(dateSold).format('M/D/YY'),
            //             props: { fontWeight: '700', background: "#0079C1", outlineOffset: '0', outline: '1px solid #00457C', padding: '20px 10px', color: '#00457C', textAlign: 'center', },
            //         },
            //         // 'Payer ID': { major: 'Buyer Paypal Transaction Info', title: buyerPaypalPayerID, props: {textAlign: 'center', }, },
            //         'Songwriter': { major: 'Deliverables Confirmation', width: 'auto', title: getCompletionStatus(undefined, stemTransfer, 0), props: { padding: '20px 10px', textAlign: 'center' }, },
            //         'Buyer': { major: 'Deliverables Confirmation', width: 'auto', title: getCompletionStatus(undefined, stemTransfer, 1), props: { padding: '20px 10px', textAlign: 'center' }, },
            //         'Inc. Fee': { major: 'Price', width: 'auto', title: calculatePriceWithFee(price).total, props: { padding: '20px 10px', textAlign: 'center' }, },
            //         ...getAdditionalInfo(payoutComplete, payoutDate, stemTransfer)

            //     }))(song)

            songs.forEach((song, index) => {
                console.log("the song is", song)
                const d = (({ song_title, buyerOrderID, buyerTransactionID, buyerInvoiceID, buyerPaypalPayerID, buyerPaypalPayerEmail, songwriter_email, buyer_email, stemTransfer, price, dateSold, payoutComplete, payoutDate }) =>
                ({
                    'Song Title': { title: song_title, props: { padding: '20px 10px' }, },
                    // 'Payer ID': { major: 'Buyer Paypal Transaction Info', title: buyerPaypalPayerID, props: {textAlign: 'center', }, },
                    'Songwriter': { major: 'Deliverables Confirmation', width: 'auto', title: getCompletionStatus(undefined, stemTransfer, 0), props: { padding: '20px 10px', textAlign: 'center' }, },
                    'Buyer': { major: 'Deliverables Confirmation', width: 'auto', title: getCompletionStatus(undefined, stemTransfer, 1), props: { padding: '20px 10px', textAlign: 'center' }, },
                    'Inc. Fee': { major: 'Price', width: 'auto', title: calculatePriceWithFee(price).total, props: { padding: '20px 10px', textAlign: 'center' }, },
                    ...getAdditionalInfo(payoutComplete, payoutDate, stemTransfer)

                }))(song)
                if (index === 0) {
                    const withHeader = Object.keys(d).map((it, index) => ([it, { ...d[it], headerProps: headerProps[index] }]))

                    formattedData.push({ data: Object.fromEntries(withHeader), id: song.song_id })
                }
                else
                    formattedData.push({ data: d, id: song.song_id })
            })

            setTableData(prev => ({ ...prev, data: formattedData }))
        } else {
            setTableData(prev => ({ ...prev, data: [] }))
        }
    }, [songs])

    const closeSideBar = () => { secondColumnSize.current = '2fr min-content'; setSideBarDetails(prev => ({ ...prev, showSideBar: false })); }

    return {
        tableData,
        selectedSong,
        handleSelect,
        getDotMenu,
        sideBarDetails,
        selected,
        sideBarDetails,
        closeSideBar,
        secondColumnSize,
        filters,
        setFilters,
        onSearchChange,
        searchValue,
    }

}