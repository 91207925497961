import React, { useState, useEffect } from 'react';

import Button from '../../Button'
import Modal from '../../Modal'
import GridItem from '../../GridItem'
import Flexbox from '../../Flexbox'
import TextArea from '../../TextArea'
import InputField from '../../InputField'
import SocialIcon from '../../SocialIcon'
import ToolTip from '../../ToolTip'

import TableCell2 from '../../TableCell'
import TableRow2 from '../../TableRow'
import Table2 from '../../Table2'

import dayjs from 'dayjs'
import NavArrow from '../../../images/formNavArrow.svg'
import { shortFormatPrice, showTrailing, calculatePriceWithFee, calculateCut } from '../../../helpers'

import { ToolTipItem, PrimaryDetails, NavArrowImg, EmailLink, TableCell, TableRow, Table } from './Top100Modal.styles'

import { useAuth } from '../../../contexts/AuthContext'

import clock from '../../../images/clockIcon.svg'
import timeElapsed from '../../../images/timeElapsed.svg'
import ExpandIcon from '../../../images/expandIcon.svg'
import favoritesIcon from '../../../images/favoriteIcon.svg'
import agreedIcon from '../../../images/agreedIcon.svg'
import notActive from '../../../images/close.svg'



const getConciergeStatus = (useConcierge) => {
    return <img src={useConcierge === '0' ? notActive : agreedIcon} style={{ width: '15px', height: '15px' }} />
}

const MessageModal = ({ modalType, modalDetails, data, loading, error, setError, setLoading }) => {
    const { currentUser } = useAuth()
    const [messageInput, setMessageInput] = useState({
        song_id: '',
        songwriter_id: '',
        sender_id: '',
        receiver_id: '',
        message: modalDetails.defaultMessage
    });

    const handleChange = (event) => {
        setMessageInput(prev => ({ ...prev, message: event.target.value }))
    }
    const continueKey = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            sendMessage().then(() => { setMessageInput((prev) => ({ ...prev, message: "" })) });
        }
    }

    const sendMessage = async () => {
        if (messageInput.message.replace(/\s/g, '').length) {
            try {
                setError(false);
                setLoading(true);
                // const res = await modalDetails.onClick(currentUser, data.song_id, messageInput.message);

            } catch (error) {
                setError(true);

            }
            setLoading(false);
        }

    };

    useEffect(() => {
        if (data && data.user_id && data.song_id) {
            setMessageInput(prev => ({
                ...prev,
                song_id: data.songId,
                songwriter_id: modalType,
                sender_id: '1',
                receiver_id: '1',
                message: ''
            }))
        }
    }, [data, modalDetails])
    return (
        <Flexbox column gap="20px">
            <TextArea margin="0" padding="20px" height="100px" onChange={handleChange} value={messageInput.message} placeholder={`Enter Message`} />
            <Button onClick={async () => { await sendMessage() }} text={data.buttonText} width="100%" border="1px solid white" background="transparent" />
        </Flexbox>
    )
}

const TableHeader = ({ isTop100, modalType }) => {
    const toolTipText = isTop100 ? 'Time spent on Top 100 in this spot.' : 'Time elapsed since song was listed for sale.'
    if (modalType === 'song-details')
        return (
            <TableRow className="tableHeader">
                <TableCell width="min-content" className="tableHeader"></TableCell>
                {/* <TableCell className="tableHeader">Name</TableCell> */}
                <TableCell width="max-content">Paypal Email</TableCell>
                <TableCell width="min-content">Split [%]</TableCell>
                <TableCell width="min-content">Split [$]</TableCell>
            </TableRow>
        )
    else if (modalType === 'buyer-details-purchases')
        return (
            <>
                <TableRow2 className="tableHeader">
                    <TableCell2 isHeader props={{ padding: '20px 10px 0' }}>Title</TableCell2>
                    <TableCell2 isHeader width="min-content" props={{ padding: '20px 10px 0' }}>Price</TableCell2>
                    <TableCell2 isHeader width="min-content" props={{ colSpan: '2', padding: '20px 10px 0', textAlign: 'center' }}>Deliverables</TableCell2>
                    <TableCell2 isHeader width="min-content" props={{ padding: '20px 10px 0' }}><ToolTipItem right="0"><img src={clock} alt="clockIcon" style={{ width: "15px" }} /><div className="tooltip" title="t">Date Purchased</div></ToolTipItem></TableCell2>
                </TableRow2>
                <TableRow2 className="tableHeader" >
                    <TableCell2 isHeader props={{ padding: '0' }}></TableCell2>
                    <TableCell2 isHeader width="min-content" props={{ padding: '0' }}></TableCell2>
                    <TableCell2 isHeader width="min-content" props={{ padding: '5px 10px', textAlign: 'center', fontSize: '10px' }}>Artist</TableCell2>
                    <TableCell2 isHeader width="min-content" props={{ padding: '5px 10px', textAlign: 'center', fontSize: '10px' }}>Buyer</TableCell2>
                    <TableCell2 isHeader width="min-content" props={{ padding: '0' }}></TableCell2>
                </TableRow2>
            </>

        )
    else if (modalType === 'buyer-details-favorites')
        return (
            <>
                <TableRow2 className="tableHeader">
                    <TableCell2 isHeader props={{ padding: '20px 10px' }}>Title</TableCell2>
                    <TableCell2 isHeader width="min-content" props={{ padding: '20px 10px' }}>Price</TableCell2>
                    <TableCell2 isHeader width="min-content" props={{ padding: '20px 10px' }}><ToolTipItem right="0"><img src={clock} alt="clockIcon" style={{ width: "15px" }} /><div className="tooltip" title="t">Date Favorited</div></ToolTipItem></TableCell2>
                    <TableCell2 width="min-content" textAlign="center" props={{ padding: '20px 10px' }}><ToolTipItem right="0"><img src={favoritesIcon} alt="numberFavs" style={{ width: "12px", height: '12px' }} /><div className="tooltip" title="t">No. of buyers who favorited this song</div></ToolTipItem></TableCell2>
                    <TableCell width="min-content" textAlign="center"><ToolTipItem right="0"><div style={{ whiteSpace: 'nowrap' }}>Top 100</div><div className="tooltip" title="t">Current spot on the top 100</div></ToolTipItem></TableCell>
                    <TableCell width="min-content"><ToolTipItem right="0"><img src={timeElapsed} alt="timeElapsed" style={{ width: "15px" }} /><div className="tooltip" title="t">Time elapsed since last on top 100<br/>or time elapsed in present top 100 spot.</div></ToolTipItem></TableCell>
                    <TableCell2 isHeader width="min-content" props={{ padding: '20px 10px' }}><ToolTipItem right="0"><img src={clock} alt="clockIcon" style={{ width: "15px" }} /><div className="tooltip" title="t">Time spent for sale</div></ToolTipItem></TableCell2>
                </TableRow2>
                {/* <TableRow2 className="tableHeader" >
                    <TableCell2 isHeader props={{ padding: '0' }}></TableCell2>
                    <TableCell2 isHeader width="min-content" props={{ padding: '0' }}></TableCell2>
                    <TableCell2 isHeader width="min-content" props={{ padding: '5px 10px', textAlign: 'center', fontSize: '10px' }}>Artist</TableCell2>
                    <TableCell2 isHeader width="min-content" props={{ padding: '5px 10px', textAlign: 'center', fontSize: '10px' }}>Buyer</TableCell2>
                    <TableCell2 isHeader width="min-content" props={{ padding: '0' }}></TableCell2>
                </TableRow2> */}
            </>

        )
    else return (
        <TableRow className="tableHeader">
            <TableCell>Title</TableCell>
            <TableCell width="min-content">Genre(s)</TableCell>
            <TableCell width="min-content">Price</TableCell>
            <TableCell width="min-content" textAlign="center"><ToolTipItem right="0"><img src={favoritesIcon} alt="numberFavs" style={{ width: "12px", height: '12px' }} /><div className="tooltip" title="t">No. of buyers who favorited this song</div></ToolTipItem></TableCell>
            <TableCell width="min-content" textAlign="center"><ToolTipItem right="0"><div style={{ whiteSpace: 'nowrap' }}>Top 100</div><div className="tooltip" title="t">Current spot on the top 100</div></ToolTipItem></TableCell>
            <TableCell width="min-content"><ToolTipItem right="0"><img src={timeElapsed} alt="timeElapsed" style={{ width: "15px" }} /><div className="tooltip" title="t">Time elapsed since last on top 100.</div></ToolTipItem></TableCell>
            <TableCell width="min-content"><ToolTipItem right="0"><img src={clock} alt="clockIcon" style={{ width: "15px" }} /><div className="tooltip" title="t">{toolTipText}</div></ToolTipItem></TableCell>
        </TableRow>
    )

}

const BuyerTransactionData = () => {

}

const MessageUser = ({ modalType, data, modalDetails }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const formatData = () => {
        if (data.buyer_email !== undefined)
            return (({ email, primarySongwriterPaypal, buyer_email, buyerPaypal, stemTransfer, dateSold, price, updated_at }) => ({ price: calculatePriceWithFee(price).total, 'Primary Songwriter [Contact Email]': email, 'Primary Songwriter [Paypal Email]': primarySongwriterPaypal, 'Deliverables Confirmation [Songwriter]': getCompletionStatus(stemTransfer, 0), 'Buyer [Contact Email]': buyer_email, 'Buyer [Paypal Email]': buyerPaypal, 'Deliverables Confirmation [Buyer]': getCompletionStatus(stemTransfer, 1), 'Date Sold': dayjs.unix(dateSold).format('M/DD/YY'), release_date: dayjs.unix(updated_at).format('M/DD/YY') }))(data)
        return (({ bpm, price, updated_at }) => ({ price: calculatePriceWithFee(price).total, tempo: bpm, release_date: dayjs.unix(updated_at).format('M/DD/YY') }))(data)
    }

    const songInfo = (({ favorites, top_100_history }) => ({ favorites }))(data)
    const songData = formatData()
    const _showTrailing = showTrailing(data.price, data.contributors)

    return (
        <>
            <h1 style={{ margin: '0' }}>{modalDetails.title} - {modalDetails.songTitle}</h1>
            <GridItem position="relative" textAlign="left" alignItems="start" gap="20px" overflow="auto" height="100%" >
                <h2 style={{ margin: '0' }}>Message</h2>
                <MessageModal modalType={modalType} modalDetails={modalDetails} data={data} loading={loading} error={error} setError={setError} setLoading={setLoading} />
                <h2 style={{ margin: '0' }}>Song Info</h2>
                <PrimaryDetails noUppercase>
                    {
                        Object.keys(songData).map(it => {
                            return (
                                <TableRow className="tableHeader">
                                    <TableCell width="min-content" color="555555" textTransform="capitalize">{it.replaceAll('_', " ")}</TableCell>
                                    <TableCell width="max-content" >{songData[it] === '' || songData[it] === 0 ? '-' : songData[it]}</TableCell>
                                </TableRow>
                            )
                        })
                    }

                </PrimaryDetails>
                <ContributorTable data={data} modalType={modalType} />


            </GridItem>

        </>)
}

export const ContributorTable = ({ data }) => {
    const _showTrailing = showTrailing(data.price, data.contributors)
    return (<>
        <h2 style={{ margin: '0' }}>Contributors</h2>

        <Table>
            <TableHeader modalType='song-details' />

            {

                data.contributors.map((contributor, index) => {

                    return (
                        <TableRow >
                            <TableCell className={`${index} row`} width="30px" textAlign="center">
                                {contributor.email === data.email &&
                                    <ToolTipItem left="0"><div style={{ position: 'relative', borderRadius: '50%', height: '5px', width: '5px', background: 'var(--yellow)' }}></div><div className="tooltip" title="t">Primary Contributor</div></ToolTipItem>

                                }
                            </TableCell>
                            {/* <TableCell className={`${index} row`} >
                        {contributor.name}
                    </TableCell> */}
                            <TableCell className={`${index} row`} width="max-content" height="54px" padding="0 10px">
                                <ToolTip height="100%" bottom="40px" justifyContent='start' absolute toolTipText={<GridItem gap="10px" display="grid" gridTempCols="min-content 1fr">
                                    <div>Paypal:</div>
                                    <EmailLink href={`mailto:${contributor.songwriterPaypal}`}>{contributor.songwriterPaypal}
                                    </EmailLink>
                                    <div>Contact:</div><EmailLink href={`mailto:${contributor.email}`}>{contributor.email}
                                    </EmailLink></GridItem>}>
                                    <EmailLink href={`mailto:${contributor.songwriterPaypal}`}>{contributor.songwriterPaypal}
                                    </EmailLink>
                                </ToolTip>
                            </TableCell>
                            <TableCell className={`${index} row`} width="min-content">{contributor.split}%</TableCell>
                            <TableCell className={`${index} row`} width="min-content">{calculateCut(contributor.split, data.price, _showTrailing)}</TableCell>
                        </TableRow>
                    )
                })
            }
        </Table></>)
}

const getCompletionStatus = (stemTransfer, index = 0) => {

    return stemTransfer[index].updated_at ?
        dayjs.unix(stemTransfer[index].updated_at).format('M/D/YY') : '-'

}

const Content = ({ data, modalDetails, modalType, spot, onChange, setTop100Action, top100Action }) => {
    // console.log("data", data[Object.keys(data).find(it => it !== 'user')])
    const [activeTab, setActiveTab] = useState(Object.keys(data).find(it => it !== 'user'))
    console.log("activeTab", activeTab)
    useEffect(() => {
        setActiveTab(Object.keys(data).find(it => it !== 'user'))
    }, [data])
    switch (modalType) {
        case 'songwriter-details':
            const social = (({ paypal, plan, publisher, acceptance_date }) => ({ paypal, plan, publisher, 'member_since': dayjs.unix(acceptance_date).format('MMMM YYYY') }))(data.user)
            const usersSongData = (({ songs_sold, total_sales, songs_accepted, songs_for_sale }) => ({ songs_sold, total_sales: shortFormatPrice(total_sales), songs_accepted, songs_for_sale }))(data.user)
            return (
                <>
                    <h1 style={{ margin: '0' }}>{modalDetails.title} - {modalDetails.songTitle}</h1>
                    <GridItem position="relative" textAlign="left" alignItems="start" gap="20px" overflow="auto" height="100%" >
                        <h2 style={{ margin: '0' }}>Artist Info</h2>
                        {/* <div style={{ height: '20px' }}></div> */}
                        <Flexbox gap="5px">
                            <SocialIcon type="spotify" url={data.user.spotify} />
                            <SocialIcon type="instagram" url={data.user.instagram} />
                            <div>{data.user.name}</div>
                            <EmailLink href={`mailto:${data.user.email}`}>{data.user.email}</EmailLink>
                        </Flexbox>
                        {/* <PrimaryDetails display="grid" >
                            {
                                Object.keys(social).map(it => {
                                    return (
                                        <>
                                            <Flexbox color="555555" transformText="capitalize">{it.replaceAll('_', " ")}</Flexbox>
                                            <Flexbox >{social[it] === '' || social[it] === 0 ? '-' : social[it]}</Flexbox>
                                        </>
                                    )
                                })
                            }
                            </PrimaryDetails> */}
                        <PrimaryDetails noUppercase>
                            {
                                Object.keys(social).map(it => {
                                    return (
                                        <TableRow className="tableHeader">
                                            <TableCell width="min-content" color="555555" textTransform="capitalize">{it.replaceAll('_', " ")}</TableCell>
                                            <TableCell width="max-content" >{social[it] === '' || social[it] === 0 ? '-' : social[it]}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }

                        </PrimaryDetails>
                        <GridItem gridRowGap="5px" width="100%" justifyItems="center" display="grid" gridTempRows="min-content min-content" gridTempCols="repeat(4, 1fr)">
                            {
                                Object.keys(usersSongData).map(it => {
                                    console.log("usersSongData", it)
                                    return (
                                        <>
                                            <GridItem fontSize="10px" alignText="center" gridRow="2" color="555555" transformText="capitalize" width="70px">{it.replaceAll('_', " ").toUpperCase()}</GridItem>
                                            <GridItem justifyContent="center" fontSize="14px" alignText="center" gridRow="1" border="1px solid #ffffff" borderRadius="50%" height="50px" width="50px">
                                                <div style={{ marginBottom: '2px' }}>{usersSongData[it] === '' || usersSongData[it] === 0 ? '-' : usersSongData[it]}</div></GridItem>
                                        </>
                                    )
                                })
                            }
                        </GridItem>
                        <h2 style={{ margin: '0' }}>Current Listings</h2>
                        <Table margin="0 0 50px" oddHighlight>
                            <TableHeader />
                            {
                                data.songs.map(song => {
                                    const genres = JSON.parse(song.genre)
                                    const currentTop100Index = song.top_100_history.length === 0 ? '-' :
                                        song.top_100_history.find(it => it.removed_at === null) ? song.top_100_history.findIndex(it => it.removed_at === null) : '-'
                                    const currentTop100Spot = currentTop100Index >= 0 ? song.top_100_history[currentTop100Index].spot : '-'
                                    const prevTop100Index = song.top_100_history.length === 0 ? '-' :
                                        song.top_100_history.find(it => it.removed_at !== null) ? song.top_100_history.findIndex(it => it.removed_at !== null) : '-'
                                    const lastTop100 = prevTop100Index >= 0 ? dayjs.unix(song.top_100_history[prevTop100Index].removed_at).fromNow() : '-'
                                    return (
                                        <TableRow >
                                            <TableCell className={`${song.id} row`} width="max-content">{song.song_title}</TableCell>
                                            <TableCell className={`${song.id} row`} >{genres.map((genre, idx) => (idx > 0 ? ", " + genre : genre))}</TableCell>
                                            <TableCell className={`${song.id} row`} width="min-content">{calculatePriceWithFee(song.price).total}</TableCell>
                                            <TableCell className={`${song.id} row`} textAlign="center" width="fit-content">{song.favorites > 0 ? song.favorites : '-'}</TableCell>
                                            <TableCell width="min-content" textAlign="center" className={`${song.id} row`} >{currentTop100Spot}</TableCell>
                                            <TableCell width="min-content" textAlign="center" className={`${song.id} row`} >{lastTop100}</TableCell>
                                            <TableCell width="min-content" className={`${song.id} row`} textAlign="center" >{dayjs.unix(song.date_added || song.updated_at).fromNow()}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </Table>
                    </GridItem>
                </>)
        case 'buyer-details':
            {
                const social = (({ paypal, plan, useConcierge, label_mgmt_aff, website, acceptance_date, artists_representing }) => ({ paypal, plan, 'Concierge': getConciergeStatus(useConcierge), 'member_since': dayjs.unix(acceptance_date).format('MMMM YYYY'), 'Label/Mgmt Aff.': label_mgmt_aff, website, 'Artists Representing': artists_representing }))(data.user)
                const usersSongData = (({ numberSongsFavorited, totalSpent, numberSongsPurchased }) => ({ 'Favorites': numberSongsFavorited, 'Total Spent': shortFormatPrice(totalSpent), 'Purchases': numberSongsPurchased }))(data.user)
                return (
                    <>
                        <h1 style={{ margin: '0' }}>{modalDetails.title} - {modalDetails.songTitle}</h1>
                        <GridItem position="relative" textAlign="left" alignItems="start" gap="20px" overflow="auto" height="100%" >
                            <h2 style={{ margin: '0' }}>Buyer Info</h2>
                            {/* <div style={{ height: '20px' }}></div> */}
                            <Flexbox gap="5px">
                                <SocialIcon type="spotify" url={data.user.spotify} />
                                <SocialIcon type="instagram" url={data.user.instagram} />
                                <div>{data.user.name}</div>
                                <EmailLink href={`mailto:${data.user.email}`}>{data.user.email}</EmailLink>
                            </Flexbox>
                            {/* <PrimaryDetails display="grid" >
                                    {
                                        Object.keys(social).map(it => {
                                            return (
                                                <>
                                                    <Flexbox color="555555" transformText="capitalize">{it.replaceAll('_', " ")}</Flexbox>
                                                    <Flexbox >{social[it] === '' || social[it] === 0 ? '-' : social[it]}</Flexbox>
                                                </>
                                            )
                                        })
                                    }
                                    </PrimaryDetails> */}
                            <PrimaryDetails noUppercase>
                                {
                                    Object.keys(social).map(it => {
                                        return (
                                            <TableRow className="tableHeader">
                                                <TableCell width="min-content" color="555555" textTransform="capitalize">{it.replaceAll('_', " ")}</TableCell>
                                                <TableCell width="max-content" >{social[it] === '' || social[it] === 0 ? '-' : social[it]}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }

                            </PrimaryDetails>
                            <GridItem gridRowGap="5px" width="100%" justifyItems="center" display="grid" gridTempRows="min-content min-content" gridTempCols={`repeat(${Object.keys(usersSongData).length}, 1fr)`}>
                                {
                                    Object.keys(usersSongData).map(it => {
                                        console.log("usersSongData", it)
                                        return (
                                            <>
                                                <GridItem fontSize="10px" alignText="center" gridRow="2" color="555555" transformText="capitalize" width="70px">{it.replaceAll('_', " ").toUpperCase()}</GridItem>
                                                <GridItem justifyContent="center" fontSize="14px" alignText="center" gridRow="1" border="1px solid #ffffff" borderRadius="50%" height="50px" width="50px">
                                                    <div style={{ marginBottom: '2px' }}>{usersSongData[it] === '' || usersSongData[it] === 0 ? '-' : usersSongData[it]}</div></GridItem>
                                            </>
                                        )
                                    })
                                }
                            </GridItem>
                            <Flexbox gap="0.5rem">
                                {
                                    Object.keys(data).filter(it => it !== 'user').map(it => (
                                        <h2
                                            key={it}
                                            onClick={() => { setActiveTab(it) }}
                                            style={{ textTransform: 'capitalize', margin: '0', color: activeTab === it ? '#ffffff' : '#1A1A1A' }}>{it}</h2>))
                                }
                                {/* <h2 style={{ margin: '0', color: activeTab === 'purchases' ? '#ffffff' : '#1A1A1A' }}>Purchases</h2>
                                <h2 style={{ margin: '0', color: activeTab === 'favorites' ? '#ffffff' : '#1A1A1A' }}>Favorites</h2> */}
                            </Flexbox>
                            <Table2 oddHighlight={activeTab === 'purchases' ? true : undefined} margin="0 0 50px" background="#1c1c1c">
                                <TableHeader modalType={activeTab === 'purchases' ? 'buyer-details-purchases' : 'buyer-details-favorites'} />
                                {
                                    activeTab === 'purchases' &&
                                    data.purchases.map(song => {
                                        const genres = JSON.parse(song.genre)
                                        // const currentTop100Index = song.top_100_history.length === 0 ? '-' :
                                        //     song.top_100_history.find(it => it.removed_at === null) ? song.top_100_history.findIndex(it => it.removed_at === null) : '-'
                                        // const currentTop100Spot = currentTop100Index >= 0 ? song.top_100_history[currentTop100Index].spot : '-'
                                        // const prevTop100Index = song.top_100_history.length === 0 ? '-' :
                                        //     song.top_100_history.find(it => it.removed_at !== null) ? song.top_100_history.findIndex(it => it.removed_at !== null) : '-'
                                        // const lastTop100 = prevTop100Index >= 0 ? dayjs.unix(song.top_100_history[prevTop100Index].removed_at).fromNow() : '-'
                                        return (
                                            <TableRow >
                                                <TableCell className={`${song.id} row`} width="max-content">{song.song_title}</TableCell>
                                                <TableCell className={`${song.id} row`} width="min-content">{calculatePriceWithFee(song.price).total}</TableCell>
                                                <TableCell className={`${song.id} row`} width="min-content">{getCompletionStatus(song.stemTransfer, 0)}</TableCell>
                                                <TableCell className={`${song.id} row`} width="min-content">{getCompletionStatus(song.stemTransfer, 1)}</TableCell>
                                                {/* <TableCell className={`${song.id} row`} textAlign="center" width="fit-content">{song.favorites > 0 ? song.favorites : '-'}</TableCell>
                                                <TableCell width="min-content" textAlign="center" className={`${song.id} row`} >{currentTop100Spot}</TableCell>
                                                <TableCell width="min-content" textAlign="center" className={`${song.id} row`} >{lastTop100}</TableCell> */}
                                                <TableCell width="min-content" className={`${song.id} row`} textAlign="center" >{dayjs.unix(song.saleDetails.dateSold || song.updated_at).fromNow()}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                                {
                                    activeTab === 'favorites' &&
                                    data.favorites.map(song => {
                                        const genres = JSON.parse(song.genre)
                                        const currentTop100Index = song.top_100_history.length === 0 ? '-' :
                                            song.top_100_history.find(it => it.removed_at === null) ? song.top_100_history.findIndex(it => it.removed_at === null) : '-'
                                        const currentTop100Spot = currentTop100Index >= 0 ? song.top_100_history[currentTop100Index].spot : '-'
                                        const prevTop100Index = song.top_100_history.length === 0 ? '-' :
                                            song.top_100_history.find(it => it.removed_at !== null) ? song.top_100_history.findIndex(it => it.removed_at !== null) : '-'
                                        const lastTop100 = prevTop100Index >= 0 ? dayjs.unix(song.top_100_history[prevTop100Index].removed_at).fromNow() : '-'
                                        return (
                                            <TableRow >
                                                <TableCell className={`${song.id} row`} width="max-content">{song.song_title}</TableCell>
                                                <TableCell className={`${song.id} row`} width="min-content">{calculatePriceWithFee(song.price).total}</TableCell>
                                                <TableCell2 width="min-content" className={`${song.id} row`} textAlign="center" props={{ textAlign: 'center',padding: "20px 10px" }}>{dayjs.unix(song.dateFavorited).format('MMM DD')}</TableCell2>
                                                <TableCell className={`${song.id} row`} textAlign="center" width="fit-content">{song.favorites > 0 ? song.favorites : '-'}</TableCell>
                                                <TableCell width="min-content" textAlign="center" className={`${song.id} row`} >{currentTop100Spot}</TableCell>
                                                <TableCell width="min-content" textAlign="center" className={`${song.id} row`} >{lastTop100}</TableCell>
                                                <TableCell width="min-content" className={`${song.id} row`} textAlign="center" >{dayjs.unix(song.updated_at).fromNow()}</TableCell>
                                                {/* <TableCell className={`${song.id} row`} textAlign="center" width="fit-content">{song.favorites > 0 ? song.favorites : '-'}</TableCell>
                                                <TableCell width="min-content" textAlign="center" className={`${song.id} row`} >{currentTop100Spot}</TableCell>
                                                <TableCell width="min-content" textAlign="center" className={`${song.id} row`} >{lastTop100}</TableCell> */}
                                            </TableRow>
                                        )
                                    })
                                }
                            </Table2>
                        </GridItem>
                    </>)
            }

        case 'song-details':
            const genres = JSON.parse(data.genre).map((genre, idx) => (idx > 0 ? ", " + genre : genre))
            const top100History = {

            }
            const formatData = () => {
                if (data.buyer_email !== undefined)
                    return (({ email, primarySongwriterPaypal, buyer_email, buyerPaypal, stemTransfer, dateSold, price, updated_at }, genres) => ({ price: calculatePriceWithFee(price).total, 'Primary Songwriter [Contact Email]': email, 'Primary Songwriter [Paypal Email]': primarySongwriterPaypal, 'Deliverables Confirmation [Songwriter]': getCompletionStatus(stemTransfer, 0), 'Buyer [Contact Email]': buyer_email, 'Buyer [Paypal Email]': buyerPaypal, 'Deliverables Confirmation [Buyer]': getCompletionStatus(stemTransfer, 1), 'Date Sold': dayjs.unix(dateSold).format('M/DD/YY'), release_date: dayjs.unix(updated_at).format('M/DD/YY') }))(data, genres)
                return (({ bpm, price, updated_at }, genres) => ({ price: calculatePriceWithFee(price).total, tempo: bpm, genres, release_date: dayjs.unix(updated_at).format('M/DD/YY') }))(data, genres)
            }
            const songInfo = (({ favorites, top_100_history }) => ({ favorites }))(data)
            const songData = formatData()

            return (
                <>
                    <h1 style={{ margin: '0' }}>{modalDetails.title} - {modalDetails.songTitle}</h1>
                    <GridItem position="relative" textAlign="left" alignItems="start" gap="20px" overflow="auto" height="100%" >
                        <h2 style={{ margin: '0' }}>Song Info</h2>
                        <PrimaryDetails noUppercase>
                            {
                                Object.keys(songData).map(it => {
                                    return (
                                        <TableRow className="tableHeader">
                                            <TableCell color="555555" textTransform="capitalize">{it.replaceAll('_', " ")}</TableCell>
                                            <TableCell width="max-content" >{songData[it] === '' || songData[it] === 0 ? '-' : songData[it]}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }

                        </PrimaryDetails>
                        <GridItem gridRowGap="5px" width="100%" justifyItems="center" display="grid" gridTempRows="min-content min-content" gridTempCols="repeat(4, 1fr)">
                            {
                                Object.keys(songInfo).map(it => {
                                    console.log("songData", it)
                                    return (
                                        <>
                                            <GridItem fontSize="10px" alignText="center" gridRow="2" color="555555" transformText="capitalize" width="70px">{it.replaceAll('_', " ").toUpperCase()}</GridItem>
                                            <GridItem justifyContent="center" fontSize="16px" alignText="center" gridRow="1" border="1px solid #ffffff" borderRadius="50%" height="50px" width="50px">
                                                <div style={{ marginBottom: '2px' }}>{songInfo[it] === '' || songInfo[it] === 0 ? '-' : songInfo[it]}</div></GridItem>
                                        </>
                                    )
                                })
                            }
                        </GridItem>
                        <ContributorTable data={data} modalType={modalType} />
                    </GridItem>

                </>)
            break;
        case 'message-songwriter':
            return <MessageUser modalDetails={modalDetails} data={data} modalType={modalType} />
        case 'message-buyer':
            return <MessageUser modalDetails={modalDetails} data={data} modalType={modalType} />
        default: return <></>
    }


}

const Top100Modal = ({ left, margin, position, flexGrow = 1, flexShrink = 1, width = '550px', modalDetails, modalType, showModal, closeModal }) => {
    const { data } = modalDetails
    const [spot, setSpot] = useState(1)
    const [top100Action, setTop100Action] = useState({
        options: {
            'option-1': { id: 'option-1', title: 'Shift' },
            'option-2': { id: 'option-2', title: 'Replace' }
        },
        active: 'option-1'
    }) // shift is 1, replace is 2
    const [details, setDetails] = useState({
        title: '',
        subtext: '',
        onClick: () => { }
    })

    const returnInRange = (val) => {
        if (val === '')
            return ''
        const value = parseInt(val)
        if (value > 100)
            return 100
        else if (value < 1)
            return 1
        else return value
    }

    const onChange = (e) => {
        const value = e.target.value.replace(/\D/g, '')
        setSpot(returnInRange(value))
    }
    console.log('details', modalDetails)

    const getModal = () => {
        switch (modalType) {
            case 'songwriter-details':
                setDetails({ title: 'Artist Details', subtext: 'Replace or shift rows down', onClick: () => { } })
                break;
            case 'song-details':
                setDetails({ title: 'Artist Details', subtext: '', onClick: () => { } })
                break;
            default:
                break;
        }
    }
    useEffect(() => {
        if (modalType)
            getModal()
    }, [modalType])
    console.log("modalDetails", modalDetails)

    return (
        <GridItem left={left} transition="margin ease-in-out 0.25s, left ease-in 0.5s" padding="50px 30px" background="var(--greyBackground)" margin={margin ? margin : showModal ? '0px' : `0 -${width} 0 0`} flexBasis={width} flexShrink={flexShrink} flexGrow={flexGrow} width="100%" height="100%" textAlign="left" alignItems="start" gap="20px" position={position ? position : "relative"} >
            <Flexbox height="30px" width="30px" border="1px solid #fff" padding="9px" borderRadius="50%" onClick={() => closeModal()}><NavArrowImg src={NavArrow} alt="back" /></Flexbox>
            <Content data={data} modalDetails={modalDetails} top100Action={top100Action} setTop100Action={setTop100Action} modalType={modalType} spot={spot} onChange={onChange} />
        </GridItem>
    )
}

export default Top100Modal