import { useState, useEffect, useRef } from 'react';
import API from '../API';
import { useAuth } from '../contexts/AuthContext'
import { updateRelTime } from '../utils'
import { useNotifications } from './useNotifications'

export const useSongwriterHook = (songwriterProps) => {
    const {
        setWaitingForServerResponse,
        setNotificationModalMessage,
        waitingForServerResponse,
        showNotificationModal,
        setShowNotificationModal,
        notificationModalMessage
    } = useNotifications()

    const { content, setItemsSubmitting, itemsSubmitting, data, setPayload } = songwriterProps
    const [popupVisible, setPopupVisibility] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [action, setAction] = useState('')
    const [showRemoveApprSongModal, setShowRemoveApprSongModal] = useState(false)
    const [isFinalWarning, setIsFinalWarning] = useState(false) // for removing songs

    const closePopup = () => {
        setIsFinalWarning(false)
        setShowRemoveApprSongModal(false);
        setPopupVisibility(false);
        setAction('')
    }

    const preparePayload = (input) => {
        if (input === 'remove')
            setShowRemoveApprSongModal(true)
        else
            setPopupVisibility(true)
        setAction(input)
        const artist = {
            userID: data.user_id,
            songID: data.song_id,
            status: input === "accept" ? '1' : '0',
            email: data.email,
            name: data.name,
            songTitle: data.song_title
        }
        //console.log("artist", artist)
        setPayload(artist)

    }

    useEffect(() => {
        if (itemsSubmitting.length > 0)
            itemsSubmitting.forEach(item => {
                console.log("HERE",content, item.payload, data)
                if (item.contentType === content.type &&
                    item.contentValue === content.value &&
                    item.payload.userID === data.user_id &&
                    item.payload.songID === data.song_id)
                    if (item.waitingForServerResponse)
                        setIsDisabled(true)
                    else
                        setIsDisabled(false)

            })
        else {
            console.log(itemsSubmitting.length)
            setIsDisabled(false)
            console.log("length is zero")
        }

    }, [itemsSubmitting])

    return {
        isDisabled,
        preparePayload,
        popupVisible,
        setPopupVisibility,
        action,
        setAction,
        showRemoveApprSongModal,
        closePopup,
        isFinalWarning,
        setIsFinalWarning,
    }
}