import styled from "styled-components";



export const Wrapper = styled.button`
width: min-content;
height:25px;
display: flex;
align-items: center;
position: relative;
background: transparent;
border: none;
cursor: pointer;
 img{
     height: 100%;
     width: 23px;
     position: relative;
 }


`;

export const InnerWrapper = styled.span`
color: #000;
white-space: nowrap;
height: 100%;
position: relative;
width: fit-content;
display: flex;
justify-content: center;
align-items: center;
padding: 0 4px;
font-size: var(--fontMedium);
font-weight: 400;
letter-spacing: .02rem;
background: var(--white);
margin-left: 2px;
border-radius: 2px;
font-weight: 400; 
`;