import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Dropdown from '../Dropdown'

import {
    DropDownContainerBox,
    DotMenuWrapper
} from './DotMenu.styles'


const DotMenu = ({ _menuOpen, _setMenuOpen, position, top, right, left, bottom, conditionMet, _ref, border, borderRadius, transform, _options, selected, onClick, onMouseOver, status, hoverActive, song, fill }) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const navigate = useNavigate()
    const handleChange = () => {
        console.log("change")
    }
    const [options, setOptions] = useState([])

    useEffect(() => {
        if (!hoverActive)
            setMenuOpen(false)
    }, [hoverActive])

    const getOptions = (status) => {
        if (_options !== undefined && _options.length > 0) {
            return _options.map(it => ({
                ...it, onClick: async (e) => {
                    await it.onClick(e)
                    setMenuOpen(false)
                }
            }))
        }
        else
            switch (parseInt(status)) {
                case 1:
                    return [
                        { value: 1, innerText: 'Remove from Top 100', onClick: () => { } },
                    ]
                case 2:
                    if (song.actionRequired === 2) {
                        return [
                            { value: 3, innerText: 'View Details', onClick: () => { } },
                            { value: 31, innerText: song.action, onClick: () => { } }
                        ]
                    }
                    else {
                        return [
                            { value: 3, innerText: 'View Details', onClick: () => { } }
                        ]
                    }
                case 4:
                    return [{ value: 4, innerText: 'View Details', onClick: () => { } }]
                default:
                    return []
            }
    }

    useEffect(() => {
        setOptions(getOptions(status))
    }, [status, _options])

    return (
        <>
            <DropDownContainerBox
                position={position}
                left={left}
                right={right}
                bottom={bottom}
                top={top}
                className="dotmenu">
                <Dropdown
                    name='header'
                    width="200px"

                    textAlign="right"
                    className="dotmenu"
                    headerWidth="100px"
                    dropdownWidth="200px"
                    marginBottom={'0'}
                    listBackground="var(--dropdownBackground)"
                    color="#ffffff"
                    boxShadow="0 0 10px rgb(0,0,0)"
                    colorOnSelect="var(--goldSolid)"
                    dropdownListMarginTop="20px"
                    justifyContent="flex-end"
                    background="var(--dropdownBackground)"
                    options={options}
                    valueX=""
                    setMenuState={setMenuOpen}
                    menuState={menuOpen}
                    handleStateChange={handleChange}
                    index={0}
                    id='filter'
                    setValidContinue={true}
                ></Dropdown>
            </DropDownContainerBox>
            <DotMenuWrapper
                className="dotmenu"
                ref={_ref}
                transform={transform}
                borderRadius={borderRadius}
                border={border}
                emptySelection={typeof (conditionMet) !== 'function' ? selected.length === 0 : !conditionMet()}
                onClick={() => {
                    //selected.length > 0
                    if (typeof (conditionMet) !== 'function' || conditionMet())
                        setMenuOpen(!menuOpen)
                }}
                onMouseOver={onMouseOver}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 13" className="dotmenu">
                    <g style={{ fill: fill }} className="dotmenu">
                        <circle cx="1.5" cy="1.5" r="1.5" className="dotmenu" />
                        <circle cx="1.5" cy="6.5" r="1.5" className="dotmenu" />
                        <circle cx="1.5" cy="11.5" r="1.5" className="dotmenu" />
                    </g>
                </svg>
            </DotMenuWrapper>
        </>
    )
}

export default DotMenu
