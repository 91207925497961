import React from 'react'
import dayjs from 'dayjs'
import { MessageWrapper, Wrapper, Sender, Time, Row } from './Message.styles'

const Message = ({ message, senderIsCurrentUser, name }) => {
    //console.log(message)
    const time = dayjs.unix(message.created_at)
    const sentToday = dayjs().format('DD-MM-YYYY') === time.format('DD-MM-YYYY')
    const sentYesterday = time.diff(dayjs(),'hour') <= -24 && time.diff(dayjs(),'hour') >= -48;
    return (
        <Wrapper senderIsCurrentUser={senderIsCurrentUser}>
            <MessageWrapper senderIsCurrentUser={senderIsCurrentUser}>
                {message.message}
            </MessageWrapper>
            <Row senderIsCurrentUser={senderIsCurrentUser} >
                <Sender senderIsCurrentUser={senderIsCurrentUser}>{name}</Sender>|
                <Time>{sentToday ? `Today ${time.format('h:mm A')}` : sentYesterday ? `Yesterday ${time.format('h:mm A')}`: time.format('MMM D')}</Time>
            </Row>

        </Wrapper>
    )
}

export default Message
