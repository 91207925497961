import styled from 'styled-components';


export const LabelStyled = styled.label`
    position: relative;
    font-size:  ${props => props.labelFontSize ? props.labelFontSize : '1em'};
    text-transform:  ${props => props.labelTextTransform ? props.labelTextTransform : 'none'};
    letter-spacing: ${props => props.labelTextTransform ? '0.1rem' : ''};
    display:inline-block;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};

`;


export const InputStyled = styled.input`
    height: ${props => props.height ? props.height : '49px'};
    flex-grow: ${({ flexGrow }) => flexGrow};
    flex-shrink: ${({ flexShrink }) => flexShrink};
    padding: ${props => props.isSearch ? '0 40px 0 20px' : props.padding ? props.padding : props.name === 'price' ? '0 20px 0 40px' : '0 20px 0 20px'};
    border-radius: ${props => props.borderRadius ? props.borderRadius : ''};
    font-weight: 600;
    text-transform: ${props => props.uppercaseOff ? '' : props.name !== 'message' ? 'uppercase' : ''};
    color: white;
    width: 100%;
    min-width:${({ minWidth }) => minWidth};
    background: ${({ background }) => background || 'var(--greyBackground)'};
    border: none;
    position: relative;
    letter-spacing: .1rem;
    box-shadow: ${({ boxShadow }) => boxShadow || 'inset 0 0 5px 1px rgb(0, 0, 0)'};
    margin: 1px;
    ::placeholder{
        color: #696980;
    }

    :focus{
        outline: ${({ outline }) => outline || '1px solid white'};
        box-shadow: ${({ boxShadowFocus }) => boxShadowFocus || 'inset 0 0 5px rgba(255, 255, 255, .5)'};
        
    }

`;

export const Wrapper = styled.div`
    position: relative;
    width: ${props => props.width ? props.width : '100%'};
    height: ${({ height }) => height};
    min-height: ${({ height }) => height};
    max-width: ${props => props.maxWidth ? props.maxWidth : ''};
    padding: 1px;
`;

export const InnerWrapper = styled.div`
    position: relative;
    width: ${props => props.width ? props.width : '100%'};
    height: ${({ height }) => height};
    min-height: ${({ height }) => height};
    max-width: ${props => props.maxWidth ? props.maxWidth : ''};
    background: ${({ background }) => background || 'var(--greyBackground)'};
    display: flex;
    align-items: center;
    padding: 1px;
`;

export const DollarSign = styled.div`
user-select: none;
position: absolute;
left: 1rem;
z-index: 1;
`;

export const SearchIcon = styled.img`
height: 15px;
width: 15px;
cursor: pointer;
opacity: ${props => props.opacity ? props.opacity : '0.25'};
position: absolute;
right: 1rem;
user-select: none;
`;