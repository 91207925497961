import styled from 'styled-components'

export const Wrapper = styled.div`

`;

export const CheckboxStyle = styled.label`
display: flex;
align-items: center;
justify-content: center;
font-size: var(--fontSmall);
color: var(--purpleGrey);
font-weight: 500;
letter-spacing: 0.02rem;
span{
    display: inline-block;
    width: 15px;
    height: 15px;
    background: rgba(255,255,255,0.5);
    position: relative;
    box-shadow: 0 0 1px 1px inset #000;
    cursor: pointer;
}

strong{
    color: #ffffff;
    font-weight: 700;
}

input{
    position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

input:checked ~ span{
    background: rgb(255,255,255);
    box-shadow: 0 0 1px 1px inset #000;
}

input:checked ~ span:after{
    content: "";
    position: relative;
    display: block;
 top: 1px;
  width: 3px;
  height: 8px;
  margin: auto auto;
  border: solid rgb(0,0,0);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
`;

export const Table = styled.div`
display: table;
width: 100%;
tr:nth-of-type(2n){
    background: rgba(255,255,255,0.05);
    
}
tr.selected{
    background: var(--yellow);
    color: #000000;
    
}
tr:not(.tableHeader):not(.selected):hover{
    background: rgba(255,255,255,0.15);
    
}
tr.tableHeader{
    text-transform: uppercase;
}
`;

export const TableCell = styled.div`
display: table-cell;
width: ${props => props.width ? props.width : 'auto'};
vertical-align: middle;
text-align: ${props => props.textAlign ?  props.textAlign : 'left'};
padding: 20px 10px;
border: 1px solid #1A1A1A;
`;

export const ToolTipItem = styled.div`
position: relative;
width: 100%;
display: flex;
justify-content: center;
.tooltip{
    border-radius: 5px;
    background: var(--greyBackground);
    border: 1px solid #858585;
    padding: 10px 20px;
    position: absolute;
    visibility: hidden;
    z-index: 1;
    white-space: nowrap;
    bottom: 30px;
}
&:hover .tooltip{
    visibility: visible;
}
`;