import styled from "styled-components";
import { Wrapper } from '../Flexbox/Flexbox.styles'

export const Container = styled(Wrapper)`
background: #121212;
height: 100%;
min-height: 100vh;
align-items: start;
justify-content: start;

width: 100%;
flex-direction: column;
height: 100vh;
.roundedTile{
    border-radius: 5px;
}

.subheadPaypal{
    color: #00457C;
    
}
.songdetailsPaypal{
    font-weight: 500;
}
h2{
    color: grey;
}
.subhead{
    color: var(--purpleGrey);
}
h2{
    color: grey;
}
h1{
    font-weight: 300;
    font-size: 1.3rem;
}
h3{
    margin: 0;
    font-size: 1.3rem;
}
`;

export const InnerContainer = styled(Wrapper)`
/* background: #1c1c1c; */
height: 100%;
overflow: auto;
padding: 20px 50px 50px;
align-items: start;
flex-direction: column;
/* max-width: 1300px; */
`;

export const EmailLink = styled.a`
all: unset;
cursor: pointer;
color: var(--yellow);
font-weight: bold;
`;