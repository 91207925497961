import React, { useState, useEffect } from 'react'

import LoginOverlay from '../LoginOverlay'

import { useParams } from 'react-router-dom'

import { Wrapper } from './Register.styles'

import API from '../../API'

import {
    SET_PASSWORD_POPUP
} from '../../config.js'

const Register = () => {
    const { userID } = useParams();
    const [popupType, setPopupType] = useState(SET_PASSWORD_POPUP)
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState({ id: '', email: '', password: '' })
    const [error, setError] = useState(false)
    const [showLogin, setShowLogin] = useState(false)
    const submitUserDetails = async () => {
        try {
            setError(false);
            setLoading(true);
            const res = await API.registerAccount(user.id, user.email, user.password);

        } catch (error) {
            setError(true);
        }
        setLoading(false);
    }


    useEffect(() => {
        const authUser = async (userID = null) => {
            try {
                setError(false);
                setLoading(true);
                const res = await API.authenticateAcceptance(userID);
                setUser(prev => ({ ...prev, id: res[0].id, email: res[0].email }))

            } catch (error) {
                setError(true);
            }
            setLoading(false);
        }

        authUser(userID)

    }, [])

    if (loading) return <Wrapper></Wrapper>
    else {
        return (
            <Wrapper>
                <LoginOverlay
                    onClick={submitUserDetails}
                    user={user}
                    setShowLogin={setShowLogin}
                    setUser={setUser}
                    popupType={popupType}
                    setPopupType={setPopupType}
                />
            </Wrapper>
        )
    }

}

export default Register
