import { useState, useEffect, useRef, memo } from 'react';
import API from '../API';
import { useAuth } from '../contexts/AuthContext'
import { updateRelTime } from '../utils'
import dayjs from 'dayjs'
import { useNotifications } from './useNotifications'

export const useTop100NewHook = (args) => {
    const { songs, options } = args
    const { currentUser } = useAuth()
    updateRelTime()
    const [selected, setSelected] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [filters, setFilters] = useState({
        columnSearch: {
            options: {
                'option-1': {
                    id: 'option-1',
                    innerText: 'Top 100',
                    value: 'top-100',
                    title: 'Top 100',
                },
                'option-2': {
                    id: 'option-2',
                    innerText: 'Options',
                    value: 'options',
                    title: 'Options',
                },
            },
            selected: 'option-1',
        },

    })



    const [loading, setLoading] = useState(true)

    const [modalDetails, setModalDetails] = useState({
        showModal: false,
        modalType: 1,
        fullModal: false,
        data: {},
        title: '',
        songTitle: ''
    })
    const payload = useRef({
        songId: '',
        category: null,
        data: {},
    })
    const songDetails = {
        options: {
            'option-1': {
                id: 'songwriter-details',
                title: 'Songwriter Details',
                onClick: async (songId) => { return await getSongwriter(songId) },
            },
            'option-2': {
                id: 'song-details',
                title: 'Song Details',
                onClick: async (songId) => { return await getSong(songId) },
            }
        },
    }
    const formatAndUpdate = async (newTop100, newOptions = []) => {
        try {
            const organizedTop100 = []
            for (let index = 0; index < newTop100.length; index++) {
                const element = newTop100[index];
                organizedTop100.push({ id: element, spot: index + 1, top100Action: 'replace' })
            }
            await API.updateTop100(currentUser, organizedTop100, newOptions)
            setSelected([])
            clearModalSelections()
        } catch (error) {
            console.log("error occured", error)
        }

    }

    const [button, setButton] = useState({
        options: {
            'option-1': {
                dataId: 'column-1',
                id: 'option-1',
                'cancel': {
                    text: 'Revert Changes',
                    onClick: () => { revertChanges() }
                },
                'submit': {
                    text: 'Save',
                    onClick: async (_data) => {
                        if (_data['columns']) {
                            const top100songIDs = _data.columns['column-1']['songIds']
                            const options = _data.columns['column-2']['songIds']
                            await formatAndUpdate(top100songIDs, options)
                        }
                    }
                }
            },
            'option-2': {
                dataId: 'column-2',
                id: 'option-2',
                'cancel': {
                    text: 'Cancel',
                    onClick: () => { closeModal() }
                },
                'submit': {
                    text: 'Save',
                    onClick: (_d) => {
                        console.log("saving", category.current)
                        // onCheck(category.current, payload.current.songId)
                        onMoveViaBar()
                    }
                }
            }
        },
        selected: 'option-1',
        fullModal: false
    })

    const closeModal = () => { setModalDetails(prev => ({ ...prev, showModal: false })); setButtonActions('option-1'); removeSelectionAfterDrop(); }

    const clearModalSelections = () => { setModalDetails(prev => ({ ...prev, showModal: false })); setButtonActions('option-1'); }

    const onSearchChange = (input) => {
        // setSearchValue(e.target.value)
        setSearchValue(input)
    }

    const initialState = useRef({})
    const changed = useRef(false)
    const category = useRef(null)
    const [data, setData] = useState({})


    const mapButtonToData = (_columnId) => {
        const buttonId = Object.keys(button.options).find(id => {
            const _button = button.options[id]
            return _button.dataId === _columnId
        })
        console.log("column id", _columnId, buttonId)
        return button.options[buttonId].id
    }

    const mapSongDetails = (_modalId, songId) => {
        const typeId = Object.keys(songDetails.options).find(id => {
            const _option = songDetails.options[id]
            return _option.id === _modalId
        })
        console.log("type id is", typeId, _modalId)
        return songDetails.options[typeId]
    }

    const needsFullModal = (_modalId) => {
        console.log("_modalId", _modalId, data.columnOrder.includes(_modalId))
        return !data.columnOrder.includes(_modalId)
    }

    const getSongDetails = (songId) => data.songs[parseInt(songId)].content

    const getMaxSpot = () => {
        const top100col = getColumnFromTitle('Top 100');
        console.log("getColumnFromTitle", top100col)
        if (!top100col)
            return -1
        else
            return top100col.songIds.length + 1

    }

    const handleActionForTop100Spot = (_spot, top100Action = 'replace') => {
        // shift, replace, remove
        changed.current = true
        const maxSpot = getMaxSpot()
        const spot = _spot > maxSpot ? maxSpot : _spot
        setModalDetails(prev => ({
            ...prev,
            data: {
                ...prev.data,
                spot,
                top100Action
            }
        }))
        if (payload.current && payload.current.data)
            payload.current = {
                ...payload.current,
                data: {
                    ...payload.current.data,
                    spot,
                    top100Action
                }
            }
    }

    const setModalType = async (_modalId, songId) => {

        payload.current = { ...payload.current, songId: songId }

        setSelected([songId])

        if (!needsFullModal(_modalId)) {
            changed.current = true //check
            const _selectedSongDetails = getSongDetails(songId)
            const data = {
                ..._selectedSongDetails,
                spot: 1,
                top100Action: 'replace'
            }
            payload.current = { ...payload.current, songId: songId, data }
            setModalDetails(prev => ({
                ...prev,
                modalType: _modalId,
                showModal: true,
                fullModal: needsFullModal(_modalId),
                data: data,
                songTitle: _selectedSongDetails.song_title,

            }))
            setButtonActions(mapButtonToData(_modalId))
        }
        else {
            const _detailsType = mapSongDetails(_modalId, songId)
            const res = await _detailsType.onClick(songId)
            console.log("songId::::::::::::", songId)
            const _selectedSongDetails = data.songs[parseInt(songId)].content.song_title
            if (res) {
                setModalDetails(prev => (
                    {
                        ...prev,
                        modalType: _modalId,
                        showModal: true,
                        fullModal: needsFullModal(_modalId),
                        title: _detailsType.title,
                        data: { ...res },
                        songTitle: _selectedSongDetails
                    }))
                setButton(prev => ({
                    ...prev,
                    fullModal: true
                }))

            }
        }
    }

    const getSongwriter = async (songId) => {
        return await API.fetchUserBySongId(currentUser, songId)
    }

    const getSong = async (songId) => {
        return await API.fetchSong(currentUser, songId)
    }

    const setButtonActions = (_optionId) => setButton(prev => ({ ...prev, selected: button.options[_optionId].id, fullModal: false }))

    const onMoveViaBar = async () => {
        // category is always shifted from column-2 to column-1 here ?
        if (payload.current && payload.current.data) {
            const { songId, data: { top100Action, spot } } = payload.current
            console.log("data;;;;;;;;;", data, songId, payload.current)
            const start = getHomeColumn(songId)
            const indexOfA = start.songIds.indexOf(songId);
            const finish = getDestinationColumn(start.id)
            const startSongIds = Array.from(start.songIds).filter(it => it !== songId)

            const newStart = {
                ...start,
                songIds: startSongIds
            }

            const finishSongIds = Array.from(finish.songIds)
            finishSongIds.splice(spot - 1, top100Action === 'shift' ? 0 : 1, songId)
            const newFinish = {
                ...finish,
                songIds: finishSongIds
            }

            setData(prev => ({
                ...prev,
                columns: {
                    ...prev.columns,
                    [newStart.id]: newStart,
                    [newFinish.id]: newFinish,
                }
            }))
            console.log("newStart", newStart, "newFinish", newFinish)
            if (data['columns']) {
                await formatAndUpdate(finishSongIds, startSongIds)

            }
        }
        changed.current = false
        setSelected([])
    }

    const onCheck = (_category, songID, event = undefined) => {
        if (modalDetails.showModal) {
            clearModalSelections()
        }
        const isWindows = navigator.userAgent.indexOf('Win') >= 0;
        const toggleKey = event ? isWindows ? event.ctrlKey : event.metaKey : null
        if (category.current === null)
            category.current = _category;
        if (_category === category.current) {
            if (selected.includes(songID)) {
                if ((event !== undefined && (event.shiftKey || toggleKey)) || selected.length === 1) {
                    setSelected(prev => ([...prev.filter(it => it !== songID)]))
                } else
                    setSelected([songID])
            }
            else {
                if (event !== undefined && event.shiftKey && selected.length) {

                    const _songIdStart = selected[selected.length - 1]
                    const start = data.columns[_category].songIds.indexOf(_songIdStart) + 1
                    const finish = data.columns[_category].songIds.indexOf(songID) + 1
                    // console.log(_songIdStart, start, finish)
                    if (finish > start)
                        setSelected(prev => [...prev, ...data.columns[_category].songIds.slice(start, finish)])
                    else
                        setSelected(prev => [...prev, ...data.columns[_category].songIds.slice(finish - 1, start - 1)])
                }
                else {
                    if (toggleKey)
                        setSelected(prev => [...prev, songID])
                    else
                        setSelected([songID])
                }
            }
        } else {
            setSelected([songID])
            category.current = _category;
        }
    }

    const multiDragAwareReorder = (args) => {
        if (selected.length > 1)
            return reorderMultiDrag(args)
        return onDragEnd(args)
    }

    const revertChanges = () => {
        if (initialState.current !== null)
            setData(initialState.current)
        changed.current = false;
    }

    const removeSelectionAfterDrop = () => {
        let id = setTimeout(() => setSelected([]), .1 * 1000)
        return () => clearTimeout(id);
    }

    useEffect(() => {
        if (songs.length || options.length) {
            let _currentTop100Data = {}

            // songs.forEach((song, index) => {
            //     let _song = { id: song.song_id, content: song }
            //     //_currentTop100Data = { ..._currentTop100Data, [`song-${index + 1}`]: _song }
            //     _currentTop100Data = { ..._currentTop100Data, [song.song_id]: _song }
            // })

            for (let index = 0; index < songs.length; index++) {
                const song = songs[index];
                let _song = { id: song.song_id, content: song }
                _currentTop100Data = { ..._currentTop100Data, [song.song_id]: _song }
            }
            console.log("_currentTop100Data", _currentTop100Data)

            let _currentOptionsData = {}
            options.forEach((song, index) => {
                let _song = { id: song.song_id, content: song }
                //_currentTop100Data = { ..._currentTop100Data, [`song-${index + 1}`]: _song }
                _currentOptionsData = { ..._currentOptionsData, [song.song_id]: _song }
            })
            const _data = {
                songs: {
                    ..._currentTop100Data,
                    ..._currentOptionsData
                },
                columns: {
                    'column-1': {
                        id: 'column-1',
                        title: 'Top 100',
                        background: "#1c1c1c",
                        subtext: dayjs().format('ddd, MMMM D, YYYY'),
                        songIds: Object.keys(_currentTop100Data).sort((a, b) => {
                            // moving the dragged item to the top of the list
                            const dataA = _currentTop100Data[a].content['spot']
                            const dataB = _currentTop100Data[b].content['spot']
                            if (!dataA || !dataB)
                                return -1
                            if (parseInt(dataA) > parseInt(dataB)) {
                                return 1;
                            }
                            return -1;
                        })
                    },
                    'column-2': {
                        id: 'column-2',
                        title: 'Options',
                        background: "var(--greyBackground)",
                        subtext: "Songs displayed are listed with 'for sale' status, not presently in the top 100.",
                        songIds: Object.keys(_currentOptionsData)
                    },
                },
                columnOrder: ['column-1', 'column-2']
            }
            setData({ ..._data })
            initialState.current = { ..._data }
            setLoading(false)
        } else {
            setLoading(false)
        }
    }, [songs, options])

    const getHomeColumn = (songId) => {
        const columnId = data.columnOrder.find(id => {
            const col = data.columns[id]
            return col.songIds.includes(songId)
        })
        return data.columns[columnId]
    }

    const getDestinationColumn = (_colId) => {
        const columnId = data.columnOrder.find(id => {
            const col = data.columns[id]
            return col.id !== _colId
        })
        return data.columns[columnId]
    }

    const getHomeColumnTitle = (columnId) => {
        return data.columns[columnId].title
    }

    const getColumnFromTitle = (_title) => {
        const columnId = data.columnOrder.find(id => {
            return data.columns[id].title === _title
        })
        return data.columns[columnId]
    }

    const reorderMultiDrag = (result = null, songId = null) => {
        if (songId) {
            const start = getHomeColumn(songId)
            const indexOfA = start.songIds.indexOf(songId);
            const finish = getDestinationColumn(start.id)
            const startSongIds = Array.from(start.songIds).filter(it => it !== songId)
            const finishSongIds = Array.from(finish.songIds)
            console.log("start", start, "finish", finish, "startSongIds", startSongIds, finishSongIds)
            const newStart = {
                ...start,
                songIds: startSongIds
            }
            finishSongIds.splice(0, 0, songId)
            const newFinish = {
                ...finish,
                songIds: finishSongIds
            }
            setData(prev => ({
                ...prev,
                columns: {
                    ...prev.columns,
                    [newStart.id]: newStart,
                    [newFinish.id]: newFinish,
                }
            }))
        } else {
            const { destination, source } = result
            changed.current = true
            const start = data.columns[source.droppableId]
            const dragged = start.songIds[source.index]
            const finish = data.columns[destination.droppableId]
            category.current = finish.title;
            const orderedSelectedSongIds = selected
            console.log("orderedSelectedSongIds", orderedSelectedSongIds)
            orderedSelectedSongIds.sort((a, b) => {
                // moving the dragged item to the top of the list
                if (a === dragged) {
                    return -1;
                }
                if (b === dragged) {
                    return 1;
                }

                // sorting by their natural indexes
                const columnForA = getHomeColumn(a)
                const indexOfA = columnForA.songIds.indexOf(a);
                const columnForB = getHomeColumn(b)
                const indexOfB = columnForB.songIds.indexOf(b);

                if (indexOfA !== indexOfB) {
                    return indexOfA - indexOfB;
                }

                // sorting by their order in the selectedTaskIds list
                return -1;
            });
            if (!destination)
                return
            if (destination.droppableId === source.droppableId && destination.index === source.index)
                return



            const startSongIds = Array.from(start.songIds).filter(it => !selected.includes(it))
            const newStart = {
                ...start,
                songIds: startSongIds
            }
            const finishSongIds = start === finish ? Array.from(startSongIds) : Array.from(finish.songIds)
            finishSongIds.splice(destination.index, 0, ...orderedSelectedSongIds)
            const newFinish = {
                ...finish,
                songIds: finishSongIds
            }
            setData(prev => ({
                ...prev,
                columns: {
                    ...prev.columns,
                    [newStart.id]: newStart,
                    [newFinish.id]: newFinish,
                }
            }))
        }

        removeSelectionAfterDrop()

    }

    const onDragStart = (start) => {
        const { draggableId } = start
        if (modalDetails.showModal) {
            clearModalSelections()
        }
        const _selected = selected.find(it => it === draggableId)
        if (!_selected)
            setSelected([draggableId])

    }

    const onDragEnd = (result) => {
        const { destination, source, draggableId } = result
        changed.current = true
        if (!destination)
            return
        if (destination.droppableId === source.droppableId && destination.index === source.index)
            return
        const start = data.columns[source.droppableId]
        const finish = data.columns[destination.droppableId]
        category.current = finish.title;
        if (start === finish) {
            const newSongIds = Array.from(start.songIds)
            newSongIds.splice(source.index, 1)
            newSongIds.splice(destination.index, 0, draggableId)
            const newColumn = {
                ...start,
                songIds: newSongIds
            }
            const newState = {
                ...data,
                columns: {
                    ...data.columns,
                    [newColumn.id]: newColumn,
                },
            }
            setData(newState)
            // setSelected([])
            removeSelectionAfterDrop()

        } else {
            const startSongIds = Array.from(start.songIds)
            startSongIds.splice(source.index, 1)
            const newStart = {
                ...start,
                songIds: startSongIds
            }
            const finishSongIds = Array.from(finish.songIds)
            finishSongIds.splice(destination.index, 0, draggableId)
            const newFinish = {
                ...finish,
                songIds: finishSongIds
            }
            setData(prev => ({
                ...prev,
                columns: {
                    ...prev.columns,
                    [newStart.id]: newStart,
                    [newFinish.id]: newFinish,
                }
            }))
            removeSelectionAfterDrop()


        }
    }

    // top 100 actions:
    // remove selection(s)
    // swap spots (2 + selections) in/out of top100
    // replace input field for number

    return {
        onDragStart,
        searchValue,
        onSearchChange,
        setFilters,
        filters,
        setButtonActions,
        button,
        closeModal,
        loading,
        setModalType,
        modalDetails,
        reorderMultiDrag,
        multiDragAwareReorder,
        getHomeColumnTitle,
        revertChanges,
        onDragEnd,
        changed,
        onCheck,
        initialState,
        setSelected,
        selected,
        data,
        setData,
        onMoveViaBar,
        handleActionForTop100Spot
    }
}