import styled from "styled-components";


export const NavWrapper = styled.div`
    width: 100%;
    display: flex;
    max-width: 800px;
    align-items: center;
    justify-content: center;

`;

export const Wrapper = styled.div`
width: 446px;
height: 374px;
margin: 0 20px;

`;

export const QuestionWrapper = styled.div`
position: relative;
margin-top: 20px;
`;

export const SongCount = styled.div`
text-transform: uppercase;
letter-spacing: 0.03rem;
height: 1.1rem;
font-size: var(--fontMicro);
border-bottom: 1px solid #fff;
`;




export const Button = styled.button`
    all: unset;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    position: relative;
    img{
        height: 23px;

    }
`;