import styled from "styled-components";

export const OuterWrapper = styled.div`
width: 100%;
display: flex;
height: 100%;
overflow: hidden;
position: relative;
grid-gap: 10px;
display: grid;
grid-template-areas:
'sideBar messages details'
'sideBar inputbar details';
grid-template-columns: minmax(min-content,250px) 1fr 400px;
grid-template-rows: 1fr min-content;
@media screen and (max-width: 1376px){
    grid-template-areas:
'sideBar details'
'sideBar messages'
'sideBar inputbar';
grid-template-columns: minmax(min-content,250px) 1fr;
grid-template-rows: min-content 1fr  min-content;
    }
`;

export const MessagesSideBar = styled.div`
    user-select: none;
    text-transform: uppercase;
    font-size: 12px;
    background: var(--greyBackground);
position: relative;
user-select: none;
height: 100%;
width: 100%;
max-height: calc(100vh - 100px);
border: 1px solid var(--greyBackground);
display: grid;
grid-template-rows: min-content  min-content 1fr;
`;

export const Title = styled.div`
text-transform: uppercase;
padding: 20px;
letter-spacing: 0.10rem;
font-weight: 600;
margin-bottom: 1rem;
`;

export const NavArrow = styled.div`
height: 15px;
width: 15px;
opacity: ${props => props.isValid ? '1' : '0.25'};
transform: ${props => props.back ? 'rotate(180deg)' : ''};
display: flex;
align-items: center;
justify-content: center;
img{
    height: 90%;
    width: 90%;
}
`;

export const SongStatusNote = styled.div`
text-transform: uppercase;
margin-top: 0.7rem;
height: 1rem;
color: var(--purpleGrey);
width: 100%;
position: relative;
font-size: 10px;
text-align: ${props=> props.textAlign ? props.textAlign : 'left'};
`;

export const SongDetailsHeader = styled.div`
text-transform: none;
user-select: none;
display: flex;
padding: 0;
justify-content: space-between;
letter-spacing: 0.10rem;
font-weight: 600;
padding-bottom: 10px;
/* border-bottom: 1px solid #ffffff; */

`;

export const LinkHeader = styled.div`
color: var(--yellow);
width: 100%;
font-weight: ${props => props.isCurrent ? '700' : '300'};
text-transform: uppercase;
letter-spacing: 0.13rem;
margin-top: 10px;
padding: 10px 20px;
font-weight: 500;
font-size: 10px;

`;

export const FlexColumn = styled.div`
display: flex;
align-items: center;
flex-direction: column;
`;

export const Link = styled.div``;

export const Line = styled.div`
height: 1px;
background: var(--purpleGrey);
width: 100%;
`;



export const MessageStream = styled.div`
display: flex;
padding-bottom: 1px;
width: 100%;
height: 100%;
position: relative;
display: grid;
`;

export const GridItem = styled.div`
height: 100%;
background: ${props=> props.background ? props.background : 'var(--greyBackground)'};
display: flex;
overflow: ${props=> props.overflow ? props.overflow : 'visible'};
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
grid-area: ${props => props.gridArea ? props.gridArea : ''};
`;

export const DetailsWrapper = styled.div`
background: var(--greyBackground);
width: 100%;
padding: 20px;
margin-bottom: 10px;
box-shadow: ${props => props.boxShadow ? props.boxShadow : 'undefined'};
`;

export const LoadingWrapper = styled.div`
height: 100%;
display: flex;
flex-direction: column;
width: 100%;
justify-content: center;
align-items: center;
`;

export const ScrollWrapper = styled.div`
overflow: auto;
height: 100%;
position: relative;
`;

export const InnerScrollWrapper = styled.div`
overflow: auto;
padding-bottom: 50px;
height: 100%;
position: relative;
`;

export const SubLink = styled(LinkHeader)`
text-transform: none;
display: flex;
align-items: center;
background: ${props => props.isCurrent ? 'var(--backgroundActiveLi)' : 'transparent'};
border-right: ${props => props.isCurrent ? 'var(--borderRightActiveLi)' : 'transparent'};
font-size: 12px;
color: ${props => props.isNew ? 'var(--yellow)' : 'var(--primary)'};
font-weight: ${props => props.isNew ? '900' : '400'};
`;

export const NewDot = styled.div`
height: 5px;
position: absolute;
left: 7.5px;
width: 5px;
background: var(--yellow);
border-radius: 50%;
`;