import styled from "styled-components";

export const Wrapper = styled.div`
height: ${props => props.height ? props.height : 'calc(100vh - var(--navBarHeight))'};
position: ${props => props.position ? props.position : 'fixed'};
width: ${props => props.width ? props.width : '100%'};
top: ${props => props.top ? props.top : 'var(--navBarHeight)'};
z-index: ${props => props.zIndex ? props.zIndex : "1"};
background: ${props => props.background ? props.background : 'var(--background)'};
display: flex;
justify-content: center;
align-items: center;
border: ${props => props.border ? props.border : ''};
`;

export const LoadingWheelWrapper = styled.div`
    /* border: ${props => props.border ? props.border : '2px solid var(--greyBackground)'};
    border-top: 2px solid #ffffff; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.radius ? props.radius : '50px'};
    height: ${props => props.radius ? props.radius : '50px'};
    position: relative;
    background-image: 
    conic-gradient(
        #111111 0deg, 
        rgb(38,38,38) 15deg,
        #3B3B3B 30deg, 
        rgb(38,38,38) 45deg,
        #111111 60deg,
        #111111 100deg,
        rgb(38,38,38) 115deg,
        #323232 130deg, 
        rgb(38,38,38) 145deg,
        #111111 160deg,
        #111111 170deg,
        #222222 185deg,
        rgb(38,38,38) 195deg,
        #3B3B3B 210deg,
        rgb(38,38,38) 230deg,
        #111111 245deg,
        #111111 260deg,
        #222222 290deg,
        rgb(38,38,38) 315deg,
        #111111 345deg);
    animation: spin 2s linear infinite;
    @keyframes spin {
    0% {
    transform: rotate(0deg);
    }

    100% { transform: rotate(360deg);
    }
    }

`;