import React, { useRef, useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { updateRelTime } from '../../../utils'
import {
    Wrapper,
    ArtistName,
    Instagram,
    Spotify,
    SongTitle,
    Tempo,
    Genre,
    Date,
    AcceptButtonContainer,
    RejectButtonContainer,
    PlayButtonContainer,
    Email,
    Status,
    ValueHighlighted,
    Strong,
    AlertIcon,
    Icon

} from './Songwriter.styles'

import Accept from '../../../images/acceptIcon.svg'
import Reject from '../../../images/rejectIcon.svg'
import Play from '../../../images/play.svg'
import Pause from '../../../images/pause.svg'
import SpotifyIcon from '../../../images/spotify.svg'
import InstagramIcon from '../../../images/instagramIcon.svg'
import AlertIconRed from '../../../images/alertIconRed.svg'

import Agreed from '../../../images/checkIconFill.svg'

import NotificationModal from '../../NotificationModal'

import { calculatePriceWithFee } from '../../../helpers'
import { PAYLOAD_TYPES } from '../../../config'

import DotMenu from '../../DotMenu'

import AcceptAndReject from '../AcceptAndReject'
import SongDetailsModal from '../SongDetailsModal'
import GridItem from '../../GridItem'

import { useSongwriterHook } from '../../../hooks/useSongwriterHook'

const PlayButton = ({ onClick, playing, isCurrent }) => {
    if (playing && isCurrent) {
        return (
            <PlayButtonContainer onClick={onClick}>
                <img src={Pause} alt="play-button" />
            </PlayButtonContainer>
        )
    }
    else {
        return (
            <PlayButtonContainer onClick={onClick}>
                <img src={Play} alt="play-button" />
            </PlayButtonContainer>
        )
    }

}

const SpotifyLink = ({ spotify }) => {
    const spotifyLink = spotify === null ? spotify : spotify.includes('https://') ? spotify : 'https://' + spotify
    return (
        <Spotify
            href={spotifyLink != '' ? spotifyLink : undefined}
            target="_blank"
            spotify={spotify}>
            <img src={SpotifyIcon} alt="spotifyIcon" />
        </Spotify>
    )
}

export const InstagramLink = ({ instagram }) => {
    const instagramLink = instagram === null ? '' : `https://www.instagram.com/${instagram.split('@').length > 1 ? instagram.split('@')[1] : instagram.split('@')[0]}`;
    return (
        <Instagram
            href={instagramLink != '' ? instagramLink : undefined}
            target="_blank"
            instagram={instagram}>
            <img src={InstagramIcon} alt="spotifyIcon" />
        </Instagram>
    )
}

const Songwriter = ({ handlePayload, content, songwriterProps, setItemsSubmitting, itemsSubmitting, songStatusKey, type, setPayload, data, playing, handleSongChange, nowPlaying, isApproved }) => {
    updateRelTime()
    const {
        isDisabled,
        preparePayload,
        popupVisible,
        setPopupVisibility,
        action,
        showRemoveApprSongModal,
        closePopup,
        isFinalWarning,
        setIsFinalWarning }
        = useSongwriterHook({ ...songwriterProps, content, data, setPayload, itemsSubmitting, setItemsSubmitting })

    const tempo = data.bpm ? data.bpm.split(' ')[0] : ''
    const genres = data.genre ? eval(data.genre) : []
    console.log("content", content, "data", data, itemsSubmitting.findIndex(it => (
        it.content === content &&
        it.payload.userID === data.user_id &&
        it.payload.songID === data.song_id
    )) >= 0)

    const getDate = () => {
        if (type === 'buyers' && !isApproved)
            return dayjs(data.created_at).fromNow()
        else if (type === 'buyers')
            return dayjs.unix(data.acceptance_date).fromNow()
        else return dayjs.unix(parseInt(data.song_status) === 1 ? data.created_at : data.updated_at).fromNow()
    }
    const date = getDate();

    if (isApproved && type === "buyers") {
        return (
            <Wrapper
                action={action}
                isApproved={isApproved}
                gridTemplateAreas={"artist email publisher website concierge instagram purchases favorites date acceptreject"}
                gridTemplateCols={'1fr minmax(200px,1fr) 2fr 1fr 60px 60px 70px 25px 40px 110px'}
            >
                <Email>{data.email}</Email>
                <GridItem gridArea="publisher" alignItems="start">{data.label_mgmt_aff || '-'}</GridItem>
                <InstagramLink instagram={data.instagram} />
                <GridItem gridArea="website" alignItems="start">{data.website || '-'}</GridItem>
                <GridItem gridArea="concierge" alignItems="center">{data.isConcierge ? <Icon src={Agreed} alt="use-concierge" /> : '-'}</GridItem>
                <GridItem gridArea="purchases" alignItems="center">{data.purchases || '-'}</GridItem>
                <ArtistName>{data.name}</ArtistName>
                <GridItem gridArea="favorites" alignItems="center">{data.favorites || '-'}</GridItem>
                <Date>{date}</Date>
                <AcceptAndReject
                    disabled={isDisabled}
                    handlePayload={async () => await handlePayload(PAYLOAD_TYPES.APPROVED_BUYER)}
                    popupVisible={popupVisible}
                    action={action}
                    preparePayload={preparePayload}
                    closePopup={closePopup}
                    userID={data.user_id}
                    isApproved={isApproved}
                    type={'buyer'}
                    value={data.name} />
            </Wrapper>
        )
    } else if (!isApproved && type === "buyers") {
        return (
            <Wrapper
                action={action}
                isApproved={isApproved}
                gridTemplateAreas={"artist email publisher website concierge instagram date acceptreject"}
                gridTemplateCols={'1fr minmax(200px,1fr) 2fr 1fr 60px 60px 40px 110px'}
            >
                <Email>{data.email}</Email>
                <GridItem gridArea="publisher" alignItems="start">{data.publisher || '-'}</GridItem>
                <GridItem gridArea="website" alignItems="start">{data.website || '-'}</GridItem>
                <GridItem gridArea="concierge" alignItems="center"><Icon src={Agreed} opacity={data.isConcierge ? '1' : '0.3'} alt="use-concierge" /></GridItem>
                <InstagramLink instagram={data.instagram} />
                <ArtistName>{data.name}</ArtistName>
                <Date>{date}</Date>
                <AcceptAndReject
                    disabled={isDisabled}
                    handlePayload={async () => await handlePayload(PAYLOAD_TYPES.NEW_BUYER)}
                    popupVisible={popupVisible}
                    action={action}
                    preparePayload={preparePayload}
                    closePopup={closePopup}
                    userID={data.user_id}
                    isApproved={isApproved}
                    type={'buyer'}
                    value={data.name} />
            </Wrapper>
        )
    }
    else if (isApproved && type !== "songs") {
        return (
            <Wrapper
                action={action}
                isApproved={isApproved}
                gridTemplateAreas={"artist email publisher instagram spotify acceptreject"}
                gridTemplateCols={'1fr minmax(200px,2fr) 1fr 80px 60px 110px'}
            >
                <Email>{data.email}</Email>
                <GridItem gridArea="publisher" alignItems="start">{data.publisher || '-'}</GridItem>
                <InstagramLink instagram={data.instagram}>{data.instagram}</InstagramLink>
                <ArtistName>{data.name}</ArtistName>
                <SpotifyLink spotify={data.spotify} />
                <AcceptAndReject
                    disabled={isDisabled}
                    handlePayload={() => { }}
                    popupVisible={popupVisible}
                    action={action}
                    preparePayload={preparePayload}
                    closePopup={closePopup}
                    userID={data.user_id}
                    songID={data.song_id}
                    isApproved={isApproved}
                    type={isApproved !== undefined && isApproved && type === "songs" ? "song" : "artist"}
                    value={isApproved !== undefined && isApproved && type === "songs" ? data.song_title : data.name} />
            </Wrapper>
        )
    }
    else if (!isApproved && type !== "songs") {
        return (
            <Wrapper
                action={action}
                isCurrent={nowPlaying === data.song_id ? true : false}
                gridTemplateAreas={"play artist song tempo genre publisher date  instagram spotify acceptreject"}
                gridTemplateCols={'min-content 2fr repeat(2,1fr) 120px 1fr 70px 80px 60px 110px'}>
                <PlayButton onClick={() =>
                    handleSongChange(`${data.file_path}`, `${data.song_id}`)}
                    playing={playing} isCurrent={nowPlaying === data.song_id ? true : false} />
                <SongTitle>{data.song_title}</SongTitle>
                <Tempo>{tempo}</Tempo>
                <GridItem gridArea="publisher" alignItems="start">{data.publisher || '-'}</GridItem>
                <Genre>
                    {genres.map((genre, idx) => (idx > 0 ? ", " + genre : genre))}
                </Genre>
                <ArtistName>{data.email}</ArtistName>
                <InstagramLink instagram={data.instagram} />
                <SpotifyLink spotify={data.spotify} />
                <Date>{date}</Date>
                <AcceptAndReject
                    disabled={isDisabled}
                    handlePayload={async () => await handlePayload(PAYLOAD_TYPES.NEW_SONGWRITER)} //NEW_SONG, APPROVED_SONG, NEW_SONGWRITER
                    popupVisible={popupVisible}
                    action={action}
                    preparePayload={preparePayload}
                    closePopup={closePopup}
                    isApproved={isApproved}
                    type={isApproved !== undefined && isApproved ? "song" : "artist"}
                    value={isApproved !== undefined && isApproved ? data.song_title : data.name} />

            </Wrapper>
        )
    }
    else if (isApproved && type === "songs" && content.value !== 'Top 100') {
        const song_state = songStatusKey.filter(st => st.id === data.song_status)[0].status;

        return (
            <>
                <Wrapper

                    showSongStatus={data.song_status !== 1}
                    action={action}
                    isCurrent={nowPlaying === data.song_id ? true : false} //min-content repeat(4,1fr) 12rem 5.5rem 60px 60px
                    gridTemplateAreas={data.song_status !== 1 ? "play song artist tempo genre status price date spotify acceptreject" : "play song artist tempo genre date spotify acceptreject"}
                    gridTemplateCols={data.song_status !== 1 ? "min-content repeat(4,1fr) 250px 100px 60px 60px 60px" : "min-content repeat(5,1fr) 60px"}
                >
                    <PlayButton onClick={() => handleSongChange(`${data.file_path}`, `${data.song_id}`)} playing={playing} isCurrent={nowPlaying === data.song_id ? true : false} />
                    <SongTitle>{data.song_title}</SongTitle>
                    <Tempo>{tempo}</Tempo>
                    <Genre>
                        {genres.map((genre, idx) => (idx > 0 ? ", " + genre : genre))}
                    </Genre>
                    <ArtistName>{data.name}</ArtistName>
                    <SpotifyLink spotify={data.spotify} />
                    <GridItem gridArea="price">{data.price !== null && data.price !== undefined ? `${calculatePriceWithFee(data.price).total}` : "-"}</GridItem>
                    <Date>{date}</Date>
                    {data.song_status !== 1 &&
                        <Status functionalStatus={data.song_status >= 3 || (parseInt(data.song_status) === 2 && data.contributors !== undefined)}
                            onClick={data.song_status >= 3 || (parseInt(data.song_status) === 2 && data.contributors !== undefined) ? () => setPopupVisibility(true) : undefined}>{song_state}</Status>}
                    <AcceptAndReject
                        gridCols="1fr"
                        width="500px"
                        disabled={isDisabled}
                        data={data}
                        secondConfirmationRequired
                        handlePayload={async () => await handlePayload(PAYLOAD_TYPES.APPROVED_SONG)} //NEW_SONG, APPROVED_SONG, NEW_SONGWRITER
                        popupVisible={showRemoveApprSongModal}
                        action={action}
                        buttonText="Permanently Delete Song"
                        preparePayload={preparePayload}
                        closePopup={closePopup}
                        isFinalWarning={isFinalWarning}
                        setIsFinalWarning={setIsFinalWarning}
                        title={<><div style={{ display: 'flex', alignItems: 'start' }}><AlertIcon src={AlertIconRed} alt="warning" />
                            <h3 style={{ color: "#9e0000", fontSize: '20px', height: '20px', margin: '0' }}>WARNING:</h3></div></>}
                        isApproved={isApproved}
                        prelimWarningMessage={
                            <div style={{ textAlign: "left" }}><p style={{ display: 'inline', fontSize: '16px', textAlign: "left" }}>
                                <span>You are about to </span>
                                <Strong action={action}>{action}</Strong>
                                <span> the approved song, '{data.song_title}' by {data.name}.
                                    <br /><br />This will remove ALL messages and data associated with this song.
                                    This is process is <span style={{ color: "#9e0000", fontWeight: '700' }}>irreversible</span>.
                                    <br /><br />
                                    Would you like to continue?</span></p></div>}
                        warningMessage={<p style={{ display: 'inline', fontSize: '16px' }}>
                            <span>Please confirm that you would like to </span>
                            <Strong action={action}>permanently delete</Strong> the song '{data.song_title}' by {data.name}.</p>}
                        type={"song"}
                        value={data.song_title} />
                        
                </Wrapper>
                {(data.song_status >= 3 || (parseInt(data.song_status) === 2 && data.contributors !== undefined)) && <SongDetailsModal selectedSong={data} closePopup={closePopup} popupVisible={popupVisible} />}
            </>
        )
    }
    else if (!isApproved && type === "songs") {
        return (
            <Wrapper
                action={action}
                isCurrent={nowPlaying === data.song_id ? true : false}
                gridTemplateAreas={"play artist song tempo genre date spotify acceptreject"}
                gridTemplateCols={'min-content repeat(5,1fr) 60px 110px'}
            >
                <PlayButton onClick={() => handleSongChange(`${data.file_path}`, `${data.song_id}`)} playing={playing} isCurrent={nowPlaying === data.song_id ? true : false} />
                <SongTitle>{data.song_title}</SongTitle>
                <Tempo>{tempo}</Tempo>
                <Genre>
                    {genres.map((genre, idx) => (idx > 0 ? ", " + genre : genre))}
                </Genre>
                <ArtistName>{data.name}</ArtistName>
                <SpotifyLink spotify={data.spotify} />
                <Date>{date}</Date>
                <AcceptAndReject
                    disabled={isDisabled}
                    handlePayload={async () => await handlePayload(PAYLOAD_TYPES.NEW_SONG)} //NEW_SONG, APPROVED_SONG, NEW_SONGWRITER
                    popupVisible={popupVisible}
                    action={action}
                    preparePayload={preparePayload}
                    closePopup={closePopup}
                    isApproved={isApproved}
                    type={"song"}
                    value={data.song_title} />
                {/* {notifModalProps && <NotificationModal notifModalProps={notifModalProps} />} */}
            </Wrapper>
        )
    }
    else {
        return <Wrapper></Wrapper>
    }

}

export default Songwriter
