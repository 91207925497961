import React, { useRef, useState, useEffect } from 'react'
import { Wraper, Innercontainer, Row, NavArrow, DateWrapper, LoadWrapper } from './PageNav.styles'
import FormNavArrow from '../../../images/formNavArrow.svg';
import LoadingWheel from '../../LoadingWheel'
import GridItem from '../../GridItem'
import Flexbox from '../../Flexbox'
import DropdownMultiSelect from '../../DropdownMultiSelect'
import Dropdown from '../../Dropdown'
import SearchBar from '../../SearchBar'
import SuggestSearch from '../../SuggestSearch'

import dayjs from 'dayjs'
import API from '../../../API'

import { useAuth } from '../../../contexts/AuthContext'

const Label = ({ index, labelRef, label, setShowFilterDropdown, width }) => {
    if (index === 0) {
        return (
            <GridItem
                background="#1c1c1c"
                width={width ? width : "100%"}
                borderRadius="5px"
                boxShadow="0 0 2px #000"
                cursor="pointer"
                position='relative'
                whiteSpace="nowrap"
                letterSpacing="0.1rem" fontSize="var(--fontSmall)" fontWeight="700"
                height="45px"
                // justifySelf="end" alignItems="end" 
                justifySelf="center" alignItems="center"
                onClick={setShowFilterDropdown}
                justifyContent="center"
                refID={labelRef}>{label}</GridItem>)
    }
    else
        return (<GridItem
            width={width ? width : "100%"}
            background="#1c1c1c"
            borderRadius="5px"
            boxShadow="0 0 2px #000"
            cursor="pointer" onClick={setShowFilterDropdown}
            position='relative' letterSpacing="0.1rem" alignText="center" fontSize="var(--fontSmall)" fontWeight="700" height="45px" alignItems="center" justifyContent="center" refID={labelRef}>{label}</GridItem>)
}

const Filter = ({ flexDirection, showBufferPlaylist, children, label, genreFilters, showFilterDropdown, setShowFilterDropdown, width, gridCol, index }) => {
    const labelRef = useRef(null)
    return (
        <GridItem gridRow="2" zIndex={index !== 1 && showBufferPlaylist ? '-1' : undefined}
            gridCol={gridCol ? gridCol : index + 1} position='relative' alignItems='center' display="flex" flexDirection={flexDirection} width={width}
        // onClick={(e) => {
        //     if (e.target === labelRef.current) setShowFilterDropdown()
        // }}
        >
            <Label index={index} label={label} labelRef={labelRef} setShowFilterDropdown={setShowFilterDropdown} width={width} />
            {
                showFilterDropdown && children
            }
        </GridItem>)
}

const Page = ({ number, onClick, isActive, isPlaceholder }) => {
    if (isPlaceholder)
        return (
            <GridItem
                width="40px"
                height="40px"
                lineHeight="0.7em"
                borderRadius="5px"
                // border={!isActive ? "" : "1px solid #4f4f4f"}
                opacity={"0.25"}
                position='relative'
                whiteSpace="nowrap"
                letterSpacing="0.1rem"
                fontSize={"var(--fontSmall)"}
                fontWeight="700"
                // justifySelf="end" alignItems="end" 
                justifySelf="center" alignItems="center"
                justifyContent="center">...</GridItem>)
    return (
        <GridItem
            width="40px"
            height="40px"
            lineHeight="0.7em"
            borderRadius="5px"
            // border={!isActive ? "" : "1px solid #4f4f4f"}
            opacity={!isActive ? "0.25" : "1.0"}
            background={!isActive ? "" : "#313131"}
            cursor="pointer"
            position='relative'
            whiteSpace="nowrap"
            letterSpacing="0.1rem"
            fontSize={"var(--fontSmall)"}
            fontWeight="700"
            // justifySelf="end" alignItems="end" 
            justifySelf="center" alignItems="center"
            onClick={onClick}
            justifyContent="center">{number}</GridItem>)
}

/**
 * 
 * @param  
 * contentFilter {
 *      genre: [],
 *      playlist: 'Top 100',
 *      tempo: 'TEMPO',
 *      searchValue: '',
 *      searchTermList: []
 *   }
 * @param  
 * @returns 
 */
const AltLayout = ({ hideHeader, content, filter, totalResults, resultsPerPage, page, setPage, pullSongs, loading, children, ...props }) => {
    const { currentUser } = useAuth()
    const {
        filterDropdowns,
        showFilterDropdown,
        contentFilter,
        setShowFilterDropdown,
        handleGenreChange,
        playlistDropdown,
        top100ColumnSearch,
        onSearchReset,
        innerPadding,
        searchPartitions,
        partitionedSearch,
        pageRef,
        isFooter = false,
    } = props
    const [paginate, setPaginate] = useState({
        totalPages: 1,
        pageArray: [],
        showFirst: false,
        showLast: true,
        tabsToShow: 2
    })
    useEffect(() => {
        if (resultsPerPage !== 0) {
            const length = Math.ceil(totalResults / resultsPerPage) !== 0 ? Math.ceil(totalResults / resultsPerPage) : 1
            setPaginate(prev => ({
                ...prev,
                pageArray: Array.from({ length: length }, (_, i) => i + 1),
                totalPages: length,
                tabsToShow: 2
            }))
        }

    }, [totalResults, resultsPerPage, content])

    const numberIsInRange = (number) => {
        if (number < (page % tabsToShow > 0 ? page - page % tabsToShow : (page - 1) - (page - 1) % tabsToShow))
            return false
        if (number > (page % tabsToShow > 0 ? page - page % tabsToShow + tabsToShow : (page - 1) - (page - 1) % tabsToShow + tabsToShow))
            return false
        return true
    }
    const { totalPages, pageArray, tabsToShow, showFirst, showLast } = paginate

    useEffect(() => {
        if (numberIsInRange(1) || numberIsInRange(totalPages)) {

            setPaginate(prev => ({
                ...prev,
                showFirst: !numberIsInRange(1),
                showLast: !numberIsInRange(totalPages),
            }))
        }

    }, [page, totalPages])
    const getSuggestions = async (_input, items, setItems, setInput) => {
        const filter_ = { filter }
        if (searchPartitions) {
            filter_.status = top100ColumnSearch.options[top100ColumnSearch.selected].valueForSearch
        }
        const res = await API.searchAcceptedSongs(currentUser, filter_, _input)
        if (res) {
            const arr = Object.entries(res).flat().flat()
            let activeKey = arr[0]
            return arr.map(it => {
                console.log("res", res)
                if (!Object.keys(res).includes(it)) {

                    const accessor = Object.keys(it)[0]
                    const key = Object.keys(res).find(k => res[k].find(_el => _el[accessor] === it[accessor]))
                    return ({
                        innerText: it[accessor],
                        value: it[accessor],
                        onClick: () => {
                            console.log("items", items)
                            if (!items.find(el => el.id !== it[accessor])) {
                                setItems([{ value: it[accessor], id: it[accessor], match: key }])
                                // setItems([...items, it.name])
                            }
                            setInput('')
                        },
                        key: key
                    })
                }
                else {
                    activeKey = it
                    return ({
                        innerText: <strong>{it}</strong>,
                        value: it,
                        onClick: () => { },
                        props: { hoverOff: true },
                        isKey: true
                    })
                }
            })
        } else {
            setInput('')
            return []
        }
    }

    const onSearchEnter = async (_val) => {
        setPage(1)
        pageRef.current = 1
        const { tempo, genre } = contentFilter
        const _filter = {}
        if (genre.length > 0) {
            _filter.genre = genre.reduce((a, b, index) => index > 0 ? a = a + ',' + b : b, '')
        }
        if (tempo.toUpperCase() !== 'TEMPO')
            _filter.tempo = tempo
        if (_val && _val[0]) {
            console.log()
            _filter.searchCategory = _val[0].match
            _filter.searchValue = _val[0].value

        }
        if (searchPartitions && _val && _val[0]) {
            _filter.column = top100ColumnSearch.options[top100ColumnSearch.selected].value
        }
        await pullSongs(1, filter, false, _filter)
    }

    return (

        <Innercontainer {...props}>
            <Flexbox padding={innerPadding ? innerPadding : "30px 30px 10px"} column height="min-content">
                {/* <Flexbox fontSize="10px">{"Today's Date: " + dayjs().format('ddd, MMMM D, YYYY')}</Flexbox> */}
                <GridItem display="grid"
                    gridTempCols="min-content 1fr min-content 140px"
                    gridTempRows="min-content min-content" gridColGap="10px" gridRowGap="10px">

                    {!hideHeader &&
                        <div style={{ gridColumn: '1 / span 2', fontSize: '1rem', gridRow: '1', textTransform: 'capitalize' }}>
                            {content.value.toLowerCase().includes('new') ? content.value : content.value + ' ' + content.type}
                        </div>
                    }
                    {(content.type !== 'messages' && content.value !== 'Top 100') &&
                        <>
                            <GridItem
                                borderRadius="5px"
                                background="#1c1c1c"
                                height="45px"
                                width="min-content"
                                padding="0 10px"
                                flexDirection="row"
                                boxShadow="0 0 2px #000"
                                textTransform="uppercase"
                                fontSize="var(--fontSmall)"
                                letterSpacing="0.1rem"
                                fontWeight="700"
                                gap="20px"
                                gridRow="2">
                                <NavArrow back isValid={page > 1}
                                    onClick={() => {
                                        if (!loading)
                                            if (page > 1) {
                                                pageRef.current = pageRef.current - 1
                                                setPage(page - 1)
                                                pullSongs(page - 1, filter)
                                            }
                                    }}><img src={FormNavArrow} alt="back" /></NavArrow>
                                {
                                    showFirst &&
                                    <>
                                        <Page number={1}
                                            onClick={() => {
                                                if (page !== 1) {
                                                    pageRef.current = 1
                                                    setPage(1)
                                                    pullSongs(1, filter)
                                                }
                                            }}
                                            isActive={1 === page} />
                                        <Page isPlaceholder={true} />
                                    </>
                                }

                                {
                                    pageArray
                                        .slice((page % tabsToShow > 0 ? page - page % tabsToShow : (page - 1) - (page - 1) % tabsToShow),
                                            (page % tabsToShow > 0 ? page - page % tabsToShow + tabsToShow : (page - 1) - (page - 1) % tabsToShow + tabsToShow))
                                        .map(it =>
                                            <Page number={it}
                                                onClick={() => {
                                                    if (page !== it) {
                                                        pageRef.current = it
                                                        setPage(it)
                                                        pullSongs(it, filter)
                                                    }
                                                }}
                                                isActive={it === page} />)
                                }
                                {
                                    showLast &&
                                    <>
                                        <Page isPlaceholder={true} />
                                        <Page number={totalPages}
                                            onClick={() => {
                                                if (page !== totalPages) {
                                                    pageRef.current = totalPages
                                                    setPage(totalPages)
                                                    pullSongs(totalPages, filter)
                                                }
                                            }}
                                            isActive={totalPages === page} />
                                    </>
                                }

                                <NavArrow isValid={page <= Math.ceil(totalResults / resultsPerPage) - 1}
                                    onClick={() => {
                                        if (!loading)
                                            if (page + 1 <= Math.ceil(totalResults / resultsPerPage)) {
                                                pageRef.current = pageRef.curent + 1
                                                setPage(page + 1)
                                                pullSongs(page + 1, filter)
                                            }
                                    }}><img src={FormNavArrow} alt="next" /></NavArrow>
                            </GridItem>

                        </>}
                    {
                        <GridItem gridRow="2" width="100%" height="45px" gridCol="2" >
                            <SuggestSearch
                                isFooter={isFooter}
                                margin="0"
                                padding="0"
                                maxWidth="100%"
                                borderRadius="5px"
                                minHeight="45px"
                                height="45px"
                                partitionedSearch={partitionedSearch}
                                listMarginBottom="45px"
                                selectedPartition={top100ColumnSearch.selected}
                                searchPartitions={top100ColumnSearch.optionOrder.map(it => ({ ...top100ColumnSearch.options[it] }))}
                                data={contentFilter.searchTermList} onChange={onSearchEnter} getSuggestions={getSuggestions} placeHolder="Search" />
                        </GridItem>
                    }
                    <Filter showBufferPlaylist={false}
                        index={0}
                        gridCol={3}
                        flexDirection={isFooter ? 'column-reverse' : undefined}
                        setShowFilterDropdown={() => { setShowFilterDropdown(prev => prev.map((it, idx) => idx === 0 ? !it : false)) }}
                        showFilterDropdown={showFilterDropdown[0]} display="flex" width="140px" genreFilters={contentFilter.genre}
                        label={contentFilter.genre.length > 0 ? contentFilter.genre.map((genre, idx) => (idx > 0 ? ", " + genre.toUpperCase() : genre.toUpperCase())) : 'GENRES'}>
                        <Flexbox absolute top={!isFooter ? "49px" : undefined} bottom={isFooter ? "49px" : undefined}
                            flexDirection={isFooter ? 'column-reverse' : undefined}>
                            <DropdownMultiSelect
                                listMarginBottom={isFooter ? "55px" : undefined}
                                flexDirection={isFooter ? 'column-reverse' : undefined}
                                label={contentFilter.genre.length > 0 ? contentFilter.genre.map((genre, idx) => (idx > 0 ? ", " + genre : genre)) : 'GENRES'}
                                headerText={contentFilter.genre.length > 0 ? contentFilter.genre.map((genre, idx) => (idx > 0 ? ", " + genre : genre)) : 'GENRES'}
                                showTile={false}
                                _isOpen={true}
                                isDash={true}
                                _handleChange={() => { setShowFilterDropdown(prev => [false, prev[1]]) }}
                                hideLabel={true}
                                name='tempo'
                                marginBottom={'1rem'}
                                options={filterDropdowns.genreDropdown}
                                handleStateChange={handleGenreChange}
                                valueX={contentFilter.genre}
                                setValidContinue={() => { }}
                            ></DropdownMultiSelect>
                        </Flexbox>
                    </Filter>
                    <Filter
                        showBufferPlaylist={false}
                        index={1}
                        gridCol={4}

                        label={contentFilter.tempo.toUpperCase()}
                        width="140px"
                        setShowFilterDropdown={() => { setShowFilterDropdown(prev => prev.map((it, idx) => idx === 1 ? !it : false)) }}
                        showFilterDropdown={showFilterDropdown[1]}>
                        <Flexbox position='absolute' top="49px" >
                            <Dropdown
                                listMarginBottom={isFooter ? "55px" : undefined}
                                flexDirection={isFooter ? 'column-reverse' : undefined}
                                showRemoveButton={true}
                                setMenuState={(val) => { setShowFilterDropdown(prev => prev.map((it, idx) => idx === 1 ? !it : false)) }}
                                menuState={showFilterDropdown[1]}
                                // handleStateChange={(e) => { console.log(e.target); setTempo(e.target.attributes.value.value) }}
                                valueX={contentFilter.tempo}
                                index={0}
                                itemPadding="17.75px"
                                _handleChange={() => { setShowFilterDropdown(prev => prev.map((it, idx) => idx === 1 ? !it : false)) }}
                                listBackground="var(--greyBackground)"
                                justifyContent="center"
                                textAlign="center"
                                letterSpacing="0.1rem"
                                fontSize="var(--fontSmall)"
                                fontWeight="700"
                                itemHeight="min-content"
                                handleStateChange={async (e) => {
                                    console.log("event target", e.target)
                                    if (e.target) {
                                        const { value: { value } } = e.target.attributes
                                        // await onTempoGenreChange(
                                        //     value.toUpperCase()
                                        // )
                                    }
                                }}
                                width="140px"
                                name='playlist'
                                options={filterDropdowns.tempoDropdown}
                                setValidContinue={true} />
                        </Flexbox>
                    </Filter>
                </GridItem>

            </Flexbox>
            {children}
            {/* {!loading && children}
            {loading && <LoadWrapper><LoadingWheel position="relative" height="100%" width="100%"></LoadingWheel></LoadWrapper>} */}
        </Innercontainer >

    )
}

const PageNav = ({ altLayout = false, hideHeader = false, ...props }) => {
    const { content, filter, totalResults, resultsPerPage, page, setPage, pullSongs, loading, children, } = props

    if (altLayout)
        return <AltLayout hideHeader={hideHeader} {...props} />
    return (
        <Innercontainer hideHeader={hideHeader} {...props}>
            <Row>
                <div style={{ gridColumn: '1 / span 2', fontSize: '1rem' }}>{content.value}</div>
                {(content.type !== 'messages') &&
                    <>
                        <div style={{ fontSize: '10px', display: 'flex', columnGap: '20px', padding: '0' }}>
                            <div style={{ display: 'flex' }}>Page {page} of <div style={{ display: 'flex', width: 'min-content', marginLeft: '3px' }}
                                onClick={!loading ? () => {
                                    if (page !== Math.ceil(totalResults / resultsPerPage)) {
                                        setPage(Math.ceil(totalResults / resultsPerPage))
                                        pullSongs(Math.ceil(totalResults / resultsPerPage), filter)
                                    }
                                } : undefined}>
                                {Math.ceil(totalResults / resultsPerPage) !== 0 ? Math.ceil(totalResults / resultsPerPage) : 1}
                            </div>
                            </div>
                            <NavArrow back isValid={page > 1}
                                onClick={() => {
                                    if (!loading)
                                        if (page > 1) {
                                            setPage(page - 1)
                                            pullSongs(page - 1, filter)
                                        }
                                }}><img src={FormNavArrow} alt="back" /></NavArrow>
                            <NavArrow isValid={page <= Math.ceil(totalResults / resultsPerPage) - 1}
                                onClick={() => {
                                    if (!loading)
                                        if (page + 1 <= Math.ceil(totalResults / resultsPerPage)) {
                                            setPage(page + 1)
                                            pullSongs(page + 1, filter)
                                        }
                                }}><img src={FormNavArrow} alt="next" /></NavArrow>
                        </div>
                        <DateWrapper>{"Today's Date: " + dayjs().format('ddd, MMMM D, YYYY')}</DateWrapper>
                    </>}
            </Row>
            {!loading && children}
            {loading && <LoadWrapper><LoadingWheel position="relative" height="100%" width="100%"></LoadingWheel></LoadWrapper>}
        </Innercontainer>
    )

}

export default PageNav