import logo from './logo.svg';
import './App.css';
import Navigation from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// Components
import Form from './components/UploadForm/Form';
import Footer from './components/Footer';
import Payout from './components/Payout';
import CompletedTransaction from './components/CompletedTransaction';
import AdminPanel from './components/AdminPanel';
import Login from './components/Login';
import NotificationModal from './components/NotificationModal'
import Register from './components/Register'
import PrivateRoute from './components/PrivateRoute'
//import AuthUser from './components/AuthUser'
import { AuthProvider, useAuth } from './contexts/AuthContext'
import { NotificationsProvider } from './contexts/NotificationsContext'
import { GlobalStyle } from './GlobalStyle';
import { updateRelTime } from './utils'


const AppUserStats = () => {
  const { currentUser } = useAuth();
  return <></>
}

function App() {
  updateRelTime()
  return (
    <AuthProvider>
      <div className="App">
        <AppUserStats />
        <Router>
          {/* <Navigation /> */}
          <Routes>
          <Route path="/register/:userID" exact element={<Register />} />
            <Route path="/login" exact element={<Login />} />
            {/* <Route path="/" element={<PrivateRoute />}>
              <Route path="/" element={<AdminPanel />} />
            </Route> */}
            <Route path="/transactions/payout/:songID" element={<PrivateRoute />}>
              <Route path="/transactions/payout/:songID" element={<AdminPanel />} />
            </Route>
            <Route path="/transactions/completed/:songID" element={<PrivateRoute />}>
              <Route path="/transactions/completed/:songID" element={<CompletedTransaction />} />
            </Route>
            <Route path="*" element={<PrivateRoute />}>
              <Route path="*" element={<AdminPanel />} />
            </Route>
            {/* <Route path="/payout/:songID" exact element={<AdminPanel />} /> */}
          </Routes>
        </Router>
        <GlobalStyle />
      </div>
    </AuthProvider>

  );
}



export default App;
