import styled from "styled-components";

const getHighlight = (oddHighlight = false) => {
    if (oddHighlight) return '2n+1'
    else return '2n'
}

export const Table = styled.table`
margin: ${props => props.margin ? props.margin : ''};
padding-bottom: 100px;
width: ${({ width = '100%' }) => width};
overflow: hidden auto;
border-spacing: ${props => props.borderSpacing ? props.borderSpacing : ''};
border-collapse: ${props => props.borderCollapse ? props.borderCollapse : 'collapse'};
background: ${props => props.background ? props.background : ''};
cellpadding: ${props => props.cellpadding ? props.cellpadding : ''};
border: ${props => props.border ? props.border : ''};
padding: 0;
`;

export const TableContainer = styled(Table)`

tr:not(.tableHeader):nth-of-type(2n){
    background: ${props => props.isTop100 ? '#282828' : '#313131'};
    //#313131
    
}
>div:not(.tableHeader):nth-of-type(2n){
    background: #0D0D0D;
    //#313131
    
}
tr.selected{
    background: var(--yellow);
    color: #000000;
    outline: inset 1px #1A1A1A;
    
}
tr:not(.tableHeader):not(.hoverOff):not(.selected):hover{
    background: ${props => props.isTop100 ? '#282828' : '#313131'};
    
}
/* tr.tableHeader{
    text-transform: uppercase;
} */
`;

export const TableContainerOdd = styled(Table)`

tr:not(.tableHeader):nth-of-type(2n + 1){
    background: ${props => props.isTop100 ? '#282828' : '#313131'};
    //#313131
    
}
>div:not(.tableHeader):nth-of-type(2n + 1){
    background: #0D0D0D;
    //#313131
    
}
tr.selected{
    background: var(--yellow);
    color: #000000;
    outline: inset 1px #1A1A1A;
    
}
tr:not(.tableHeader):not(.hoverOff):not(.selected):hover{
    background: ${props => props.isTop100 ? '#282828' : '#313131'};
    
}
`;
