import React from 'react'

import { ToolTipItem, ToolTipTextWrapper } from './ToolTip.styles'

const ToolTip = ({ justifyContent, boxShadow, width, height, toolTipText, absolute, relative, fixed, border, background, right, left, bottom, children, ...props }) => {
    const getPosition = () => {
        if (absolute)
            return 'absolute'
        if (relative)
            return 'relative'
        if (fixed)
            return 'fixed'
        return 'absolute'
    }
    return (
        <>
            <ToolTipItem
                position={getPosition()}
                right={right}
                left={left}
                boxShadow={boxShadow}
                justifyContent={justifyContent}
                bottom={bottom}
                width={width}
                height={height}
                border={border}
                background={background}
                {...props}>
                {children}
                <ToolTipTextWrapper className="tooltip" title="t" {...props}>{toolTipText}</ToolTipTextWrapper>
            </ToolTipItem>
        </>
    )
}

export default ToolTip