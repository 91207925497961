import styled from 'styled-components'

export const Wrapper = styled.div`

`;

export const CheckboxStyle = styled.label`
display: flex;
align-items: center;
justify-content: center;
font-size: var(--fontSmall);
color: var(--purpleGrey);
font-weight: 500;
letter-spacing: 0.02rem;
span{
    display: inline-block;
    width: 15px;
    height: 15px;
    background: rgba(255,255,255,0.5);
    position: relative;
    box-shadow: 0 0 1px 1px inset #000;
    cursor: pointer;
}

strong{
    color: #ffffff;
    font-weight: 700;
}

input{
    position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

input:checked ~ span{
    background: rgb(255,255,255);
    box-shadow: 0 0 1px 1px inset #000;
}

input:checked ~ span:after{
    content: "";
    position: relative;
    display: block;
 top: 1px;
  width: 3px;
  height: 8px;
  margin: auto auto;
  border: solid rgb(0,0,0);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
`;

export const Table = styled.div`
display: table;
width: 100%;
overflow: hidden auto;
/* tr{
    background: ${props => props.background ? props.background : ''};
} */
/* >.row:nth-of-type(2n){
    background: ${props => props.isTop100 ? '#282828' : '#313131'};
    //#313131
    
} */
/* .row{
    width: 100%;
} */
.selected{
    background: var(--yellow);
    color: #000000;
    outline: inset 1px #1A1A1A;
    
}
>div:not(.tableHeader):not(.selected):hover{
    background: ${props => props.isTop100 ? '#282828' : '#313131'};
    
}

.tableHeader:nth-of-type(1){
    /* text-transform: uppercase; */
    >div{
        padding: 20px 10px 0 10px;
    }
}
.tableHeader:nth-of-type(2){
    /* text-transform: uppercase; */
    >div{
        padding: 5px 10px 20px 10px;
    }
}
`;

export const TableRow = styled.div`
display: table-row;
transition: background 0.5s ease-out, color 0.3s ease-out;
background: ${props => props.isDragging ? 'var(--yellow) !important' : props.background ? props.background : ''};
color: ${props => props.isDragging ? '#000000 !important' : ''};
width: 100%;
outline: ${props => props.isDragging ? 'inset 1px #1A1A1A' : 'none'};

`;

export const TableCell = styled.div`
display: table-cell;
width: ${props => props.width ? props.width : 'auto'};
vertical-align: middle;
text-align: ${props => props.textAlign ? props.textAlign : 'left'};
padding: 20px 10px;

`;

export const ToolTipItem = styled.div`
position: relative;
width: 100%;
display: flex;

justify-content: center;
.tooltip{
    border-radius: 5px;
    background: var(--greyBackground);
    border: 1px solid #858585;
    padding: 10px 20px;
    position: absolute;
    visibility: hidden;
    z-index: 1;
    text-transform: none;
    right: ${({ right }) => right};
    left: ${({ left }) => left};
    white-space: nowrap;
    bottom: -30px;
}
&:hover .tooltip{
    visibility: visible;
}
`;

export const TaskList = styled.div`
border: 1px solid var(--yellow);
`;

export const Item = styled.div`
padding: 8px;
margin: 8px 0;
border: 1px solid #ffffff;
`;