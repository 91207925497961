import React from 'react'

import { Wrapper } from './Table.styles'

import TableEntry from '../TableEntry'

const Table = ({ height, rowHighlight, headerFontSize, contentFontSize, padding, content, gridTemplateColumns, gridColGap, gridRowGap, tableHeader, rowAlignmentArray, handleSelect, noHover, tableHeaderTextStyling }) => {
    return (
        <Wrapper height={height}>
            <TableEntry
                content={tableHeader}
                headerFontSize={headerFontSize}
                tableHeaderTextStyling={tableHeaderTextStyling}
                gridTemplateColumns={gridTemplateColumns}
                gridColGap={gridColGap}
                rowAlignmentArray={rowAlignmentArray}
                header
                padding={padding}
            />
            {
                content.map((row, index) => (
                    <TableEntry
                        onClick={ handleSelect ? handleSelect(row.contentID) : undefined}
                        noHover={noHover}
                        contentFontSize={contentFontSize}
                        rowHighlight={row.rowHighlight}
                        padding={padding}
                        content={row.contentArr}
                        id={row.contentID}
                        key={index}
                        gridTemplateColumns={gridTemplateColumns}
                        gridColGap={gridColGap}
                        gridRowGap={gridRowGap}
                        rowAlignmentArray={rowAlignmentArray}
                    />
                ))
            }
        </Wrapper>
    )
}

export default Table
