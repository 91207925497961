import React from 'react'

import { TableCellWrapper, TableCellHeaderWrapper, LinkWrapper } from './TableCell.styles'

import { InstagramLink } from '../AdminPanel/Songwriter'

const CellContent = ({ props, title }) => {
    if (!props['type'])
        return title
    else if (props['type'] === 'instagram')
        return <InstagramLink instagram={title} />
    else if (props['type'] === 'link') {
        if (title === '-')
            return title
        return <LinkWrapper target="_blank" href={title.includes('https://') ? title : 'http://' + title}>{title}</LinkWrapper>
    }
    else return <></>
}

const TableCell = ({ children, className, index, content, isHeader, props, ...remainingProps }) => {
    if (content) {
        const columnSpan = !content.props ? undefined : content.props.colSpan ? content.props.colSpan : undefined
        if (isHeader)
            return (<TableCellHeaderWrapper colSpan={columnSpan} className={className} id={index} {...content.props} {...remainingProps} >{content.title}</TableCellHeaderWrapper>)
        return (
            <TableCellWrapper colSpan={columnSpan} className={className} id={index} {...content.props} {...props} {...remainingProps}>

                <CellContent props={content.props} title={content.title} />

            </TableCellWrapper>
        )
    } else {
        if (isHeader)
            return (<TableCellHeaderWrapper className={className} id={index} {...props} {...remainingProps}>{children}</TableCellHeaderWrapper>)
        return (
            <TableCellWrapper className={className} id={index} {...props} {...remainingProps}>{children}</TableCellWrapper>
        )
    }

}

export default TableCell