import React, { useState, useEffect, useRef } from 'react'

import { TableRowWrapper, TableCell, DragCountWrapper } from './TableRow.styles'

import Flexbox from '../../Flexbox'
import DotMenu from '../../DotMenu'
import GridItem from '../../GridItem'
import { calculatePriceWithFee } from '../../../helpers'
import dayjs from 'dayjs'


const DragCount = ({ selected, className }) => (

    <DragCountWrapper className={className}>{selected}</DragCountWrapper>
)

const DraggableIcon = ({ fill, className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.01 20" className={className} height="25px" width="25px">
            <g id="a" style={{ fill: fill }}>
                <polygon points="0 10 2.28 7.71 2.28 12.28 0 10" />
                <polygon points="7.73 17.73 12.28 17.73 10 20 7.73 17.73" />
                <polygon points="10 0 12.28 2.27 7.73 2.27 10 0" />
                <polygon points="20.01 10 17.73 12.28 17.73 7.71 20.01 10" />
                <path d="M13.88,15.33H6.13c-.8,0-1.45-.65-1.45-1.45V6.12c0-.8,.65-1.45,1.45-1.45h7.75c.8,0,1.45,.65,1.45,1.45v7.75c0,.8-.65,1.45-1.45,1.45ZM6.13,5.67c-.25,0-.45,.2-.45,.45v7.75c0,.25,.2,.45,.45,.45h7.75c.25,0,.45-.2,.45-.45V6.12c0-.25-.2-.45-.45-.45H6.13Z" />
                <polygon points="8.82 12.49 7.02 10.69 7.73 9.98 8.82 11.07 12.03 7.86 12.74 8.57 8.82 12.49" />
            </g>
        </svg >
    )
}

const TableRow = ({ className, background, setButtonActions, setModalType, reorderMultiDrag, getHomeColumnTitle, column, onCheck, song, provided, snapshot, selected, index, spot }) => {
    const [hover, setHover] = useState(false)
    // const [spot, setSpot] = useState(1 + index)

    const genres = JSON.parse(song.content.genre)
    const dotMenuRef = useRef(null)
    const dotMenuRefWrapper = useRef(null)
    const [options, setOptions] = useState([])

    const getOptions = () => {
        const { title } = column
        switch (title) {
            case 'Top 100':
                return [
                    { value: 1, innerText: 'Remove from Top 100', onClick: () => { reorderMultiDrag(null, song.id) } },
                ]
            default:
                return [
                    {
                        value: 1, innerText: 'Add to Top 100', onClick: () => {
                            setModalType(column.id, song.id)
                        }
                    },
                    {
                        value: 2, innerText: 'View Song Details', onClick: () => {
                            setModalType('song-details', song.id)
                        }
                    },
                    {
                        value: 3, innerText: 'View Songwriter Details', onClick: () => {
                            setModalType('songwriter-details', song.id)
                        }
                    },
                ]
            // default:
            //     return [{
            //         value: 1, innerText: 'Add to Top 100', onClick: () => {
            //             reorderMultiDrag(null, song.id)
            //         }
            //     }]
        }
    }

    useEffect(() => {
        setOptions(getOptions())
    }, [column])

    return (
        <>
            <TableRowWrapper
                // onKeyDown={(e) => console.log("keypress target:", e.target)}
                onMouseEnter={() => { setHover(true) }}
                onMouseLeave={() => { setHover(false) }}
                onClick={(e) => {

                    if (e.target !== dotMenuRef.current && e.target !== dotMenuRefWrapper.current && !e.target.classList.contains('dotmenu') && e.target.classList.contains('row')) {
                        console.log("TRIGGERED BY", e, e.target)
                        onCheck(column.id, song.id, e)
                    }
                }}
                className={selected.includes(song.id) ? 'selected' : 'row'}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                padding="8px"
                ref={provided.innerRef}
                background={background ? background : column.background}
                isDragging={snapshot.isDragging}
                margin="5px"
                border="1px solid #ffffff"
                key={song.id}>

                <TableCell minWidth="45px" width="45px" textAlign="center" className="row">
                    {(hover || selected.includes(song.id)) &&
                        <>
                            <GridItem position="absolute" justifyContent="start" className="row">
                                {(snapshot.isDragging && selected.includes(song.id)) && <DragCount selected={selected.length} className="row" />}
                            </GridItem>
                            <DraggableIcon className="row" fill={selected.includes(song.id) ? '#000000' : '#ffffff'} /></>
                    }
                    {/* <img src={ExpandIcon} style={{ height: '25px', width: '25px' }} /> */}
                </TableCell>
                {column.title === 'Top 100' &&
                    <TableCell className={`${song.id}`} textAlign="center">
                        <Flexbox textAlign="center" justifyContent="center">
                            {spot}
                        </Flexbox>
                        <Flexbox opacity="0.35" fontSize="10px" textAlign="center" justifyContent="center">
                            {dayjs.unix(song.content.date_added || song.content.updated_at).fromNow()}
                        </Flexbox>
                    </TableCell>
                }
                <TableCell className={`${song.id} row`} width="80%">{song.content.song_title}</TableCell>
                <TableCell className={`${song.id} row`} width="20%">
                    <Flexbox>{genres.map((genre, idx) => (idx > 0 ? ", " + genre : genre))}</Flexbox>
                    <Flexbox opacity="0.35" fontSize="10px">{song.content.tempo}</Flexbox>
                </TableCell>
                <TableCell className={`${song.id} row`}>{calculatePriceWithFee(song.content.price).total}</TableCell>
                <TableCell className={`${song.id} row`} textAlign="center" width="fit-content">{song.content.favorites > 0 ? song.content.favorites : '-'}</TableCell>
                {/* <TableCell className={`${song.id} row`} textAlign="center" >{dayjs.unix(song.content.date_added || song.content.updated_at).fromNow()}</TableCell> */}
                <TableCell className={`${song.id}`} width="30px" height="30px" ref={dotMenuRefWrapper}>
                    <GridItem position="relative">
                        <DotMenu
                            _options={options}
                            right="0"
                            hoverActive={hover}
                            bottom="0"
                            _ref={dotMenuRef}
                            selected={selected}
                            fill={selected.includes(song.id) ? "#000000" : "#ffffff"}
                            status="1" />
                    </GridItem>
                </TableCell>
            </TableRowWrapper>
        </>
    )
}

export default TableRow