import React, { useState, useEffect, useRef } from 'react'

import { Wrapper, ScrollWrapper } from './MessageGrid.styles'

import Message from '../Message'

const MessageGrid = ({ messages, currUserID, loading }) => {
    const scrollBoxRef = useRef(null)
    useEffect(() => {
        if (!loading && messages !== null) {
            if (scrollBoxRef.current !== null) {
                scrollBoxRef.current.scrollTop = scrollBoxRef.current.firstChild.getBoundingClientRect().height;//scrollBoxRef.current.scrollHeight;//getBoundingClientRect().height
            }
        }
    }, [loading, messages])
    if (scrollBoxRef.current !== null) {
        console.log("")
    }
    if (messages === null || messages === undefined) {
        return (<Wrapper></Wrapper>)
    }
    else {
        return (
            <ScrollWrapper ref={scrollBoxRef}>
                <Wrapper>
                    {
                        messages.map(message => (
                            <Message
                                message={message}
                                key={message.id}
                                name={message.sender_id === '1' ? 'Azaa admin' : message.first_name}
                                senderIsCurrentUser={message.sender_id === currUserID ? true : false}
                            />
                        ))
                    }
                </Wrapper>
            </ScrollWrapper>
        )
    }


}

export default MessageGrid
